import blocked from 'assets/graphics/blocked.svg'
import { closeBlock } from 'state/slices/ui/dash'

export function BlockedModal() {
  return (
    <div className="blocked transfer-success">
      <img src={blocked} alt="Blocked" />
      <h2>
        Your current IP address appears to originate from a country or region that is restricted
        from accessing app.superposition.finance.
      </h2>
      <p>
        You can still visit our website at&nbsp;
        <a
          href="https://superposition.finance"
          target="_blank"
          rel="noopener noreferrer"
          className="clickable">
          superposition.finance
        </a>
      </p>
      <button className="sp-btn tertiary full-w large" onClick={closeBlock}>
        Close
      </button>
    </div>
  )
}
