import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { fetchMultiplyPortfolio } from 'state/thunks/fetchMultiplyPortfolio'
import { types } from '@concordia/super-sdk'
import { selectCloseSlippage } from '../ui/form'
import { scaleDown } from 'toolbox/format'

type State = Loadable<types.SUserStrategy | null>
export const initialState = initLoadable<types.SUserStrategy | null>(null)

const multiplyPortfolio = createSlice({
  name: 'multiplyPortfolio',
  initialState,
  reducers: {
    resetMultiplyPortfolio: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMultiplyPortfolio.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchMultiplyPortfolio.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchMultiplyPortfolio.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export const { resetMultiplyPortfolio } = multiplyPortfolio.actions
export default multiplyPortfolio.reducer
export const selectMultiplyPortfolio = (s: RootState) => s.user.multiplyPortfolio.value
export const selectMultiplyPortfolioBusy = (s: RootState) =>
  s.user.multiplyPortfolio.status === 'busy'
export const selectMultiplyPortfolioLoaded = (s: RootState) => s.user.multiplyPortfolio.loadedOnce
export const selectMultiplyPortfolioErrored = (s: RootState) =>
  s.user.multiplyPortfolio.status === 'errored'

export const selectLoadedMultiplyPosition = createSelector(
  [selectMultiplyPortfolio, selectMultiplyPortfolioLoaded],
  (portfolio, loaded) => {
    if (!loaded || !portfolio) {
      return null
    }

    return portfolio
  }
)

export const selectLoadedEstimatedClose = createSelector(
  [selectLoadedMultiplyPosition],
  (position) => {
    if (!position) {
      return 0
    }
    const nativeEstimatedClose = position.estimated_closing_amount
    const APT_DECIMALS = 8
    const estimatedClose = scaleDown(nativeEstimatedClose, APT_DECIMALS)
    return estimatedClose
  }
)

export const selectCloseWithSlippage = createSelector(
  [selectLoadedEstimatedClose, selectCloseSlippage, selectLoadedMultiplyPosition],
  (estimatedCloseAmount, slippage, multiplyPosition) => {
    const normalizedSlippage = Number(slippage) / 100
    const multiplier = multiplyPosition?.leverage_value
    // The slippage scales with the multiplier
    // because the trade is performed on the full leveraged amount
    const leveragedSlippage = normalizedSlippage * Number(multiplier)
    if (leveragedSlippage >= 1) {
      throw new Error('Selected slippage is too high')
    }
    const minimumAcceptedRate = 1 - leveragedSlippage
    const minimumAcceptedAmount = estimatedCloseAmount * minimumAcceptedRate
    return minimumAcceptedAmount
  }
)
