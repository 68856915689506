import { ReactNode } from 'react'

export interface ColumnProps {
  title: string
  children: ReactNode
  leftTitle?: boolean
}

function Column({ title, children, leftTitle }: ColumnProps) {
  const leftClass = leftTitle ? 'left-title' : ''
  return (
    <div className={`sp-custom-column`}>
      <span className={`is-flex title-box ${leftClass}`}>
        <h3 className="title">{title}&nbsp;</h3>
        <p className="comp">composition</p>
      </span>
      {children}
    </div>
  )
}

export default Column
