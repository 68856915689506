import { createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { createSelector } from 'reselect'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchStrategies } from 'state/thunks/fetchStrategies'
import { types } from '@concordia/super-sdk'

const initialState = initLoadable<types.SFlashLoanStrategies>([])

export const strategies = createSlice({
  name: 'strategies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStrategies.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchStrategies.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchStrategies.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export default strategies.reducer
export const selectStrategies = (s: RootState) => s.app.strategies.value
export const selectStrategiesBusy = (s: RootState) => s.app.strategies.status === 'busy'
export const selectStrategiesLoaded = (s: RootState) => s.app.strategies.loadedOnce
export const selectStrategiesErrored = (s: RootState) => s.app.strategies.status === 'errored'

export const selectLoadedStrategies = createSelector(
  [selectStrategiesLoaded, selectStrategies],
  (strategiesLoaded, strategies) => {
    if (!strategiesLoaded || isEmptyOrNil(strategies) || !strategies) {
      return []
    }
    return strategies
  }
)
