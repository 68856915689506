"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionError = exports.moneygunShoot = exports.registerCoin = exports.signSubmitWait = exports.signTransaction = exports.fundFromExisting = exports.createAndFund = void 0;
const aptos = __importStar(require("aptos"));
/** Create an account on Aptos and fund */
async function createAndFund(faucet, amount = 100e8) {
    const a = new aptos.AptosAccount();
    await faucet.fundAccount(a.address(), amount);
    return a;
}
exports.createAndFund = createAndFund;
/** Fund an existing account */
async function fundFromExisting(faucet, amount = 100e8, address) {
    await faucet.fundAccount(address, amount);
}
exports.fundFromExisting = fundFromExisting;
/**
 * @param client the AptosClient
 * @param account the AptosAccount that will sign
 * @param payload a payload to an entry function
 * @returns
 */
async function signTransaction(client, account, payload) {
    const rawtx = await client.generateTransaction(account.address(), payload);
    return await client.signTransaction(account, rawtx);
}
exports.signTransaction = signTransaction;
async function signSubmitWait(client, account, payload, checkSuccess = false) {
    const signed = await signTransaction(client, account, payload);
    const tx = await client.submitTransaction(signed);
    try {
        const res = await client.waitForTransactionWithResult(tx.hash, {
            checkSuccess,
        });
        if (!res.success) {
            const msg = res.vm_status.split(": ")[1];
            throw new TransactionError(msg);
        }
        return res;
    }
    catch (e) {
        console.log("error", e);
        throw e;
    }
}
exports.signSubmitWait = signSubmitWait;
function registerCoin(account, aptosClient, coin) {
    return signSubmitWait(aptosClient, account, {
        type: "entry_function_payload",
        function: "0x1::managed_coin::register",
        type_arguments: [coin],
        arguments: [],
    });
}
exports.registerCoin = registerCoin;
/** Shoot the moneygun to a user */
async function moneygunShoot(aptosClient, moneygunAddress, account, coin) {
    const payload = {
        type: "entry_function_payload",
        function: `${moneygunAddress}::moneygun::shoot`,
        type_arguments: [coin],
        arguments: [],
    };
    return signSubmitWait(aptosClient, account, payload);
}
exports.moneygunShoot = moneygunShoot;
class TransactionError extends Error {
    constructor(msg) {
        super(msg);
        this.name = "TransactionError";
    }
}
exports.TransactionError = TransactionError;
