import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { PointsList } from './PointsList'
import { MedianChart } from './MedianChart'
import { useAppSelector } from 'state/hooks'
import {
  DAILY_POINTS,
  selectBuiltPointsTabs,
  selectClosestDailyPoints,
  selectMedianPoints,
  selectPointsTab,
  selectPointsTotalsLoaded,
  selectRankedDailyPointsRank,
  selectTier,
  selectTopTenDailyRanks,
  selectTopTenRanks,
  selectTotalRanks,
  selectUserPoints,
  selectUserRank
} from 'state/slices/app/points'
import { formatPercentageCompact } from 'toolbox/format'
import Tabs from 'components/common/Tabs'

export function Leaderboard() {
  const { account, connected } = useWallet()
  const address = account?.address
  const isLoaded = useAppSelector(selectPointsTotalsLoaded)

  const userRank = useAppSelector((state) => selectUserRank(state, address))
  const userDailyRank = useAppSelector((state) => selectRankedDailyPointsRank(state, address))
  const totalRanks = useAppSelector(selectTotalRanks)
  const userPoints = useAppSelector((state) => selectUserPoints(state, address))
  const userRankPercentInitial = (userRank / totalRanks) * 100
  const userRankPercent = 100 - userRankPercentInitial
  const medianPoints = useAppSelector(selectMedianPoints)
  const topTen = useAppSelector(selectTopTenRanks)
  const topTenDaily = useAppSelector(selectTopTenDailyRanks)
  const pointsTabs = useAppSelector(selectBuiltPointsTabs)
  const currentTab = useAppSelector(selectPointsTab)
  const isDaily = currentTab === DAILY_POINTS
  const allTiers = useAppSelector(selectClosestDailyPoints)
  const userTier = useAppSelector((s) => selectTier(s, address))

  const dailyMedian = allTiers?.medianPoints

  const tierRows = [
    {
      tier: 'Tier 1',
      multiplier: '2.0x',
      range: isLoaded ? `${allTiers.closestTo25}+` : '--',
      isUser: connected && userPoints.multiplier === 2
    },
    {
      tier: 'Tier 2',
      multiplier: '1.5x',
      range: isLoaded ? `${allTiers.closestTo50} - ${allTiers.closestTo25}` : '--',
      isUser: connected && userPoints.multiplier === 1.5
    },
    {
      tier: 'Tier 3',
      multiplier: '1.2x',
      range: isLoaded ? `${allTiers.closestTo75} - ${allTiers.closestTo50}` : '--',
      isUser: connected && userPoints.multiplier === 1.2
    },
    {
      tier: 'Tier 4',
      multiplier: '1.0x',
      range: isLoaded ? `${0} - ${allTiers.closestTo75}` : '--',
      isUser: connected && userPoints.multiplier === 1
    }
  ]

  const tierRowDisplay = () => {
    const mapped = tierRows.map((t, i) => {
      const isUserClass = t.isUser ? 'is-user' : ''
      return (
        <div className={`tier-row ${isUserClass}`} key={i}>
          <div className="tier-label">
            {!!t.isUser && <span className="me">Me</span>}
            {t.tier}
          </div>
          {t.isUser ? <div className="coin smush">{t.multiplier}</div> : <div>{t.multiplier}</div>}
          <div className="tier-range">{t.range}</div>
        </div>
      )
    })
    return <div className="tier-display">{mapped}</div>
  }

  const userRow = {
    rank: userRank,
    address,
    spActivity: userPoints.runningtotalpoints,
    totalRunning: userPoints.runningtotalpoints,
    referralActivity: userPoints.referal_points,
    userRow: true
  }

  const createTopTenRows = (topTen) => {
    return topTen.map((user, index) => {
      return {
        rank: index + 1,
        address: user.address,
        totalRunning: user.runningtotalpoints,
        spActivity: user.runningtotalpoints,
        referralActivity: user.referal_points
      }
    })
  }

  const topTenRows = createTopTenRows(topTen)

  const createTopTenDailyRows = (topTen) => {
    return topTen.map((user, index) => {
      return {
        isDaily: true,
        rank: index + 1,
        address: user.address,
        spActivity: user.dailypoints,
        totalRunning: user.runningtotalpoints,
        referralActivity: user.referal_points
      }
    })
  }

  const userDailyRow = {
    isDaily: true,
    rank: userDailyRank,
    address,
    spActivity: userPoints.dailypoints,
    userTier,
    referralActivity: userPoints.referal_points,
    totalRunning: userPoints.runningtotalpoints,
    userRow: true
  }
  const topTenDailyRows = createTopTenDailyRows(topTenDaily)

  const allRows = [userRow, ...topTenRows]

  const allDailyRows = [userDailyRow, ...topTenDailyRows]

  const discoData = isDaily
    ? [
        {
          name: 'Median daily points',
          points: isLoaded ? dailyMedian : 0
        }
      ]
    : [
        {
          name: 'Median user points',
          points: medianPoints
        }
      ]

  const data = isDaily
    ? [
        {
          name: 'Median daily points',
          points: isLoaded ? dailyMedian : 0
        },
        {
          name: 'Your daily points',
          points: isLoaded ? userPoints.dailypoints : 0
        }
      ]
    : [
        {
          name: 'Median user points',
          points: medianPoints
        },
        {
          name: 'Your points',
          points: userPoints.runningtotalpoints
        }
      ]

  const rankDetails =
    userPoints.runningtotalpoints === 0
      ? `You haven't accumulated any points yet.`
      : `You have more points than ${formatPercentageCompact(userRankPercent / 100)} of users.`

  const rankCardDisplay = isDaily ? (
    <div className="daily-tiers">
      <p className="dt-label">Daily Points Tiers</p>
      {tierRowDisplay()}
    </div>
  ) : (
    <div className="rank">
      <div className="user-rank">
        <p className="rank-label">Global rank</p>
        <p className="rank-value">{connected ? `#${userRank}` : 'N/A'}</p>
      </div>
      <p className="rank-details">{rankDetails}</p>
    </div>
  )

  return (
    <div className="leaderboard">
      <h2>Leaderboard</h2>
      <Tabs tabs={pointsTabs} variant="tab-link" />
      <hr className="hr" />
      <div className="global-rank-card">
        {rankCardDisplay}
        <div className="median-chart">
          <MedianChart data={connected ? data : discoData} />
        </div>
      </div>
      <hr className="hr" />
      <PointsList rows={isDaily ? allDailyRows : allRows} isDaily={isDaily} />
    </div>
  )
}
