import React, { CSSProperties } from 'react'
import { formatCurrency, formatPercentageCompact } from 'toolbox/format'
import { isRedZone, isYellowZone } from 'state/slices/user/portfolio'
import { selectBrokersErrored } from 'state/slices/app/brokers'
import { useAppSelector } from 'state/hooks'

type SegmentedProgressBarProps = {
  minEquityVal: number
  totalCollatVal: number
  liabilityVal: number
  freeEquityVal: number
  isLoadedUser: boolean
  healthFactor: number
}

const styles = {
  container: () => {
    const props: CSSProperties = {
      display: 'flex',
      width: '99%',
      height: 4,
      backgroundColor: 'transparent',
      borderRadius: 4,
      position: 'relative',
      marginTop: '1rem'
    }
    return props
  },
  containerEmpty: () => {
    const props: CSSProperties = {
      display: 'flex',
      width: '100%',
      height: 4,
      backgroundColor: 'transparent',
      borderRadius: 4,
      position: 'relative',
      marginTop: '1rem'
    }
    return props
  },
  containerUnhealthy: () => {
    const props: CSSProperties = {
      display: 'flex',
      width: '99.2%',
      height: 4,
      backgroundColor: 'transparent',
      borderRadius: 4,
      position: 'relative',
      marginTop: '1rem'
    }
    return props
  },
  segment: (width: number, color?: string) => ({
    flex: `0 0 ${width}%`,
    backgroundColor: color || '#6200ea',
    borderRadius: 4,
    transition: 'flex 0.5s'
  }),
  gap1: (): CSSProperties => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    // Apply your gap styles here
    flex: '0 0 .8%', // Adjust the gap width here
    backgroundColor: 'transparent'
  }),

  gap2: (): CSSProperties => ({
    flex: `0 0 0.2%`, // Adjust the gap width here
    backgroundColor: 'transparent'
  })
}

const colorMap = {
  blue: '#1d41ff',
  green: '#00DD5C',
  red: '#FF2E00',
  orange: '#F90',
  black: '#000',
  grey: '#0000000d'
}

const BlackCircle = () => (
  <div
    style={{
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: 'black',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }}
  />
)

const SegmentedProgressBar: React.FC<SegmentedProgressBarProps> = ({
  minEquityVal,
  totalCollatVal,
  liabilityVal,
  isLoadedUser,
  healthFactor
}) => {
  const minEquityPercent = (minEquityVal / totalCollatVal) * 100
  const liabilityPercent = (liabilityVal / totalCollatVal) * 100
  const freeEquityPercent = 100 - minEquityPercent - liabilityPercent
  const total = minEquityPercent + freeEquityPercent + liabilityPercent
  const push = minEquityPercent < 4
  const bigPush = minEquityPercent < 1.5
  const brokersErroredAfterRetry = useAppSelector(selectBrokersErrored)

  if (freeEquityPercent < 0) {
    const diffTo100 = 100 - minEquityPercent
    return (
      <div style={styles.containerUnhealthy()}>
        <div style={styles.segment(minEquityPercent, colorMap.red)}></div>
        <div style={styles.gap1()}>
          <>
            <BlackCircle />
            <SegLabel
              lightValue={minEquityVal}
              value={minEquityPercent}
              label="Min. required equity"
              tooltip="Portfolio is unhealthy. Increase collateral or reduce debt to avoid liquidation."
              push={push}
              bigPush={bigPush}
            />
          </>
        </div>
        <div style={styles.segment(diffTo100, colorMap.red)}></div>
      </div>
    )
  }

  if (!isLoadedUser || !total || isNaN(total) || totalCollatVal === 0 || brokersErroredAfterRetry) {
    return (
      <div style={styles.containerEmpty()}>
        <div style={styles.segment(49.25, colorMap.grey)}></div>
        <div style={styles.gap1()}>
          <BlackCircle />
          <EmptySegLabel
            label={'Min. required equity'}
            lightValue={'N/A'}
            tooltip={'Supply collateral below by clicking the + button to receive a health rating'}
          />
        </div>
        <div style={styles.segment(49.25, colorMap.grey)}></div>
      </div>
    )
  }

  let riskColor = colorMap.green
  const isYellow = isYellowZone(healthFactor)
  const isRed = isRedZone(healthFactor)
  if (isYellow) {
    riskColor = colorMap.orange
  }
  if (isRed) {
    riskColor = colorMap.red
  }

  return (
    <div style={styles.container()}>
      <div style={styles.segment(minEquityPercent, riskColor)}></div>
      <div style={styles.gap1()}>
        <>
          <BlackCircle />{' '}
          <SegLabel
            lightValue={minEquityVal}
            value={minEquityPercent}
            label="Min. required equity"
            tooltip="Minimum equity value USD required to avoid liquidation."
            push={push}
            bigPush={bigPush}
          />
        </>
      </div>
      <div style={styles.segment(freeEquityPercent, riskColor)}></div>
      <div style={styles.gap2()}></div>
      <div style={styles.segment(liabilityPercent, colorMap.blue)}></div>
    </div>
  )
}

export default SegmentedProgressBar

function SegLabel({
  lightValue,
  value,
  label,
  push,
  tooltip,
  bigPush
}: {
  lightValue: number
  value: number
  label: string
  withinTenPercent?: boolean
  push?: boolean
  minLessFive?: boolean
  tooltip?: string
  bigPush?: boolean
}) {
  const bigPushClass = bigPush ? 'big' : ''
  const pushClass = push ? `push-right ${bigPushClass}` : ''

  return (
    <div className={`seg-label center ${pushClass}`}>
      <div className={`values `}>
        <p className="value">{formatCurrency(lightValue)}</p>
        <p className="light-value">{formatPercentageCompact(value / 100)}</p>
      </div>
      <div className="tip-box desk-only">
        <p className={` no-wrap label tip-line`}>{label}</p>
        {tooltip && <span className={`tip-value tip-shift top-down`}>{tooltip}</span>}
      </div>
    </div>
  )
}

function EmptySegLabel({
  label,
  lightValue,
  tooltip
}: {
  label: string
  lightValue: string
  tooltip: string
}) {
  return (
    <div className={`seg-label center`}>
      <div className={`values`}>
        <p className="xtra-light-value">{lightValue}</p>
      </div>
      <div className={` tip-box`}>
        <p className="no-wrap label tip-line">{label}</p>
        {tooltip && <span className={`tip-value tip-shift top-down`}>{tooltip}</span>}
      </div>
    </div>
  )
}
