export default function Footer() {
  return (
    <div className="footer">
      <FooterLinks />
    </div>
  )
}

function FooterLinks() {
  const currentYear = new Date().getFullYear()

  return (
    <>
      <div className="footer-links">
        <p className="year">&copy;&nbsp;{currentYear}&nbsp;Superposition</p>
        <a href="https://www.superposition.finance/" target="_blank" rel="noreferrer">
          Website
        </a>
        <a href="https://docs.superposition.finance/" target="_blank" rel="noreferrer">
          Docs
        </a>
        <a href="https://www.superposition.finance/tos" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a href="https://www.superposition.finance/privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
      <div className="footer-links-mobile">
        <div className="links">
          <a href="https://www.superposition.finance/" target="_blank" rel="noreferrer">
            Website
          </a>
          <a href="https://docs.superposition.finance/" target="_blank" rel="noreferrer">
            Docs
          </a>
          <a href="https://www.superposition.finance/tos" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          <a href="https://www.superposition.finance/privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </div>
        <p className="year">&copy;&nbsp;{currentYear}&nbsp;Superposition</p>
      </div>
    </>
  )
}
