import { onlyNumbers, prettyTokenBal } from 'toolbox/format'
import { ParamProps } from '../txForm/Param'
import CloseButton from '../common/CloseButton'
import DataPoint from '../common/DataPoint'
import ParamList from '../txForm/ParamList'
import { getTokenMeta, selectTokens } from 'state/slices/app/meta'
import { useAppSelector } from 'state/hooks'
import {
  closeClosePosition,
  selectBuiltSlippageTabs,
  selectCloseSlippage,
  selectSlippageMenuOpen,
  setCloseSlippageVal
} from 'state/slices/ui/form'
import { SUserStrategy } from '@concordia/super-sdk/src/io'
import Tabs from 'components/common/Tabs'
import { selectLoadedEstimatedClose } from 'state/slices/user/multiplyPortfolio'
import { useState } from 'react'

export interface ClosePositionProps {
  multiParams: ParamProps[]
  action: () => void
  disabled: boolean
  userVault: SUserStrategy
}

function getStringBeforeHyphen(inputString: string): string {
  const match = inputString.match(/^(.*?)-.*/)
  if (match) {
    return match[1]
  }
  return inputString
}

export function ClosePosition({ multiParams, action, disabled, userVault }: ClosePositionProps) {
  const tokens = useAppSelector(selectTokens)
  const borrowTokenName = getStringBeforeHyphen(userVault?.strategy_name)
  const token = getTokenMeta(borrowTokenName, tokens)
  const isSlippageOpen = useAppSelector(selectSlippageMenuOpen)
  const slippageTabs = useAppSelector(selectBuiltSlippageTabs)
  const selectedSlippage = useAppSelector(selectCloseSlippage)
  const estimatedClose = useAppSelector(selectLoadedEstimatedClose)
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const slippageMenu = () => {
    return (
      <div className="slippage-menu">
        <div className="slippage-input">
          <Tabs tabs={slippageTabs} variant={'basic'} />
          <div className="slip-input">
            <input
              type="text"
              value={selectedSlippage}
              onChange={(s) => setCloseSlippageVal(onlyNumbers(s.target.value))}
            />
            <p>%</p>
          </div>
        </div>
        <p className="slip-info">
          Your transaction will revert if the price changes unfavorably by more than this percentage
        </p>
      </div>
    )
  }

  return (
    <div className="tx-form">
      <CloseButton cb={closeClosePosition} />
      <div className="m-title">Review multiply details</div>
      <div className="amount-box">
        <DataPoint
          label="Amount to receive"
          value={`${prettyTokenBal(estimatedClose)} ${token?.ticker}`}
          light
        />
        <p className="slip-details">*Due to slippage, amount may vary</p>
      </div>

      <ParamList params={multiParams} />
      {isSlippageOpen && slippageMenu()}
      <div style={{ display: 'flex', alignItems: 'center' }} className="slip-check">
        <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
        <p style={{ margin: '0 0 0 8px' }}>I agree to close this position despite high slippage</p>
      </div>
      <a
        className="basic-link"
        href="https://medium.com/superp-fi/multiply-how-to-close-a-position-b8da77f5d413"
        target="_blank"
        rel="noopener noreferrer">
        Learn more about slippage
      </a>
      <div className="review-buttons">
        <button className="sp-btn secondary blue large full-w" onClick={closeClosePosition}>
          <p className="pri">Cancel</p>
        </button>
        <button
          className="sp-btn primary large full-w"
          onClick={action}
          disabled={disabled || !isChecked}>
          {!isChecked ? 'Confirm agree' : 'Close position'}
        </button>
      </div>
      <p className="t_c">
        By clicking multiply, I authorize Superposition to initiate the transaction detailed above
      </p>
    </div>
  )
}
