import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { commaFloor } from 'toolbox/format'

export interface MedianChartProps {
  data: {
    name: string
    points: number
  }[]
}

export function MedianChart(props: MedianChartProps) {
  const CustomLabel = ({ x, y, value }) => {
    const roundedValue = commaFloor(value)
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={28}
        fontSize={18}
        fontWeight={500}
        textAnchor="middle"
        fill="#1d41ff">
        {roundedValue}
      </text>
    )
  }
  return (
    <ResponsiveContainer width={'100%'} height={250}>
      <BarChart data={props.data} barCategoryGap={250} margin={{ top: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar dataKey="points" fill="#1D41FF" barSize={57} activeBar={false}>
          <LabelList dataKey="points" content={CustomLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
