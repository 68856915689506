import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'
import { isEmptyOrNil } from 'toolbox/account'
import Cookies from 'js-cookie'

const MAX_ATTEMPTS = 5

export const fetchToggles = createAsyncThunk(
  'toggles/fetchAll',
  async (): Promise<types.SFeatureToggles> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not provided')
    }

    let attempt = 0

    const baseDelay = 1000

    while (attempt < MAX_ATTEMPTS) {
      try {
        const toggles = await sdk.fetcher.fetchToggles()
        console.log('Feature Toggles: ', toggles)
        if (isEmptyOrNil(toggles)) {
          throw new Error('Failed to fetch toggles')
        }
        const updatedToggles: types.SFeatureToggles = { ...toggles }

        Object.keys(updatedToggles).forEach((key) => {
          const toggle = updatedToggles[key]
          const cookieValue = Cookies.get(key)

          if (cookieValue !== undefined && cookieValue === 'true') {
            toggle.enabled = true
          } else if (cookieValue !== undefined && cookieValue === 'false') {
            toggle.enabled = false
          }
        })

        return updatedToggles
      } catch (e: any) {
        attempt++
        console.log(`Attempt ${attempt}: Failed to fetch toggles, retrying...`)

        if (attempt >= MAX_ATTEMPTS) {
          throw new Error(e.message || 'Failed to fetch toggles after multiple attempts')
        }

        const delay = baseDelay * Math.pow(2, attempt - 1) // Exponential backoff formula
        console.log(`Waiting ${delay} ms before next attempt...`)

        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }
)
