import logomark from 'assets/logos/logomark.svg'
import { Link, useLocation } from 'react-router-dom'
import {
  closeDisconnect,
  openDisconnect,
  selectDisconnectOpen,
  disconnectWallet,
  openWidgetSignIn
} from 'state/slices/ui/widget'
import SignIn from 'components/common/SignIn'
import WalletDisconnect from 'components/wallet/WalletDisconnect'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { closeDetails, selectDetailsOpen } from 'state/slices/ui/form'
import { FullModal } from './FullModal'
import BrokerDetails from 'pages/BrokerDetails'
import { useRef, useState } from 'react'
import { logAndToastError } from 'toolbox/toast'
import MoneygunDropdown from 'components/wallet/MoneygunDropdown'
import { selectPointsTotalsLoaded, selectUserPoints } from 'state/slices/app/points'
import { commaFloor } from 'toolbox/format'
import burgerImg from 'assets/icons/burger.svg'
import { BurgerNav } from './BurgerNav'
import walletImg from 'assets/icons/wallet.svg'
import { selectMultiplyToggleOn } from 'state/slices/app/toggles'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function TopNav() {
  const query = useQuery()
  const queryString = query.toString()
  const { disconnect, connected, account, signAndSubmitTransaction, wallet } = useWallet()
  const isDisconnectOpen = useAppSelector(selectDisconnectOpen)
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const targetElementRef = useRef(null)
  const isMSafe = wallet?.name === 'MSafe'
  const pointsTotalsLoaded = useAppSelector(selectPointsTotalsLoaded)
  const userPoints = useAppSelector((state) => selectUserPoints(state, account?.address))
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)
  const isMultiplyToggleOn = useAppSelector(selectMultiplyToggleOn)

  const dashSelected = pathname === '/'
  const statsSelected = pathname.includes('/markets')
  const activitySelected = pathname.includes('/activity')
  const pointsSelected = pathname.includes('/points')
  const multiplySelected = pathname.includes('/multiply')
  const selectedStyle = 'selected'

  const dividerClass = !dashSelected ? 'divide' : ''
  const isDetailsOpen = useAppSelector(selectDetailsOpen)

  const envNet = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'
  const isMainnet = envNet === 'mainnet'

  const tryDisconnect = async () => {
    try {
      await disconnect()
      disconnectWallet()
    } catch (e: any) {
      logAndToastError('Wallet', e.message)
    }
  }

  const moneyGunDropdownProps = {
    isMainnet,
    connected,
    dispatch,
    account,
    signAndSubmitTransaction,
    isMSafe
  }

  return (
    <>
      <div className={`nav-wrapper  ${dividerClass}`} ref={targetElementRef}>
        <nav className="top-nav">
          <div className="left-logo">
            <Link to={`/?${queryString}`}>
              <img src={logomark} alt="superposition" className="logomark" />
            </Link>
          </div>
          {/* <div className="burger-button" onClick={() => setIsBurgerOpen(!isBurgerOpen)}></div> */}
          <div className="mid-links piece">
            <Link
              to={`/?${queryString}`}
              className={`a-link bg-grey-select ${dashSelected ? selectedStyle : ''}`}>
              Borrow/Lend
            </Link>
            {!!isMultiplyToggleOn && (
              <Link
                to={`/multiply/?${queryString}`}
                className={`a-link bg-grey-select ${multiplySelected ? selectedStyle : ''}`}>
                Multiply
              </Link>
            )}
            <Link
              to={`/markets/?${queryString}`}
              className={`a-link bg-grey-select ${statsSelected ? selectedStyle : ''}`}>
              Markets
            </Link>
            <Link
              to={`/activity/?${queryString}`}
              className={`a-link bg-grey-select ${activitySelected ? selectedStyle : ''}`}>
              Activity
            </Link>
            <Link
              to={`/points/?${queryString}`}
              className={`a-link flex-link bg-grey-select ${pointsSelected ? selectedStyle : ''}`}>
              <p className="p-points">Points</p>
              {!!pointsTotalsLoaded && userPoints?.runningtotalpoints !== 0 && (
                <p className="p-total">{commaFloor(userPoints.runningtotalpoints)}</p>
              )}
              <p className="new">New</p>
            </Link>
          </div>
          <div className="buttons">
            <MoneygunDropdown {...moneyGunDropdownProps} />
            <button
              className="mobile-only wallet-img"
              onClick={connected ? openDisconnect : openWidgetSignIn}>
              <img src={walletImg} alt="wallet" />
            </button>
            <button onClick={() => setIsBurgerOpen(true)} className="mobile-only burger-button tsp">
              <img src={burgerImg} alt="menu" />
            </button>
            {isBurgerOpen && <BurgerNav cb={() => setIsBurgerOpen(false)} />}

            <SignIn open={openWidgetSignIn} connected={connected} disco={openDisconnect} />
            {isDisconnectOpen && (
              <WalletDisconnect disconnect={tryDisconnect} closeModal={closeDisconnect} />
            )}
          </div>
        </nav>
      </div>
      <FullModal open={isDetailsOpen} onClose={closeDetails}>
        <BrokerDetails />
      </FullModal>
    </>
  )
}

export default TopNav
