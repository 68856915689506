export interface Loadable<T> {
  value: T
  loadedOnce: boolean
  status: 'idle' | 'busy' | 'errored'
}

export function initLoadable<T>(value: T): Loadable<T> {
  return {
    value,
    loadedOnce: false,
    status: 'idle'
  }
}
