export interface SimpleMetaDisplayProps {
  firstIcon: string
  value: string
  onClickCallback?: () => void
  noHover?: boolean
}

export function SimpleMetaDisplay({
  firstIcon,
  value,
  onClickCallback,
  noHover
}: SimpleMetaDisplayProps) {
  const noHoverClass = noHover ? 'no-hover' : ''
  return (
    <div className={`simple-meta-display ${noHoverClass} `} onClick={onClickCallback}>
      <div className="simple-meta">
        <img src={firstIcon} alt={value} />
        <div className="divider-simple" />
        <p className="simple-point">{value}</p>
      </div>
    </div>
  )
}
