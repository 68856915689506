import { createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { createSelector } from 'reselect'
import { getTokenMeta, selectMeta, selectMetaLoaded, getChainMeta } from './meta'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchBrokers } from 'state/thunks/fetchBrokers'
import { types } from '@concordia/super-sdk'
import { MetaBroker } from 'state/mock'

const initialState = initLoadable<types.SBroker[]>([])

export const brokers = createSlice({
  name: 'brokers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBrokers.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchBrokers.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchBrokers.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export default brokers.reducer
export const selectBrokers = (s: RootState) => s.app.brokers.value
export const selectBrokersBusy = (s: RootState) => s.app.brokers.status === 'busy'
export const selectBrokersLoaded = (s: RootState) => s.app.brokers.loadedOnce
export const selectBrokersErrored = (s: RootState) => s.app.brokers.status === 'errored'

export const DEPOSIT_NOTE = 'DepositNote'
export const LOAN_NOTE = 'LoanNote'

export const selectBrokersWithMeta = createSelector(
  [selectBrokersLoaded, selectMetaLoaded, selectBrokers, selectMeta],
  (brokersLoaded, metaLoaded, brokers, meta) => {
    if (!brokersLoaded || !metaLoaded) {
      return []
    }
    const { tokens, chains } = meta

    // create brokers with meta in the order of
    // the token meta
    let brokersWithMeta: MetaBroker[] = []
    for (const assetName in tokens) {
      const tokenMeta = getTokenMeta(assetName, tokens)
      const chainMeta = getChainMeta(tokenMeta.chainID, chains)
      const broker = brokers.find((b) => b.underlyingAsset.name === assetName)
      brokersWithMeta.push({
        ...broker,
        tokenMeta,
        chainMeta
      })
    }

    return brokersWithMeta
  }
)

export const selectPricesFromBrokers = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return {}
    }
    const prices: types.NameBalanceMap = {}
    brokers?.forEach((b) => {
      prices[b.underlyingAsset.name] = b.underlyingAsset.price
      prices[b.depositNote.name] = b.depositNote.price
      prices[b.loanNote.name] = b.loanNote.price
    })
    return prices
  }
)

export const selectUnderlyingNamesFromBrokers = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return []
    }
    const names: string[] = []
    brokers?.forEach((b) => {
      names.push(b.underlyingAsset.name)
    })
    return names
  }
)

export const selectInstruments = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return []
    }
    if (isEmptyOrNil(brokers)) {
      return []
    }
    const instruments: types.SInstrument[] = []
    brokers?.forEach((b) => {
      instruments.push(b.underlyingAsset)
      instruments.push(b.depositNote)
      instruments.push(b.loanNote)
    })
    return instruments
  }
)

export const getPriceFromName = (name: string, prices: types.NameBalanceMap) => {
  if (isEmptyOrNil(prices)) {
    return 0
  }
  return prices[name]
}

export const getBrokerByAddress = (brokers: MetaBroker[], address: string) => {
  if (isEmptyOrNil(brokers)) {
    return null
  }
  return brokers.find((b) => b.networkAddress === address)
}

export const getBrokerByName = (brokers: MetaBroker[], name: string) => {
  if (isEmptyOrNil(brokers)) {
    return null
  }
  return brokers.find((b) => b.underlyingAsset.name === name)
}

export const getBrokerByLoanNoteName = (brokers: MetaBroker[], name: string) => {
  if (isEmptyOrNil(brokers)) {
    return null
  }
  return brokers.find((b) => b.loanNote.name === name)
}

export const selectPlatformTotalLend = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return 0
    }
    if (isEmptyOrNil(brokers)) {
      return 0
    }
    let total = 0
    brokers?.forEach((b) => {
      const totalLend = b.scaledAvailableLiquidityUnderlying + b.scaledTotalBorrowedUnderlying
      total += totalLend
    })
    return total
  }
)

export const selectPlatformTotalBorrow = createSelector(
  [selectBrokersLoaded, selectBrokers],
  (loaded, brokers) => {
    if (!loaded) {
      return 0
    }
    if (isEmptyOrNil(brokers)) {
      return 0
    }
    let total = 0
    brokers?.forEach((b) => {
      total += b.scaledTotalBorrowedUnderlying
    })
    return total
  }
)

export const calcBrokerTotalLendDollars = (loaded, brokers, prices, name) => {
  if (!loaded) {
    return 0
  }
  if (isEmptyOrNil(brokers)) {
    return 0
  }
  const broker = getBrokerByName(brokers, name)
  if (!broker) {
    return 0
  }
  const underlyingPrice = getPriceFromName(broker.underlyingAsset.name, prices)
  const total =
    (broker.scaledAvailableLiquidityUnderlying + broker.scaledTotalBorrowedUnderlying) *
    underlyingPrice
  return total
}

export const calcBrokerTotalBorrowDollars = (loaded, brokers, prices, name) => {
  if (!loaded) {
    return 0
  }
  if (isEmptyOrNil(brokers)) {
    return 0
  }
  const broker = getBrokerByName(brokers, name)
  if (!broker) {
    return 0
  }
  const underlyingPrice = getPriceFromName(broker.underlyingAsset.name, prices)
  const total = broker.scaledTotalBorrowedUnderlying * underlyingPrice
  return total
}
