import { TransactionHistory } from '@concordia/super-sdk/src/io'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import list from 'assets/graphics/list.svg'
import { ActivityRow } from 'components/activity/ActivityRow'
import SearchBar, { SearchBarProps } from 'components/common/SearchBar'
import Tabs from 'components/common/Tabs'
import { useCallback, useEffect, useState } from 'react'
import { useAppSelector } from 'state/hooks'
import {
  selectBrokerHistoriesBusy,
  selectBrokerHistoriesLoaded,
  selectBrokerTransactionHistory,
  selectUserTransactionHistory
} from 'state/slices/app/brokerHistories'
import { selectActivityInput, selectLastRefresh, setActivitySearch } from 'state/slices/ui/dash'
import {
  ALL_HISTORY,
  USER_HISTORY,
  selectBuiltHistoryTabs,
  selectHistoryTab
} from 'state/slices/ui/form'
import { formatDateString } from 'toolbox/format'
import { CustomDropdown, CustomDropdownProps } from 'components/activity/CustomDropdown'
import { useMemo } from 'react' // Add this import
import { fetchHistories, kickAndFetchHistories } from 'state/fetch'
import { selectUnderlyingNamesFromBrokers } from 'state/slices/app/brokers'
import circleArrow from 'assets/icons/circle-arrow.svg'
import { ReactComponent as BlackDownChev } from 'assets/icons/down-chev-black.svg'

const TimeFilterOptions = {
  ONE_DAY: '1D',
  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  ONE_YEAR: '1Y',
  YTD: 'YTD',
  ALL: 'All'
}

const TypeFilterOptions = {
  ALL: 'All types',
  DEPOSIT: 'Deposit',
  REDEEM: 'Withdraw',
  BORROW: 'Borrow',
  REPAY: 'Repay',
  LIQUIDATE: 'Liquidate'
}

const AssetFilterOptions = {
  ALL: 'All assets',
  USDC: 'USDC',
  APT: 'APT',
  THAPT: 'THAPT',
  STHAPT: 'STHAPT',
  AMAPT: 'AMAPT',
  STAPT: 'STAPT'
}

export function Activity() {
  const loaded = useAppSelector(selectBrokerHistoriesLoaded)
  const activityData = useAppSelector(selectUserTransactionHistory)
  const [lastKick, setLastKick] = useState(Date.now())
  const brokerNames = useAppSelector(selectUnderlyingNamesFromBrokers)
  const { account } = useWallet()
  const lastRefresh = useAppSelector(selectLastRefresh)
  const [secondsSinceRefresh, setSecondsSinceRefresh] = useState(0)
  const [isRefreshHovered, setIsRefreshHovered] = useState(false)
  const [isSpinning, setIsSpinning] = useState(false)
  const brokerHistoriesLoading = useAppSelector(selectBrokerHistoriesBusy)

  const handleMouseEnter = () => {
    setIsRefreshHovered(true)
  }

  const handleMouseLeave = () => {
    setIsRefreshHovered(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now()
      const lastRefreshDate = lastRefresh
      const secondsDiff = Math.floor((now - lastRefreshDate) / 1000)

      if (secondsDiff >= 30) {
        clearInterval(interval)
        setSecondsSinceRefresh(30)
      } else {
        setSecondsSinceRefresh(secondsDiff)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [lastRefresh])

  const handleRefresh = () => {
    setIsSpinning(true)
    // Remove the class after the animation completes (1 second)
    setTimeout(() => setIsSpinning(false), 500)
    if (secondsSinceRefresh < 5 || brokerHistoriesLoading) {
      setSecondsSinceRefresh(0)
      return
    }

    const now = Date.now()
    const secondsFromLastKick = Math.floor((now - lastKick) / 1000)
    const hasAddress = account?.address
    const addressOrEmpty = hasAddress ? account?.address : ''
    if (secondsFromLastKick > 30) {
      setSecondsSinceRefresh(0)
      setLastKick(Date.now())
      kickAndFetchHistories(brokerNames, addressOrEmpty as string)
    } else {
      setSecondsSinceRefresh(0)
      fetchHistories(brokerNames, addressOrEmpty as string)
    }
  }

  const systemActivityData = useAppSelector(selectBrokerTransactionHistory)

  //TESTING ONLY
  // const userAddressCounts = countUserAddresses(systemActivityData)
  // console.log(userAddressCounts)
  const input = useAppSelector(selectActivityInput)
  const tabs = useAppSelector(selectBuiltHistoryTabs)
  const historyTab = useAppSelector(selectHistoryTab)

  //state for filtered transactions
  const [filteredUserTransactions, setFilteredUserTransactions] =
    useState<TransactionHistory[]>(activityData)
  const [filteredSystemTransactions, setFilteredSystemTransactions] =
    useState<TransactionHistory[]>(systemActivityData)

  const sortedFilteredUserTransactions = useMemo(() => {
    return filteredUserTransactions.sort((a, b) => b.time - a.time)
  }, [filteredUserTransactions])

  const sortedFilteredSystemTransactions = useMemo(() => {
    return filteredSystemTransactions.sort((a, b) => b.time - a.time)
  }, [filteredSystemTransactions])

  //state for pagination
  const [currentUserPage, setCurrentUserPage] = useState(1)
  const [currentSystemPage, setCurrentSystemPage] = useState(1)
  const ITEMS_PER_PAGE = 50

  const totalUserPages = useMemo(
    () => Math.ceil(sortedFilteredUserTransactions.length / ITEMS_PER_PAGE),
    [sortedFilteredUserTransactions]
  )
  const totalSystemPages = useMemo(
    () => Math.ceil(sortedFilteredSystemTransactions.length / ITEMS_PER_PAGE),
    [sortedFilteredSystemTransactions]
  )

  const indexOfLastItemUser = currentUserPage * ITEMS_PER_PAGE
  const indexOfFirstItemUser = indexOfLastItemUser - ITEMS_PER_PAGE

  const currentItemsUser = useMemo(
    () => sortedFilteredUserTransactions.slice(indexOfFirstItemUser, indexOfLastItemUser),
    [indexOfFirstItemUser, indexOfLastItemUser, sortedFilteredUserTransactions]
  )

  const indexOfLastItemSystem = currentSystemPage * ITEMS_PER_PAGE
  const indexOfFirstItemSystem = indexOfLastItemSystem - ITEMS_PER_PAGE

  const currentItemsSystem = useMemo(
    () => sortedFilteredSystemTransactions.slice(indexOfFirstItemSystem, indexOfLastItemSystem),
    [indexOfFirstItemSystem, indexOfLastItemSystem, sortedFilteredSystemTransactions]
  )

  const paginateUser = useCallback((pageNumber: number) => setCurrentUserPage(pageNumber), [])
  const paginateSystem = useCallback((pageNumber: number) => setCurrentSystemPage(pageNumber), [])

  const paginateDownUser = useCallback(() => {
    setCurrentUserPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))
  }, [])

  const paginateUpUser = useCallback(() => {
    setCurrentUserPage((prevPage) => (prevPage < totalUserPages ? prevPage + 1 : prevPage))
  }, [totalUserPages])

  const paginateDownSystem = useCallback(() => {
    setCurrentSystemPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))
  }, [])

  const paginateUpSystem = useCallback(() => {
    setCurrentSystemPage((prevPage) => (prevPage < totalSystemPages ? prevPage + 1 : prevPage))
  }, [totalSystemPages])

  const resetPaginationOnFilter = () => {
    setCurrentUserPage(1)
    setCurrentSystemPage(1)
  }

  //state for time filter
  const [isTimeFilterOpen, setIsTimeFilterOpen] = useState(false)
  const [timeFilterOption, setTimeFilterOption] = useState<string>(TimeFilterOptions.ALL)

  //state for type filter
  const [typeFilterOpen, setTypeFilterOpen] = useState(false)
  const [typeFilterOption, setTypeFilterOption] = useState<string>(TypeFilterOptions.ALL)

  //state for asset filter
  const [assetFilterOpen, setAssetFilterOpen] = useState(false)
  const [assetFilterOption, setAssetFilterOption] = useState<string>(AssetFilterOptions.ALL)

  //reset all filters
  const resetFilters = () => {
    setTimeFilterOption(TimeFilterOptions.ALL)
    setTypeFilterOption(TypeFilterOptions.ALL)
    setAssetFilterOption(AssetFilterOptions.ALL)
  }

  //create helpers to close dropdowns when one opens
  const openTimeFilter = (targetFilterState: boolean) => {
    setIsTimeFilterOpen(targetFilterState)
    setTypeFilterOpen(false)
    setAssetFilterOpen(false)
  }

  const openTypeFilter = (targetFilterState: boolean) => {
    setIsTimeFilterOpen(false)
    setTypeFilterOpen(targetFilterState)
    setAssetFilterOpen(false)
  }

  const openAssetFilter = (targetFilterState: boolean) => {
    setIsTimeFilterOpen(false)
    setTypeFilterOpen(false)
    setAssetFilterOpen(targetFilterState)
  }

  // useEffect to pass transactions through each filter and set state at the end
  useEffect(() => {
    const now = Date.now() / 1000 // Current time in seconds
    const startOfYear = new Date(new Date().getFullYear(), 0, 1).getTime() / 1000 // Start of current year

    const timeFilteredUser = activityData.filter((transaction) => {
      switch (timeFilterOption) {
        case TimeFilterOptions.ONE_DAY:
          return now - transaction.time <= 86400 // 24 * 60 * 60
        case TimeFilterOptions.ONE_WEEK:
          return now - transaction.time <= 604800 // 7 * 24 * 60 * 60
        case TimeFilterOptions.ONE_MONTH:
          return now - transaction.time <= 2629743 // ~30.44 days
        case TimeFilterOptions.ONE_YEAR:
          return now - transaction.time <= 31556926 // ~365.24 days
        case TimeFilterOptions.YTD:
          return transaction.time >= startOfYear // Transactions since start of current year
        case TimeFilterOptions.ALL:
          return true
        default:
          return true
      }
    })

    const timeFilteredSystem = systemActivityData.filter((transaction) => {
      switch (timeFilterOption) {
        case TimeFilterOptions.ONE_DAY:
          return now - transaction.time <= 86400 // 24 * 60 * 60
        case TimeFilterOptions.ONE_WEEK:
          return now - transaction.time <= 604800 // 7 * 24 * 60 * 60
        case TimeFilterOptions.ONE_MONTH:
          return now - transaction.time <= 2629743 // ~30.44 days
        case TimeFilterOptions.ONE_YEAR:
          return now - transaction.time <= 31556926 // ~365.24 days
        case TimeFilterOptions.YTD:
          return transaction.time >= startOfYear // Transactions since start of current year
        case TimeFilterOptions.ALL:
          return true
        default:
          return true
      }
    })

    const typeFilteredUser = timeFilteredUser.filter((transaction) => {
      switch (typeFilterOption) {
        case TypeFilterOptions.ALL:
          return true
        case TypeFilterOptions.DEPOSIT:
          return transaction.txType === 'lend'
        case TypeFilterOptions.REDEEM:
          return transaction.txType === 'redeem'
        case TypeFilterOptions.BORROW:
          return transaction.txType === 'borrow'
        case TypeFilterOptions.REPAY:
          return transaction.txType === 'repay'
        case TypeFilterOptions.LIQUIDATE:
          return transaction.txType === 'liquidate'
        default:
          return true
      }
    })

    const typeFilteredSystem = timeFilteredSystem.filter((transaction) => {
      switch (typeFilterOption) {
        case TypeFilterOptions.ALL:
          return true
        case TypeFilterOptions.DEPOSIT:
          return transaction.txType === 'lend'
        case TypeFilterOptions.REDEEM:
          return transaction.txType === 'redeem'
        case TypeFilterOptions.BORROW:
          return transaction.txType === 'borrow'
        case TypeFilterOptions.REPAY:
          return transaction.txType === 'repay'
        case TypeFilterOptions.LIQUIDATE:
          return transaction.txType === 'liquidate'
        default:
          return true
      }
    })

    const assetFilteredUser = typeFilteredUser.filter((transaction) => {
      switch (assetFilterOption) {
        case AssetFilterOptions.ALL:
          return true
        case AssetFilterOptions.USDC:
          return transaction.brokerName === 'usdc'
        case AssetFilterOptions.APT:
          return transaction.brokerName === 'aptos'
        case AssetFilterOptions.THAPT:
          return transaction.brokerName === 'thapt'
        case AssetFilterOptions.STHAPT:
          return transaction.brokerName === 'sthapt'
        case AssetFilterOptions.AMAPT:
          return transaction.brokerName === 'amapt'
        case AssetFilterOptions.STAPT:
          return transaction.brokerName === 'stapt'
        default:
          return true
      }
    })

    setFilteredUserTransactions(assetFilteredUser)

    const assetFilteredSystem = typeFilteredSystem.filter((transaction) => {
      switch (assetFilterOption) {
        case AssetFilterOptions.ALL:
          return true
        case AssetFilterOptions.USDC:
          return transaction.brokerName === 'usdc'
        case AssetFilterOptions.APT:
          return transaction.brokerName === 'aptos'
        case AssetFilterOptions.THAPT:
          return transaction.brokerName === 'thapt'
        case AssetFilterOptions.STHAPT:
          return transaction.brokerName === 'sthapt'
        case AssetFilterOptions.AMAPT:
          return transaction.brokerName === 'amapt'
        case AssetFilterOptions.STAPT:
          return transaction.brokerName === 'stapt'
        default:
          return true
      }
    })

    setFilteredSystemTransactions(assetFilteredSystem)
  }, [timeFilterOption, activityData, systemActivityData, typeFilterOption, assetFilterOption])

  const sortedActivityData = useMemo(() => {
    return currentItemsUser.filter((item) => {
      const date = new Date(item.time)
      const dateString = formatDateString(date)
      return (
        item.txType.toLowerCase().includes(input.toLowerCase()) ||
        item.brokerName.toLowerCase().includes(input.toLowerCase()) ||
        item.userAddress.toLowerCase().includes(input.toLowerCase()) ||
        item.version.toLowerCase().includes(input.toLowerCase()) ||
        dateString.yearly.toLowerCase().includes(input.toLowerCase()) ||
        dateString.hourly.toLowerCase().includes(input.toLowerCase())
      )
    })
  }, [currentItemsUser, input]) // Dependencies: currentItemsUser and input

  const systemSortedActivityData = useMemo(() => {
    return currentItemsSystem.filter((item) => {
      const date = new Date(item.time)
      const dateString = formatDateString(date)
      return (
        item.txType.toLowerCase().includes(input.toLowerCase()) ||
        item.brokerName.toLowerCase().includes(input.toLowerCase()) ||
        item.userAddress.toLowerCase().includes(input.toLowerCase()) ||
        item.version.toLowerCase().includes(input.toLowerCase()) ||
        dateString.yearly.toLowerCase().includes(input.toLowerCase()) ||
        dateString.hourly.toLowerCase().includes(input.toLowerCase())
      )
    })
  }, [currentItemsSystem, input]) // Dependencies: currentItemsSystem and input

  const activityRows = useMemo(() => {
    return sortedActivityData.map((a, i) => <ActivityRow {...a} key={i} />)
  }, [sortedActivityData]) // Dependency: sortedActivityData

  const systemActivityRows = useMemo(() => {
    return systemSortedActivityData.map((a, i) => <ActivityRow {...a} key={i} />)
  }, [systemSortedActivityData])

  const showNoActivity =
    !loaded ||
    (historyTab === ALL_HISTORY && systemActivityData.length === 0) ||
    (historyTab === USER_HISTORY && activityData.length === 0)

  const noActivity = (
    <div className="activity">
      <h1>Activity</h1>
      <Tabs tabs={tabs} variant="tab-link" />
      <div className="act-con not-loaded">
        <div className="cluster">
          <img src={list} alt="list" />
          <h3>Transaction history will appear here</h3>
          <h5>Currently there are no recent transactions recorded</h5>
        </div>
      </div>
    </div>
  )

  if (showNoActivity) return noActivity

  const showEmpty = !loaded

  const searchProps: SearchBarProps = {
    value: input,
    update: setActivitySearch,
    // baseline: true
    noMargin: true
  }

  const headings = ['Tx type', 'Time', 'Amount', 'Token', 'Address', '']

  const headingDisplay = headings.map((h, i) => {
    return (
      <p className={`act-column heading `} key={i}>
        {h}
      </p>
    )
  })
  // Dependency: systemSortedActivityData

  const isSystemHistory = historyTab === ALL_HISTORY

  const timeFilterDropdown: CustomDropdownProps = {
    options: Object.values(TimeFilterOptions),
    isOpen: isTimeFilterOpen,
    setIsOpen: openTimeFilter,
    selectedOption: timeFilterOption,
    setSelectedOption: setTimeFilterOption,
    resetPaginationOnFilter
  }

  const typeFilterDropdown: CustomDropdownProps = {
    options: Object.values(TypeFilterOptions),
    isOpen: typeFilterOpen,
    setIsOpen: openTypeFilter,
    selectedOption: typeFilterOption,
    setSelectedOption: setTypeFilterOption,
    resetPaginationOnFilter
  }

  const assetFilterDropdown: CustomDropdownProps = {
    options: Object.values(AssetFilterOptions),
    isOpen: assetFilterOpen,
    setIsOpen: openAssetFilter,
    selectedOption: assetFilterOption,
    setSelectedOption: setAssetFilterOption,
    resetPaginationOnFilter
  }

  const timeIsAll = timeFilterOption === TimeFilterOptions.ALL
  const typeIsAll = typeFilterOption === TypeFilterOptions.ALL
  const assetIsAll = assetFilterOption === AssetFilterOptions.ALL

  const showClearFilters = !timeIsAll || !typeIsAll || !assetIsAll

  const Pagination = ({
    totalSystemPages,
    totalUserPages,
    isSystemHistory,
    currentSystemPage,
    currentUserPage,
    paginateSystem,
    paginateUser
  }) => {
    const paginationButtons = useMemo(() => {
      const totalPages = isSystemHistory ? totalSystemPages : totalUserPages
      const currentPage = isSystemHistory ? currentSystemPage : currentUserPage
      const paginate = isSystemHistory ? paginateSystem : paginateUser

      let pages = []
      if (totalPages <= 5) {
        // If total pages is 5 or less, just display all the buttons
        pages = Array.from({ length: totalPages }, (_, i) => i + 1)
      } else {
        // Always include the first page
        pages.push(1)

        // Determine the range of page numbers to show
        let start = Math.max(2, currentPage - 1)
        let end = Math.min(currentPage + 1, totalPages - 1)

        if (currentPage - 1 <= 2) {
          // Close to start
          start = 2
          end = 4
        } else if (totalPages - currentPage <= 2) {
          // Close to end
          start = totalPages - 3
          end = totalPages - 1
        }

        // Ellipses for skipping sections
        if (start > 2) {
          pages.push('...')
        }
        pages.push(...Array.from({ length: end - start + 1 }, (_, i) => start + i))
        if (end < totalPages - 1) {
          pages.push('...')
        }

        // Always include the last page
        pages.push(totalPages)
      }

      // Map to buttons
      return pages.map((number) =>
        number === '...' ? (
          <span key={number}>...</span>
        ) : (
          <button
            className={number === currentPage ? 'selected' : ''}
            key={number}
            onClick={() => paginate(number)}>
            {number}
          </button>
        )
      )
    }, [
      totalSystemPages,
      totalUserPages,
      isSystemHistory,
      currentSystemPage,
      currentUserPage,
      paginateSystem,
      paginateUser
    ])

    return (
      <div className="pagination-controls">
        <BlackDownChev
          className="rotate-90 clickable"
          onClick={() => (isSystemHistory ? paginateDownSystem() : paginateDownUser())}
        />
        {paginationButtons}
        <BlackDownChev
          className="rotate-270 clickable"
          onClick={() => (isSystemHistory ? paginateUpSystem() : paginateUpUser())}
        />
      </div>
    )
  }

  const widthPercentage = (secondsSinceRefresh / 30) * 100
  const spinClass = isSpinning ? 'spin-animation' : ''

  return showEmpty ? (
    noActivity
  ) : (
    <div className="activity">
      <h1>Activity</h1>
      <div className="filters-bar no-top">
        <Tabs tabs={tabs} variant="tab-link" />
        <button
          className="sp-btn refresh"
          onClick={handleRefresh}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '120%',
              width: `${widthPercentage}%`,
              backgroundColor: 'white',
              transition: 'width 1s linear',
              zIndex: 2,
              visibility: isRefreshHovered ? 'hidden' : 'visible',
              marginLeft: '-1rem',
              marginRight: '-1rem'
            }}
          />
          <span className="content">Refresh</span>
          <img className={`content arrow ${spinClass}`} src={circleArrow} alt="refresh" />
        </button>
      </div>
      <div className="filters-bar">
        <div className="is-flex">
          <SearchBar {...searchProps} />
        </div>
        <div className="dropdowns">
          {showClearFilters && (
            <p className="clickable" onClick={resetFilters}>
              Clear filters x
            </p>
          )}
          <CustomDropdown {...timeFilterDropdown} />
          <CustomDropdown {...typeFilterDropdown} />
          <CustomDropdown {...assetFilterDropdown} />
        </div>
      </div>
      <hr className="hr-global-margin" />
      <div className="act-columns desk-only">{headingDisplay}</div>
      <div className="act">{isSystemHistory ? systemActivityRows : activityRows}</div>
      <Pagination
        totalSystemPages={totalSystemPages}
        totalUserPages={totalUserPages}
        isSystemHistory={isSystemHistory}
        currentSystemPage={currentSystemPage}
        currentUserPage={currentUserPage}
        paginateSystem={paginateSystem}
        paginateUser={paginateUser}
      />
    </div>
  )
}
