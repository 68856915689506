import React, { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { Modal } from 'layout/Modal'
import TxForm from 'components/txForm/TxForm'
import { selectFormattedPositions } from 'state/slices/user/portfolio'
import { selectPortfolioLoaded } from 'state/slices/user/portfolio'
import { isEmptyOrNil } from 'toolbox/account'
import TransferSuccess from 'components/txForm/TransferSuccess'
import { ErrorModal } from 'components/wallet/ErrorModal'
import { closeBlock, selectShowBlockModal } from 'state/slices/ui/dash'
import { BlockedModal } from './BlockedModal'
import {
  closeDoReferModal,
  closeReferralModal,
  selectDoReferModalOpen,
  selectReferralModalOpen
} from 'state/slices/app/points'
import { LinkModal } from 'components/points/LinkModal'
import { DoReferModal } from 'components/points/DoReferModal'
import Multiform from 'components/multiply/MultiForm'
import {
  selectCloseWithSlippage,
  selectMultiplyPortfolio
} from 'state/slices/user/multiplyPortfolio'
import { getBrokerByName, selectBrokersWithMeta } from 'state/slices/app/brokers'
import { scaleUp } from 'toolbox/format'
import {
  selecttActiveStrategy,
  selectActiveBroker,
  selectActiveMultiBorrowBroker,
  selectActiveMultiLendBroker,
  selectFormOpen,
  selectMultiplyFormOpen,
  closeForm,
  closeMultiplyForm
} from 'state/slices/ui/form'
import {
  selectTxError,
  selectTxSuccess,
  setTxErrorState,
  setTxSuccessState
} from 'state/slices/ui/transaction'
import { createSelector } from '@reduxjs/toolkit'

// Memoized modal components
const MemoizedTxForm = React.memo(TxForm)
const MemoizedMultiform = React.memo(Multiform)
const MemoizedTransferSuccess = React.memo(TransferSuccess)
const MemoizedErrorModal = React.memo(ErrorModal)
const MemoizedBlockedModal = React.memo(BlockedModal)
const MemoizedLinkModal = React.memo(LinkModal)
const MemoizedDoReferModal = React.memo(DoReferModal)

// Memoized selector
const selectModalState = createSelector(
  [
    selectFormattedPositions,
    selectPortfolioLoaded,
    selectFormOpen,
    selectActiveBroker,
    selectTxSuccess,
    selectTxError,
    selectShowBlockModal,
    selectReferralModalOpen,
    selectDoReferModalOpen,
    selectMultiplyFormOpen,
    selectActiveMultiBorrowBroker,
    selectActiveMultiLendBroker,
    selecttActiveStrategy,
    selectMultiplyPortfolio,
    selectCloseWithSlippage,
    selectBrokersWithMeta
  ],
  (
    positions,
    isLoadedUser,
    isFormOpen,
    formBroker,
    txSuccessState,
    txError,
    showBlockModal,
    referralModalOpen,
    doReferModalOpen,
    isMultiFormOpen,
    activeMultiBorrow,
    activeMultiLend,
    activeStrategy,
    userVault,
    closeMinimum,
    brokers
  ) => ({
    positions,
    isLoadedUser,
    isFormOpen,
    formBroker,
    txSuccessState,
    txError,
    showBlockModal,
    referralModalOpen,
    doReferModalOpen,
    isMultiFormOpen,
    activeMultiBorrow,
    activeMultiLend,
    activeStrategy,
    userVault,
    closeMinimum,
    brokers
  })
)

export function AppModals() {
  const {
    positions,
    isLoadedUser,
    isFormOpen,
    formBroker,
    txSuccessState,
    txError,
    showBlockModal,
    referralModalOpen,
    doReferModalOpen,
    isMultiFormOpen,
    activeMultiBorrow,
    activeMultiLend,
    activeStrategy,
    userVault,
    closeMinimum,
    brokers
  } = useAppSelector(selectModalState)

  const txSuccessOpen = !isEmptyOrNil(txSuccessState)
  const isErrorOpen = !isEmptyOrNil(txError)

  const { rawCloseMinimum } = useMemo(() => {
    const borrowBroker = getBrokerByName(brokers, 'aptos')
    const closeDecimals = borrowBroker?.underlyingAsset.decimals
    return {
      rawCloseMinimum: Math.floor(scaleUp(closeMinimum, closeDecimals))
    }
  }, [brokers, closeMinimum])

  return (
    <>
      <Modal open={isFormOpen} onClose={closeForm} transition={'bot-up'}>
        <MemoizedTxForm broker={formBroker} isLoadedUser={isLoadedUser} positions={positions} />
      </Modal>

      <Modal open={isMultiFormOpen} onClose={closeMultiplyForm} transition={'bot-up'}>
        <MemoizedMultiform
          borrowBroker={activeMultiBorrow}
          lendBroker={activeMultiLend}
          isLoadedUser={isLoadedUser}
          strategy={activeStrategy}
          userVault={userVault}
          minLiabilityCoinAmountOut={rawCloseMinimum}
        />
      </Modal>

      <Modal open={txSuccessOpen} onClose={() => setTxSuccessState(null)} transition={'bot-up'}>
        <MemoizedTransferSuccess />
      </Modal>

      <Modal open={isErrorOpen} onClose={() => setTxErrorState(null)} transition={'bot-up'}>
        <MemoizedErrorModal {...txError} />
      </Modal>

      <Modal open={showBlockModal} onClose={closeBlock} transition={'top-down'}>
        <MemoizedBlockedModal />
      </Modal>

      <Modal open={referralModalOpen} onClose={closeReferralModal} transition={'top-down'}>
        <MemoizedLinkModal />
      </Modal>

      <Modal open={doReferModalOpen} onClose={closeDoReferModal} transition={'top-down'}>
        <MemoizedDoReferModal />
      </Modal>
    </>
  )
}
