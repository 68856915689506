import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'

export type FetchPriceHistoryPayload = {
  instrumentNames: string[]
}

export const fetchPriceHistories = createAsyncThunk(
  'priceHistory/fetchAll',
  async (payload: FetchPriceHistoryPayload): Promise<types.HistoricalPrices> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const hist = await sdk.fetcher.fetchHistoricalPrices(payload.instrumentNames)
    // console.log('Price Histories:', hist)
    return hist
  }
)
