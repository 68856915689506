import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer, YAxis } from 'recharts'
import { formatCurrency, formatDateExact, formatDateSimple } from 'toolbox/format'
import { SimpleSpinner } from './SimpleSpinner'
import { useState } from 'react'
import { useAppSelector } from 'state/hooks'
import { selectUtilizationHistory } from 'state/slices/app/brokerHistories'
import { isEmptyOrNil } from 'toolbox/account'
import { timeTabs } from 'state/slices/app/brokerHistories'
import TimeFilterTabs from 'components/common/TimeFilterTabs'
import { AltLabel } from './AltLabel'

export function TwoLinePlatformTotals({ isLoaded }: { isLoaded: boolean }) {
  const [activeData, setActiveData] = useState(null)
  const activeLendTotal = activeData ? activeData.totalLend : null
  const activeBorrowTotal = activeData ? activeData.totalBorrowed : null
  const [activeTab, setActiveTab] = useState('YTD')
  const numberOfDays = timeTabs.find((tab) => tab.label === activeTab).days
  const utilHistory = useAppSelector((s) => selectUtilizationHistory(s, numberOfDays))

  if (isEmptyOrNil(utilHistory)) {
    return (
      <ResponsiveContainer width={'100%'} height={375}>
        <SimpleSpinner />
      </ResponsiveContainer>
    )
  }

  const handleMouseMove = (data) => {
    if (data.activePayload) {
      // Update state with the data of the hovered point
      setActiveData(data.activePayload[0].payload)
    }
  }

  const handleMouseLeave = () => {
    setActiveData(null)
  }

  const chartData = utilHistory
    .map((item: any) => ({
      time: formatDateSimple(Number(item?.timestamp) / 1000 - 86400),
      totalBorrowed: Number(item.totalBorrow.toFixed(2)),
      totalLend: Number(item.totalLend.toFixed(2)),
      exactTime: formatDateExact(Number(item?.timestamp) / 1000 - 86400)
    }))
    .reverse()

  const finalLendRate = chartData[chartData.length - 1].totalLend
  const finalBorrowRate = chartData[chartData.length - 1].totalBorrowed

  const rateDataPoints: DataPointProps[] = [
    {
      label: 'Total lend',
      value: activeData ? formatCurrency(activeLendTotal) : formatCurrency(finalLendRate),
      tooltip: 'Total sum value of all collaterals',

      isLoading: !isLoaded,
      colorBox: 'green'
    },
    {
      label: 'Total borrow',
      value: activeData ? formatCurrency(activeBorrowTotal) : formatCurrency(finalBorrowRate),
      tooltip: 'Total sum value of all borrows',

      isLoading: !isLoaded,
      colorBox: 'blue'
    }
  ]

  const timeTabsProps = {
    activeTab,
    setActiveTab,
    extraFormat: 'desk-only-no-width'
  }

  const timeTabsPropsMobile = {
    activeTab,
    setActiveTab,
    extraFormat: 'mobile-only'
  }

  return (
    <div className="chart-container">
      <div className="points-line">
        <div className="cluster-points">
          <DataPoint {...rateDataPoints[0]} />
          <DataPoint {...rateDataPoints[1]} />
        </div>
        <TimeFilterTabs {...timeTabsProps} />
      </div>
      <TimeFilterTabs {...timeTabsPropsMobile} />
      <ResponsiveContainer width={'100%'} height={375}>
        {isLoaded && !isEmptyOrNil(chartData) ? (
          <LineChart
            data={chartData}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            margin={{
              top: 0
            }}>
            <YAxis
              domain={['auto', 'auto']}
              hide={true}
              allowDataOverflow={false}
              padding={{ bottom: 20 }}
            />

            <XAxis
              dataKey="time"
              axisLine={false}
              tickLine={false}
              allowDataOverflow={false}
              interval={'preserveStartEnd'}
            />
            <Tooltip
              content={
                <AltLabel
                  value={formatCurrency(activeData?.totalLend)}
                  secondValue={formatCurrency(activeData?.totalBorrowed)}
                  labels={{
                    value: 'Daily Avg. Total Supply',
                    secondValue: 'Daily Avg. Total Borrow'
                  }}
                />
              }
            />
            {isLoaded && (
              <>
                <Line
                  dataKey="totalBorrowed"
                  stroke="#1D41FF"
                  dot={false}
                  isAnimationActive={true}
                  animationBegin={0}
                  strokeWidth={1.5}
                  animationEasing="ease-in-out"
                />
                <Line
                  dataKey="totalLend"
                  stroke="#00DD5C"
                  dot={false}
                  isAnimationActive={true}
                  animationBegin={300}
                  strokeWidth={1.5}
                  animationEasing="ease-in-out"
                />
              </>
            )}
          </LineChart>
        ) : (
          <SimpleSpinner />
        )}
      </ResponsiveContainer>
    </div>
  )
}
