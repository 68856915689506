import { useAppSelector } from 'state/hooks'
import { selectPortfolioBusy, selectPortfolioLoaded } from 'state/slices/user/portfolio'

export interface BigBalProps {
  bal: string
  disable: boolean
  label: string
  circle?: boolean
}

function BigBal({ bal, disable, label, circle }: BigBalProps) {
  const zero = bal === '$0' || bal === '$0.00'
  const disabled = disable || zero ? 'disabled' : ''
  const circ = circle ? 'circle' : ''
  const isBusyUser = useAppSelector(selectPortfolioBusy)
  const isLoadedUser = useAppSelector(selectPortfolioLoaded)

  if (zero) {
    bal = '0'
  }

  return (
    <div className={`big-bal ${disabled} ${circ}`}>
      <div className="text">
        <p className="bal">{isBusyUser && !isLoadedUser ? '--' : bal}</p>
        <p className="label">{label}</p>
      </div>
    </div>
  )
}

export default BigBal
