import { createAsyncThunk } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from 'state/context'
import { Referral } from 'state/slices/user/referral'

export type FetchReferralPayload = {
  address: string
}

export const fetchReferralCode = createAsyncThunk(
  'referralCode/fetchAll',
  async (payload: FetchReferralPayload): Promise<Referral> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }

    const zeroCheckedAdd =
      payload.address[2] === '0' ? '0x' + payload.address.slice(3) : payload.address
    const code = await sdk.fetcher.fetchReferralsCode(zeroCheckedAdd)
    console.log('User Referral Code:', code)
    return { referralCode: code }
  }
)
