import CloseButton from 'components/common/CloseButton'
import WidgetColumn from './WidgetColumn'
import WidgetSelect from './WidgetSelect'
import connect from 'assets/graphics/connect.svg'

export interface WidgetProps {
  closeWidget: () => void
  openSelect: () => void
  closeSelect: () => void
}

function Widget({ closeWidget, closeSelect }: WidgetProps) {
  const signInStatic = {
    h1: 'Connect',
    asset: connect,
    // h2: 'Connect to Concordia by connecting the associated account address.',
    // p: 'You can sign in with any address connected to Concordia.',
    p: '',
    url: 'https://www.concordia.systems/'
  }
  const staticText = signInStatic

  const selectProps = {
    closeSelect
  }

  return (
    <div className="account-widget">
      <CloseButton cb={closeWidget} />
      <WidgetColumn {...staticText} />
      <WidgetSelect {...selectProps} />
    </div>
  )
}

export default Widget
