import { createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { fetchMeta } from 'state/thunks/fetchMeta'
import { RootState } from 'state/store'
import { createSelector } from 'reselect'
import { TabProps } from 'components/common/Tab'
import { isEmpty, isNil } from 'ramda'
import { isEmptyOrNil } from 'toolbox/account'
import {
  ChainMeta,
  ChainMetaMap,
  DEFAULT_CHAIN,
  DEFAULT_TOKEN,
  TokenMeta,
  TokenMetaMap
} from 'state/tokens'

//slice
export interface AppMeta {
  tokens: TokenMetaMap
  chains: ChainMetaMap
}

export const initialState = initLoadable<AppMeta>(null)

export const meta = createSlice({
  name: 'meta',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMeta.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchMeta.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export default meta.reducer

//selectors
export const selectMeta = (s: RootState) => s.app.meta.value
export const selectMetaBusy = (s: RootState) => s.app.meta.status === 'busy'
export const selectMetaLoaded = (s: RootState) => s.app.meta.loadedOnce

export const selectTokens = (s: RootState) => s.app.meta.value?.tokens
export const selectLoadedTokens = createSelector(
  [selectMetaLoaded, selectTokens],
  (loaded, tokens) => {
    if (!loaded) {
      return [DEFAULT_TOKEN]
    }
    return tokens
  }
)

export const selectChains = (s: RootState) => s.app.meta.value?.chains
export const selectLoadedChains = createSelector(
  [selectMetaLoaded, selectChains],
  (loaded, chains) => {
    if (!loaded) {
      return { chain: DEFAULT_CHAIN }
    }
    return chains
  }
)

export const selectAptos = createSelector([selectMetaLoaded, selectMeta], (loaded, meta) => {
  if (!loaded) {
    return DEFAULT_TOKEN
  }
  return meta.tokens[100]
})

export const selectAptosChain = createSelector([selectMetaLoaded, selectMeta], (loaded, meta) => {
  if (!loaded) {
    return DEFAULT_CHAIN
  }
  return meta.chains['Aptos']
})

//prebuilds
export const selectLoadedChainLabels = createSelector(
  [selectMetaLoaded, selectMeta],
  (loaded, meta) => {
    if (loaded || !meta) {
      return []
    }

    const labels = Object.keys(meta.chains)
    return labels
  }
)

export const buildChainTabs = (
  labels: string[],
  selected: string,
  setChain: (s: string) => void
) => {
  const chainTabs: TabProps[] = labels.map((label) => ({
    label,
    active: label === selected,
    onClick: () => setChain(label)
  }))
  return chainTabs
}

export interface ChainUI {
  activeChain: string
  onClick: (s: string) => void
}

export const selectBuiltChainTabs = createSelector(
  [selectLoadedChainLabels, (_, chainUI: ChainUI) => chainUI],
  (labels, cui) => {
    const { activeChain, onClick } = cui
    return buildChainTabs(labels, activeChain, onClick)
  }
)

export function getTokenMeta(assetName: string, tokens: TokenMetaMap): TokenMeta {
  const hasTokens = !isEmpty(tokens) && !isNil(tokens)
  if (hasTokens) {
    const t = tokens[assetName]
    if (t) {
      return t
    }
  }
  return DEFAULT_TOKEN
}

export function getChainMeta(chainId: string, chains: ChainMetaMap): ChainMeta {
  const hasChains = !isEmptyOrNil(chains)

  if (hasChains) {
    const c = chains[chainId]
    if (c) {
      return c
    }
  }

  return DEFAULT_CHAIN
}
