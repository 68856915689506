import { useAppSelector } from 'state/hooks'
import FlashRow from '../dashboard/FlashRow'
import { selectBrokersLoaded, selectBrokersWithMeta } from 'state/slices/app/brokers'
import MarketsRow from './MarketsRow'
import { memo, useMemo } from 'react'

const MarketsRowMemo = memo(MarketsRow)

function MarketsList() {
  const brokers = useAppSelector(selectBrokersWithMeta)
  const isLoadedBrokers = useAppSelector(selectBrokersLoaded)

  const displayListTop = useMemo(
    () => brokers?.map((b, i) => <MarketsRowMemo b={b} key={i} />),
    [brokers]
  )

  const MARKET_COLUMNS = [
    'Market',
    'Utilization',
    'Net earn APR',
    'Net borrow APR',
    'Total available',
    'Total borrowing',
    'Total collateral'
  ]

  const marketColumns = () => {
    return MARKET_COLUMNS.map((c, i) => (
      <div className="market-column" key={i}>
        <p className="heading">{c}</p>
      </div>
    ))
  }

  if (!isLoadedBrokers) {
    return (
      <div className="market-list">
        <div className="headings">{marketColumns()}</div>
        <div className="rows">
          <hr className="hr" />
          <FlashRow />
          <FlashRow />
          <FlashRow />
        </div>
      </div>
    )
  }

  return (
    <div className="market-list desk-only-column">
      <div className="market-columns">{marketColumns()}</div>
      <div className="market-rows">{displayListTop}</div>
    </div>
  )
}

export default MarketsList
