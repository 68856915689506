import close from 'assets/icons/close-white.svg'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'state/hooks'
import { useLocation } from 'react-router-dom'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import {
  closeReferralBanner,
  openDoReferModal,
  selectReferralBannerOpen
} from 'state/slices/app/points'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

function ReferralBanner() {
  const open = useAppSelector(selectReferralBannerOpen)
  const [referralCode, setReferralCode] = useState<string | null>(null)
  const query = useQuery()
  const { connected } = useWallet()

  useEffect(() => {
    const code = query.get('refCode')
    if (code) {
      setReferralCode(code)
    }
  }, [query])

  const buttonText = connected ? 'Submit the code >' : 'Connect wallet and submit the code >'

  return (
    open && (
      <div className="banner">
        <div className="left"></div>
        <div className="middle">
          <p>Get 2x more points for the first 10 days by using referral code {referralCode}.</p>
          <button className="sp-btn primary" onClick={openDoReferModal}>
            {buttonText}
          </button>
        </div>
        <div className="right" onClick={closeReferralBanner}>
          <img src={close} alt="close" />
        </div>
      </div>
    )
  )
}

export default ReferralBanner
