export interface FlashRowProps {
  metaOnly?: boolean
}

export default function FlashRow({ metaOnly }: FlashRowProps) {
  if (metaOnly) {
    return (
      <div className="collateral-row">
        <div className="columns inner flasher">
          <div className="column  is-4">
            <div className="dummy-meta">
              <div className="stacked">
                <div className="circ1" />
                <div className=" stacker" />
              </div>
            </div>
            <div className={`dummy-info-point`}>
              <div className={`dark`} />
              <div className={`light `}>
                <div className="primary-sub" />
                <div className="marker" />
                <div className="secondary-sub" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="collateral-row">
      <div className="columns inner flasher">
        <div className="column  is-4">
          <div className="dummy-meta">
            <div className="stacked">
              <div className="circ1" />
              <div className=" stacker" />
            </div>
          </div>
          <div className={`dummy-info-point`}>
            <div className={`dark`} />
            <div className={`light `}>
              <div className="primary-sub" />
              <div className="marker" />
              <div className="secondary-sub" />
            </div>
          </div>
        </div>
        <div className="column is-2">
          <div className="dummy-basic" />
        </div>
        <div className="column is-2">
          <div className="dummy-basic" />
        </div>
        <div className="column is-2">
          <div className="dummy-double">
            <div className="topper" />
            <div className="bottomer" />
          </div>
        </div>
        <div className="column is-2">
          <div className="dummy-basic" />
        </div>
      </div>
      <div className=" dummy-action-circles">
        <div className="c" />
        <div className="c" />
      </div>
    </div>
  )
}
