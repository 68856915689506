import { createAsyncThunk } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from 'state/context'
import { types } from '@concordia/super-sdk'

export type FetchReferralPayload = {
  address: string
}

export const fetchReferralsByUser = createAsyncThunk(
  'referralsByUser/fetchAll',
  async (payload: FetchReferralPayload): Promise<types.SReferralsByUser> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const refs = await sdk.fetcher.fetchReferralsByUser(payload.address)
    console.log('User Referrals:', refs)
    return refs
  }
)
