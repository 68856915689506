export interface TabProps {
  label: string
  active: boolean
  onClick: () => void
}

function Tab({ label, active, onClick }: TabProps) {
  const activeClass = active ? 'active' : ''
  return (
    <>
      <button onClick={onClick} className={`regular ${activeClass}`}>
        {label}
      </button>
    </>
  )
}

export default Tab
