import { useAppSelector } from 'state/hooks'
import { MultiCard } from '../components/multiply/MultiCard'
import { selectLoadedStrategies, selectStrategiesLoaded } from 'state/slices/app/strategies'
import { fetchStrategiesData, fetchUserVault } from 'state/fetch'
import { useEffect } from 'react'
import { isEmptyOrNil } from 'toolbox/account'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { SimpleSpinner } from 'components/charts/SimpleSpinner'
import thalaName from 'assets/logos/thala-name.svg'

export function Multiply() {
  const strategies = useAppSelector(selectLoadedStrategies)
  const strategiesLoaded = useAppSelector(selectStrategiesLoaded)
  const { account } = useWallet()

  useEffect(() => {
    fetchStrategiesData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!isEmptyOrNil(strategies) && account?.address) {
        const strat = strategies[0]
        const { base_token, multiply_token } = strat
        fetchUserVault(account?.address as string, multiply_token, base_token)
      }
    }

    fetchData()
  }, [account?.address, strategies])

  const stratDisplay = strategies.map((strat, i) => {
    return (
      <MultiCard
        borrowTokenName={strat.base_token}
        lendTokenName={strat.multiply_token}
        availableLiquidity={strat.available_liquidity}
        key={i}
        strategy={strat}
        marketName={'Thala'}
        marketLogo={thalaName}
      />
    )
  })
  return (
    <div className="multiply">
      <h3 className="title">20x your yield by using 1-click strategies with Multiply</h3>
      <hr className="hr" />
      <h4 className="subtitle">All strategies</h4>
      <div className="multi-card-list">
        {!strategiesLoaded ? (
          <div className="chart-container">
            <SimpleSpinner />
          </div>
        ) : (
          stratDisplay
        )}
      </div>
    </div>
  )
}
