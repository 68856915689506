import DataPoint, { DataPointProps } from './DataPoint'
import { ReactNode, useMemo, memo } from 'react'

export interface InfoBarProps {
  dataSet: DataPointProps[]
  secondarySet?: DataPointProps[]
  variant?: string
  children?: ReactNode
}

const DataPointMemo = memo(DataPoint)

function InfoBar({ dataSet, secondarySet, variant, children }: InfoBarProps) {
  const primaryDataDisplay = useMemo(
    () => dataSet.map((dp, i) => <DataPointMemo key={i} {...dp} />),
    [dataSet]
  )

  const secondaryDataDisplay = useMemo(
    () => secondarySet?.map((dp, i) => <DataPointMemo key={i} {...dp} />),
    [secondarySet]
  )

  return (
    <div className="info-wrapper">
      <div className={`sp-info-bar ${variant && variant}`}>
        <div className="data-points pri">{primaryDataDisplay}</div>
        {secondarySet && <div className="data-points nowrap">{secondaryDataDisplay}</div>}
      </div>
      {children && children}
    </div>
  )
}

export default InfoBar
