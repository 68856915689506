import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { formatPublicKeyDisplay } from 'toolbox/format'
import chev from 'assets/icons/blue-chev.svg'
import { useAppSelector } from 'state/hooks'
import { selectIsBlocked, showBlock } from 'state/slices/ui/dash'
export interface SignInProps {
  open: () => void
  connected: boolean
  disco: () => void
}

function SignIn({ open, connected, disco }: SignInProps) {
  const { account } = useWallet()
  const address = account?.address
  const formatted = formatPublicKeyDisplay(address)
  const isBlocked = useAppSelector(selectIsBlocked)

  return connected ? (
    <button className="sp-btn secondary blue flexed desk-only" onClick={disco}>
      <p>{formatted}</p>
      <img src={chev} alt="disconnect" />
    </button>
  ) : (
    <button className="sp-btn sign-in desk-only" onClick={isBlocked ? showBlock : open}>
      Connect wallet
    </button>
  )
}

export default SignIn
