import tooltip from 'assets/icons/tooltip-white.svg'
import tip from 'assets/icons/tooltip-grey.svg'
import { ReactNode } from 'react'

export interface TooltipProps {
  label: string
  value: string
  normal?: boolean
  shiftLeft?: boolean
  children?: ReactNode
  small?: boolean
}

export function Tooltip({ label, value, normal, shiftLeft, children, small }: TooltipProps) {
  const noLabel = label === ''
  const shiftLeftClass = shiftLeft ? 'left' : ''
  const smallClass = small ? 'small' : ''
  return children ? (
    <div className="tip-box">
      {children}
      <div className={`tip-value ${shiftLeftClass}`}>
        <p>{value}</p>
      </div>
    </div>
  ) : (
    <div className="tip-box">
      {noLabel ? (
        <img src={normal ? tip : tooltip} alt="tooltip" className={`tip-image ${smallClass}`} />
      ) : (
        <p className={`tip-label`}>{label}</p>
      )}
      <div className={`tip-value ${shiftLeftClass}`}>
        <p>{value}</p>
      </div>
    </div>
  )
}
