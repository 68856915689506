import leftBack from 'assets/icons/left-chev-thick.svg'
import { useAppSelector } from 'state/hooks'
import { getTokenMeta, selectTokens } from 'state/slices/app/meta'
import DataPoint from '../common/DataPoint'
import { closeDetails, selectActiveBroker } from 'state/slices/ui/form'
import { formatPercentage, formatCurrency, prettyTokenBal } from 'toolbox/format'
import { getPriceFromName, selectPricesFromBrokers } from 'state/slices/app/brokers'

export function TopDetails({ isStickyOn }: { isStickyOn: boolean }) {
  const tokens = useAppSelector(selectTokens)
  const selectedBroker = useAppSelector(selectActiveBroker)
  const available = selectedBroker?.scaledAvailableLiquidityUnderlying
  const t = getTokenMeta(selectedBroker?.underlyingAsset.name, tokens)
  const prices = useAppSelector(selectPricesFromBrokers)
  const price = getPriceFromName(selectedBroker?.underlyingAsset.name, prices)
  const stickyClass = isStickyOn ? 'sticky' : ''
  const totalSupplied =
    (selectedBroker?.scaledAvailableLiquidityUnderlying +
      selectedBroker?.scaledTotalBorrowedUnderlying) *
      price || 0
  const totalBorrowed = selectedBroker?.scaledTotalBorrowedUnderlying * price || 0
  const utilRate = selectedBroker?.utilization
  const poolMaxLimit = Number(selectedBroker?.maxDepositScaled)

  return (
    <div className={`top-details ${stickyClass}`}>
      <div className="flexer-max">
        <div className="back-split">
          <img src={leftBack} alt="back" className="back-chev" onClick={closeDetails} />

          <div className="basic-meta">
            <img src={t.logo} alt={t.name} />
            <div className="names">
              <p className="name">{t.ticker}</p>
              <p className="full-name">{t.displayName}</p>
            </div>
          </div>
        </div>
        <div className="data-split">
          <DataPoint label="Total supplied" value={formatCurrency(totalSupplied)} />
          <DataPoint label="Total borrowed" value={formatCurrency(totalBorrowed)} />
          <DataPoint label="Available liquidity" value={formatCurrency(available)} />
          <DataPoint label="Utilization rate" value={formatPercentage(utilRate)} />
          <DataPoint
            label="Pool max limit"
            value={`${prettyTokenBal(poolMaxLimit)} ${selectedBroker?.tokenMeta?.ticker}`}
          />
        </div>
      </div>
    </div>
  )
}
