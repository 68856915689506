import { useAppSelector } from 'state/hooks'
import {
  selectMultiReviewOpen,
  closeMultiReview,
  closeMultiplyForm,
  openMultiReview,
  setFormInput,
  selectMultiplier,
  setMultiplierVal,
  selectMultiFormInputVal,
  multiFormAction,
  closePositionAction,
  DEPOSIT_TAB,
  selectCloseSlippage
} from 'state/slices/ui/form'
import ParamList from '../txForm/ParamList'
import CloseButton from 'components/common/CloseButton'
import { MetaBroker } from 'state/mock'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { selectTxStatus } from 'state/slices/ui/transaction'
import { MultiInputContainer } from './MultiInputContainer'
import {
  detrmineHealthColor,
  formatCurrency,
  formatHealthFactor,
  formatPercentage,
  prettyTokenBal,
  prettyTokenBalWithK,
  scaleDown,
  scaleUp
} from 'toolbox/format'
import {
  getWalletBalanceByName,
  selectLoadedWalletBalances
} from 'state/slices/user/walletBalances'
import { MultiFormReview, MultiFormReviewProps } from './MultiReview'
import Slider from './MultiSlider'
import { calculateNetAPR, estimateNewAvailable } from 'toolbox/calc'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'
import {
  getPriceFromName,
  selectPricesFromBrokers,
  selectBrokersWithMeta
} from 'state/slices/app/brokers'
import { MultiTxPayload } from 'state/thunks/doMultiTx'
import { ClosePositionTxPayload } from 'state/thunks/doClosePosition'
import { SFlashLoanStrategy, SUserStrategy } from '@concordia/super-sdk/src/io'
import { ClosePosition } from './ClosePosition'
import { PortfolioState } from '@concordia/super-json-api-client'
import { useEffect, useState } from 'react'
import { SDK_CONTEXT } from 'state/context'
import { isRedZone, isYellowZone } from 'state/slices/user/portfolio'
import { selectCloseWithSlippage } from 'state/slices/user/multiplyPortfolio'

export interface MultiFormProps {
  borrowBroker: MetaBroker
  lendBroker: MetaBroker
  isLoadedUser: boolean
  strategy: SFlashLoanStrategy
  userVault: SUserStrategy
  minLiabilityCoinAmountOut: number
}

function Multiform({
  borrowBroker,
  lendBroker,
  isLoadedUser,
  strategy,
  userVault,
  minLiabilityCoinAmountOut
}: MultiFormProps) {
  //state and selectors
  const brokers = useAppSelector(selectBrokersWithMeta)
  const inputVal = useAppSelector(selectMultiFormInputVal)
  const isTxPending = useAppSelector(selectTxStatus) === 'pending'
  const leverage = useAppSelector(selectMultiplier)
  const { account, signAndSubmitTransaction } = useWallet()
  const slippageValue = useAppSelector(selectCloseSlippage)
  //input container props
  const parsedInput = parseFloat(inputVal) || 0
  const prices = useAppSelector(selectPricesFromBrokers)
  const price = getPriceFromName(borrowBroker?.underlyingAsset.name, prices)
  const inputDollars = parsedInput > 0 ? parsedInput * price : 0
  const token = borrowBroker?.tokenMeta
  const inputProps = {
    token,
    inputVal,
    inputDollars,
    setMax,
    setInput: setFormInput
  }

  const hasInput = parsedInput > 0

  const [simHealthFactor, setSimHealthFactor] = useState<number>(0)
  //use to show warning colors in stateful update to avoid flickering
  const [simHealthYellow, setSimHealthYellow] = useState<boolean>(false)
  const [simHealthRed, setSimHealthRed] = useState<boolean>(false)
  //use to differntiate between warning red and unhealthy red messaging
  const [isSimHealthy, setIsSimHealthy] = useState<boolean>(false)
  const [isSimulatedLoading, setIsSimulatedLoading] = useState<boolean>(false)
  const lendTicker = lendBroker?.tokenMeta.ticker
  const borrowTicker = borrowBroker?.tokenMeta.ticker

  useEffect(() => {
    const fetchSimHealthData = async () => {
      if (isLoadedUser && hasInput) {
        const collateralId = lendBroker?.depositNote.networkAddress
        const rootAddr = process.env.REACT_APP_APTOS_ROOT_ADDRESS
        const collateralDecimals = lendBroker?.depositNote.decimals
        const liabilityId = borrowBroker?.loanNote.networkAddress
        const liabilityDecimals = borrowBroker?.loanNote.decimals
        const scaledLendAmount = scaleUp(totalLendExposure, collateralDecimals)
        const scaledDebt = scaleUp(totalDebt, liabilityDecimals)
        setIsSimulatedLoading(true)

        const simPortfolio: PortfolioState = {
          collaterals: [
            {
              instrumentId: `${rootAddr}::broker::${collateralId}`,
              amount: scaledLendAmount.toString()
            }
          ],
          liabilities: [
            {
              instrumentId: `${rootAddr}::broker::${liabilityId}`,
              amount: scaledDebt.toString()
            }
          ]
        }

        try {
          const response = await SDK_CONTEXT.superSdk.fetcher.fetchSimulatedPortfolio(simPortfolio)
          console.log('Multiply sim response', response)
          const simFactor = response.health_ratio
          const simYellow = isYellowZone(simFactor)
          const simRed = isRedZone(simFactor)
          const healthy = simFactor > 1.1
          setSimHealthFactor(simFactor)
          setSimHealthYellow(simYellow)
          setSimHealthRed(simRed)
          setIsSimHealthy(healthy)
        } catch (e) {
          console.error('SIM ERROR !!!', e)
          setSimHealthFactor(0)
          setIsSimHealthy(false)
          setSimHealthRed(true)
          setIsSimulatedLoading(false)
        } finally {
          setIsSimulatedLoading(false)
        }
      }
    }
    fetchSimHealthData()
  }, [isLoadedUser, hasInput, parsedInput, leverage])

  const resetSimValues = () => {
    setSimHealthYellow(false)
    setSimHealthRed(false)
    setIsSimHealthy(false)
  }

  useEffect(() => {
    if (!hasInput) {
      resetSimValues()
    }
  }, [hasInput])
  //params
  const healthFactor = simHealthFactor
  const simHealthDisplay = formatHealthFactor(simHealthFactor)
  const currentHealthYellow = isYellowZone(healthFactor)
  const currentHealthRed = isRedZone(healthFactor)
  const currentHealthGreen = !currentHealthRed && !currentHealthYellow

  const healthFactorParam = {
    label: 'Health factor',
    value: isSimulatedLoading ? '--' : simHealthDisplay,
    tooltip: 'Health simulation of next position required to be 1.2x or higher for user safety',
    currentColor:
      simHealthDisplay === 'N/A' || simHealthDisplay === '0' || simHealthDisplay === '--'
        ? ''
        : detrmineHealthColor(currentHealthGreen, currentHealthRed, currentHealthYellow)
  }
  const healthFactorUVParam = {
    label: 'Health factor',
    value: userVault?.health_ratio?.toFixed(2) + 'x',
    tooltip: 'Health simulation of next position required to be 1.2x or higher for user safety'
  }

  const exchangeRate = strategy?.multiply_exchange_rate

  const principalAmount = parsedInput

  const totalDebt = principalAmount * (leverage - 1)
  const totalDebtParam = {
    label: `Total ${borrowTicker} debt`,
    value: prettyTokenBal(totalDebt),
    tooltip: `Total debt of the position in ${borrowTicker} || principal * (leverage - 1)`
  }

  let flashLoanAmount: number

  // 0.3% or 30 bps is the fee rate for flash loans
  const flashLoanFeeRate = 0.003

  // The total amount borrowed is the flash loan amount plus fees
  // totalDebt = flashLoanAmount * (1 + flashLoanFeeRate)
  // flashLoanAmount = totalDebt / (1 + flashLoanFeeRate)
  flashLoanAmount = totalDebt / (1 + flashLoanFeeRate)

  // The user input is denominated in borrow token
  // The exchange rate is the amount of borrow token per lend token
  const totalCollateral = principalAmount + flashLoanAmount
  // This is how much lend token  we'll have after converting
  // the leveraged borrow token to lend token
  const totalLendExposure = totalCollateral / exchangeRate
  //const totalLendExposure = (parsedInput * leverage) / exchangeRate
  const totalLendExposureParam = {
    label: `Total ${lendTicker} exposure`,
    value: prettyTokenBal(totalLendExposure),
    tooltip: `Total exposure of the position in ${lendTicker} || principal * leverage * exchange rate`
  }
  const collateralValueUV = userVault?.vault_balance.collaterals[0]?.amount || 0
  const scaledCollateralValueUV = scaleDown(
    Number(collateralValueUV),
    lendBroker?.depositNote.decimals
  )
  const totalLendExposureUVParam = {
    label: `Total ${lendTicker} exposure`,
    value: prettyTokenBal(scaledCollateralValueUV),
    tooltip: `Total exposure of the position in ${lendTicker} || principal * leverage * exchange rate`
  }

  const totalExposure = inputDollars * leverage
  const totalExposureParam = {
    label: 'Total exposure',
    value: formatCurrency(totalExposure),
    tooltip: 'Total exposure of the position in USD || principal * leverage * price'
  }
  let totalExposureUVParam

  const uvprice = getPriceFromName(borrowBroker?.underlyingAsset.name, prices)
  totalExposureUVParam = {
    label: 'Total exposure',
    value: formatCurrency(scaledCollateralValueUV * uvprice),
    tooltip: 'Total exposure of the position in USD || principal * leverage * price'
  }

  const borrowValueUV = userVault?.vault_balance.liabilities[0]?.amount || 0
  const scaledBorrowValueUV = scaleDown(Number(borrowValueUV), borrowBroker?.loanNote.decimals)
  const totalDebtUVParam = {
    label: `Total ${borrowTicker} debt`,
    value: prettyTokenBal(scaledBorrowValueUV),
    tooltip: `Total debt of the position in ${borrowTicker} || principal * (leverage - 1)`
  }

  const stakingAPR = strategy?.multiply_apr
  const supplyAPR = strategy?.lend_apr

  //more params
  const borrowAPR = strategy?.borrow_apr
  const maxLTV = strategy?.max_ltv
  const availableLiquidity = borrowBroker?.scaledAvailableLiquidityUnderlying
  const spFee = strategy?.multiply_fee
  const maxLeverage = strategy?.max_leverage

  const netAPR = calculateNetAPR(strategy, leverage)
  const netAPRParam = {
    label: 'Net APR',
    value: formatPercentage(netAPR),
    tooltip:
      'Total Net APR of the position || (earning APRs * leverage) - (paying APRs * (leverage - 1))'
  }

  const multiParams = [
    healthFactorParam,
    totalExposureParam,
    totalLendExposureParam,
    totalDebtParam,
    netAPRParam
  ]

  const slippageParam = {
    label: 'Slippage',
    value: slippageValue + '%',
    slippage: true,
    tooltip:
      'Closing the multiply position is subject to a higher (x) slippage based on your leverage multiplier'
  }

  const estimatedMimimum = useAppSelector(selectCloseWithSlippage)
  const estimatedMinimumParam = {
    label: 'Estimated minimum',
    value: prettyTokenBal(estimatedMimimum),
    tooltip: 'Estimated minimum amount to receive after slippage and DEX trades to close position'
  }

  const UVParams = [
    healthFactorUVParam,
    totalExposureUVParam,
    totalLendExposureUVParam,
    totalDebtUVParam,
    estimatedMinimumParam,
    slippageParam
  ]

  const stakignAPRParam = {
    label: `${lendTicker} Staking APR`,
    value: formatPercentage(stakingAPR)
  }

  const lendAPRParam = {
    label: `${lendTicker} Supply APR`,
    value: formatPercentage(supplyAPR)
  }

  const borrowAPRParam = {
    label: `${borrowTicker} Borrow APR`,
    value: formatPercentage(borrowAPR)
  }

  const maxLTVParam = {
    label: 'Max LTV',
    value: formatPercentage(maxLTV)
  }

  const availableLiquidityParam = {
    label: 'Available liquidity',
    value: prettyTokenBalWithK(availableLiquidity)
  }

  const spFeeParam = {
    label: 'Flashloan fee',
    value: formatPercentage(spFee)
  }

  const moreParams = [
    stakignAPRParam,
    lendAPRParam,
    borrowAPRParam,
    maxLTVParam,
    availableLiquidityParam,
    spFeeParam
  ]

  //below params
  const balances = useAppSelector(selectLoadedWalletBalances)
  const balance = getWalletBalanceByName(balances, token?.name)
  const walletBalanceParam = {
    label: 'Wallet balance',
    value: `${prettyTokenBal(balance)} ${token?.ticker}`
  }
  const belowParams = [walletBalanceParam]

  //tx review
  const multiReviewOpen = useAppSelector(selectMultiReviewOpen)

  const hasVaultBalances = Number(userVault?.vault_balance.liabilities[0]?.amount) > 0

  if (hasVaultBalances) {
    const closePositionPayload: ClosePositionTxPayload = {
      network: 'Aptos',
      brokers,
      redeemBroker: lendBroker,
      repayBroker: borrowBroker,
      user: account?.address as string,
      signAndSub: signAndSubmitTransaction,
      minLiabilityCoinAmountOut
    }
    return (
      <ClosePosition
        multiParams={UVParams}
        action={() => closePositionAction(closePositionPayload)}
        disabled={false}
        userVault={userVault}
      />
    )
  }

  const multiPayload: MultiTxPayload = {
    network: 'Aptos',
    lendBroker,
    lendAmount: totalLendExposure,
    borrowBroker,
    principalAmount: parsedInput,
    borrowAmount: totalDebt,
    flashLoanAmount,
    user: account?.address as string,
    borrowCoinsPerLendCoin: exchangeRate,
    signAndSub: signAndSubmitTransaction
  }

  //form actions and button functions
  const action = () => {
    if (multiReviewOpen) {
      multiFormAction(multiPayload)
    } else {
      openMultiReview()
    }
  }

  //broker maximums
  const maxSupplyBroker = Number(lendBroker?.maxDepositScaled)
  const SHAVE_FACTOR = 0.999

  //max limits
  const totalAvailableLendBroker = lendBroker?.scaledAvailableLiquidityUnderlying
  const totalBorrowedLendBroker = lendBroker?.scaledTotalBorrowedUnderlying

  const nextAvailLendBroker = estimateNewAvailable(
    lendBroker,
    totalLendExposure.toString(),
    DEPOSIT_TAB
  )

  const nextTotalSupplied = nextAvailLendBroker + totalBorrowedLendBroker

  const lendBrokerTotal = totalBorrowedLendBroker + totalAvailableLendBroker

  const overBrokerDepositLimit = nextTotalSupplied > maxSupplyBroker
  const depositDiffToBrokerLimit = maxSupplyBroker - lendBrokerTotal
  const depositDiffToBrokerLimitNegative = depositDiffToBrokerLimit <= 0
  const poolIsFull = depositDiffToBrokerLimitNegative
  const walletBalanceOrDepositDiffLess =
    balance < depositDiffToBrokerLimit ? balance : depositDiffToBrokerLimit
  const walletBalanceOrDepositDiffLessShaved =
    balance < depositDiffToBrokerLimit
      ? parseFloat((balance * SHAVE_FACTOR).toFixed(4))
      : depositDiffToBrokerLimit * SHAVE_FACTOR

  const maxTxValueUnderlying = function setMaxValue() {
    return walletBalanceOrDepositDiffLess > 0 ? walletBalanceOrDepositDiffLess : 0
  }

  //max value of token to transact logic
  const maxTxValueUnderlyingShaved = function setMaxValue() {
    return walletBalanceOrDepositDiffLessShaved > 0 ? walletBalanceOrDepositDiffLessShaved : 0
  }

  function setMax() {
    setFormInput(maxTxValueUnderlyingShaved().toString())
  }

  const over = parsedInput > maxTxValueUnderlying()

  const overLimit = () => {
    return over || poolIsFull || overBrokerDepositLimit || !isSimHealthy
  }

  const MIN_INPUT = 0.01
  const isNotMinInput = parsedInput < MIN_INPUT

  function overLimitMessage() {
    return isNotMinInput
      ? `Minimum 0.01 ${borrowTicker}`
      : !isSimHealthy
      ? 'User would become unhealthy'
      : poolIsFull
      ? 'Pool is full'
      : overBrokerDepositLimit
      ? `Exceeds ${token.ticker} deposit limit`
      : 'Exceeds wallet balance'
  }

  function overLimitInfoBox() {
    const ticker = lendBroker?.tokenMeta.ticker || '---'
    const borrowTicker = borrowBroker?.tokenMeta.ticker || '---'
    if (poolIsFull)
      return `${ticker} pool is full. Pool limits are set by the broker and can be adjusted. They are in place to protect the health of the pool and the safety of the users.`
    if (overBrokerDepositLimit)
      return `Amount exceeds max deposit value set for ${ticker} by broker`
    return `Amount exceeds wallet balance of ${borrowTicker}`
  }

  const infoBoxText = overLimit() ? overLimitInfoBox() : null

  const buttonText = isSimulatedLoading
    ? 'Simulating health factor'
    : overLimit()
    ? overLimitMessage()
    : leverage === 1
    ? 'Input leverage (x) with slider'
    : multiReviewOpen
    ? `Multiply ${token?.ticker}`
    : 'Review'

  const zeroInput = parsedInput <= 0

  const disabledForm =
    !isLoadedUser ||
    overLimit() ||
    zeroInput ||
    leverage === 1 ||
    isNotMinInput ||
    isSimulatedLoading
  const disabledReview = disabledForm || isTxPending

  const actionButtonProps = {
    className: 'sp-btn primary large full-w send-btn',
    onClick: action,
    disabled: disabledForm
  }

  const multiReviewProps: MultiFormReviewProps = {
    closeForm: closeMultiplyForm,
    inputVal,
    token,
    multiParams,
    closeMultiReview,
    action,
    disabled: disabledReview
  }

  const blueInfoBoxProps: InfoBoxProps = {
    color: 'blue',
    showBox: over && infoBoxText ? true : false,
    text: infoBoxText,
    fullWidth: true
  }

  if (multiReviewOpen) {
    return <MultiFormReview {...multiReviewProps} />
  }

  return (
    <div className={`tx-form `}>
      <CloseButton cb={closeMultiplyForm} />
      <p className="m-title">Multiply</p>
      <MultiInputContainer {...inputProps} />
      <InfoBox {...blueInfoBoxProps} />
      <Slider min={1} max={maxLeverage} step={1} value={leverage} onChange={setMultiplierVal} />
      <ParamList params={multiParams} more={moreParams} />
      <button {...actionButtonProps}>{buttonText}</button>
      <ParamList params={belowParams} />
    </div>
  )
}

export default Multiform
