import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'

export interface FetchWalletBalancesPayload {
  address: string
}

export const fetchWalletBalances = createAsyncThunk(
  'walletBalances/fetchAll',
  async (payload: FetchWalletBalancesPayload): Promise<types.SWalletBalance[]> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const balances = await sdk.fetcher.fetchWalletBalances(payload.address)
    console.log('Balances: ', balances)
    return balances.balances
  }
)
