import { memo, useMemo } from 'react'
import { useAppSelector } from 'state/hooks'
import { selectBrokersLoaded, selectBrokersWithMeta } from 'state/slices/app/brokers'
import { MobileMarketCard } from './MobileMarketCard'

const MarketsRowMemo = memo(MobileMarketCard)

export function MarketsListMobile() {
  const brokers = useAppSelector(selectBrokersWithMeta)

  const displayList = useMemo(
    () => brokers?.map((b, i) => <MarketsRowMemo broker={b} key={i} />),
    [brokers]
  )
  return <div className="markets-list-mobile mobile-only">{displayList}</div>
}
