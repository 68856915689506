import { createSlice } from '@reduxjs/toolkit'
import { resetUserData } from 'state/fetch'
import { RootState, dispatch } from 'state/store'

export interface WidgetState {
  disconnectOpen: boolean
  selectOpen: boolean
  widgetOpen: boolean
  stickyOn: boolean
}

export const initialState: WidgetState = {
  disconnectOpen: false,
  selectOpen: false,
  widgetOpen: false,
  stickyOn: false
}

const slice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    setSelectOpen: (s) => {
      s.selectOpen = true
    },
    setSelectClosed: (s) => {
      s.selectOpen = false
    },
    setWidgetOpen: (s) => {
      s.widgetOpen = true
    },
    setWidgetClosed: (s) => {
      s.widgetOpen = false
      s.selectOpen = false
    },
    setDisconnectOpen: (s) => {
      s.disconnectOpen = true
    },
    setDisconnectClosed: (s) => {
      s.disconnectOpen = false
    },
    setStickyOn: (s) => {
      s.stickyOn = true
    },
    setStickyOff: (s) => {
      s.stickyOn = false
    }
  }
})

export default slice.reducer

//selectors
export const selectDisconnectOpen = (s: RootState) => s.ui.widget.disconnectOpen
export const selectOpen = (s: RootState) => s.ui.widget.selectOpen
export const selectWidgetOpen = (s: RootState) => s.ui.widget.widgetOpen
export const selectStickyOn = (s: RootState) => s.ui.widget.stickyOn

//actions
const {
  setDisconnectOpen,
  setDisconnectClosed,
  setSelectOpen,
  setSelectClosed,
  setWidgetOpen,
  setWidgetClosed,
  setStickyOn,
  setStickyOff
} = slice.actions

//dispatches

export const openSelect = () => {
  dispatch(setSelectOpen())
}

export const closeSelect = () => {
  dispatch(setSelectClosed())
}

export const openWidget = () => {
  dispatch(setWidgetOpen())
}

export const closeWidget = () => {
  dispatch(setWidgetClosed())
}

export const openDisconnect = () => {
  dispatch(setDisconnectOpen())
}

export const closeDisconnect = () => {
  dispatch(setDisconnectClosed())
}

export const setSticky = () => {
  dispatch(setStickyOn())
}

export const unsetSticky = () => {
  dispatch(setStickyOff())
}

//group actions
export const disconnectWallet = () => {
  closeDisconnect()
  resetUserData()
}

export const openWidgetSignIn = () => {
  openWidget()
  openSelect()
}
