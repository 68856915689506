import { combineReducers } from 'redux'
import widget from 'state/slices/ui/widget'
import transaction from 'state/slices/ui/transaction'
import form from 'state/slices/ui/form'
import dash from 'state/slices/ui/dash'

export const ui = combineReducers({
  widget,
  transaction,
  form,
  dash
})
