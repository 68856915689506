import { useAppSelector } from 'state/hooks'
import { selectLoadedToggles, updateToggleState } from 'state/slices/app/toggles'
import Cookies from 'js-cookie'
import { dispatch } from 'state/store'

export function Toggles() {
  const toggles = useAppSelector(selectLoadedToggles)

  const handleToggleChange = (name: string, currentValue: boolean) => {
    Cookies.set(name, String(!currentValue))
    dispatch(updateToggleState({ name, enabled: !currentValue }))
  }
  return (
    <div className="toggles">
      {' '}
      {Object.keys(toggles).map((key) => {
        const toggle = toggles[key]
        return (
          <div key={toggle.name}>
            <label>
              <input
                type="checkbox"
                checked={toggle.enabled}
                onChange={() => handleToggleChange(toggle.name, toggle.enabled)}
              />
              <strong>{toggle.name}</strong>: {toggle.description}
            </label>
          </div>
        )
      })}
    </div>
  )
}
