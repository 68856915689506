import { PointsRow, PointsRowProps } from './PointsRow'

export interface PointsListProps {
  rows: PointsRowProps[]
  isDaily: boolean
}

export function PointsList({ rows, isDaily }: PointsListProps) {
  return (
    <div className="points-list">
      <div className="points-row column-labels">
        <p>Rank</p>
        <p>Address</p>
        <p>{isDaily ? 'Daily activity points' : 'Superposition Activity'}</p>
        {isDaily ? <p>Tier</p> : <p>Referral Activity</p>}
        <p>Total Points</p>
      </div>
      {rows.map((row, i) => (
        <PointsRow key={i} {...row} isDaily={isDaily} />
      ))}
    </div>
  )
}
