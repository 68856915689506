import cplus from 'assets/icons/c-plus.svg'
import cminus from 'assets/icons/c-minus.svg'
import greyplus from 'assets/icons/grey-plus.svg'
import greyminus from 'assets/icons/grey-minus.svg'
import cplusBlack from 'assets/icons/c-plus-black.svg'
import cminusBlack from 'assets/icons/c-minus-black.svg'

import { useEffect, useRef, useState } from 'react'

export interface ActionCircleProps {
  cb: () => void
  isAdd?: boolean
  disabled?: boolean
  isSupply?: boolean
}

function ActionCircle({ cb, isAdd, disabled, isSupply }: ActionCircleProps) {
  const [isHovered, setIsHovered] = useState(false)
  const [isHoveredDelayed, setIsHoveredDelayed] = useState(false)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = () => {
    setIsHovered(true)
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setIsHoveredDelayed(true)
    }, 1000)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
    setIsHoveredDelayed(false)
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const buttonImg = isAdd
    ? disabled
      ? greyplus
      : isHovered
      ? cplusBlack
      : cplus
    : disabled
    ? greyminus
    : isHovered
    ? cminusBlack
    : cminus

  const showHoverClass = isHovered && isHoveredDelayed ? 'show-hover' : ''
  const hoverText = isSupply ? (isAdd ? 'Supply' : 'Withdraw') : isAdd ? 'Borrow' : 'Repay'

  return (
    <>
      <button
        ref={buttonRef}
        onClick={cb}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`sp-btn no-style`}
        disabled={disabled}>
        <img src={buttonImg} alt={isAdd ? '+' : '-'} />
        <div className={`${showHoverClass} btn-tip top-down`}>{hoverText}</div>
      </button>
    </>
  )
}

export default ActionCircle
