import { dispatch } from 'state/store'
import { fetchPortfolios } from './thunks/fetchPortfolios'
import { fetchMeta } from './thunks/fetchMeta'
import { resetPortfolio } from './slices/user/portfolio'
import { fetchBrokers } from './thunks/fetchBrokers'
import { fetchWalletBalances } from './thunks/fetchWalletBalances'
import { resetWalletBalances } from './slices/user/walletBalances'
import { fetchPriceHistories } from './thunks/fetchPriceHistories'
import { fetchBrokerHistories, kickRefreshBrokerHistories } from './thunks/fetchBrokerHistories'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchPointsTotals } from './thunks/fetchPointsTotals'
import { fetchReferralCode } from './thunks/fetchReferralCode'
import { fetchReferralsByUser } from './thunks/fetchReferralsByUser'
import { resetReferralCode } from './slices/user/referral'
import { resetReferralsByUser } from './slices/user/referralsByUser'
import { fetchStrategies } from './thunks/fetchStrategies'
import { fetchMultiplyPortfolio } from './thunks/fetchMultiplyPortfolio'
import { resetMultiplyPortfolio } from './slices/user/multiplyPortfolio'
import { fetchToggles } from './thunks/fetchToggles'

export function fetchAppData() {
  console.log('fetching app data...')
  dispatch(fetchMeta())
  dispatch(fetchBrokers())
  dispatch(fetchToggles())
}

export function fetchStrategiesData() {
  dispatch(fetchStrategies())
}

export function fetchHistories(brokerNames: string[], userAddress: string) {
  dispatch(fetchBrokerHistories({ brokerNames, userAddress }))
}

export function kickAndFetchHistories(brokerNames: string[], userAddress: string) {
  dispatch(kickRefreshBrokerHistories({ brokerNames, userAddress }))
}

export function fetchHistoriesPrice(instrumentNames: string[]) {
  dispatch(fetchPriceHistories({ instrumentNames }))
}

export function fetchPortUser(address: string) {
  dispatch(fetchPortfolios({ address }))
}

export function resetUserData() {
  dispatch(resetPortfolio())
  dispatch(resetWalletBalances())
  dispatch(resetReferralCode())
  dispatch(resetReferralsByUser())
  dispatch(resetMultiplyPortfolio())
}

export function postTransactionRefresh(address: string, brokerNames?: string[]) {
  fetchAppData()
  fetchPortUser(address)
  fetchUserWalletBalances(address)
  if (!isEmptyOrNil(brokerNames)) {
    fetchHistories(brokerNames, address)
  }
}

export function fetchUserWalletBalances(address: string) {
  dispatch(fetchWalletBalances({ address }))
}

export function fetchPoints() {
  dispatch(fetchPointsTotals())
}

export function fetchCode(address: string) {
  dispatch(fetchReferralCode({ address }))
  dispatch(fetchReferralsByUser({ address }))
}

export function fetchUserVault(address: string, lendBrokerName: string, borrowBrokerName: string) {
  dispatch(fetchMultiplyPortfolio({ address, lendBrokerName, borrowBrokerName }))
}
