import { useAppSelector } from 'state/hooks'
import { selectTxSuccess, setTxSuccessState } from 'state/slices/ui/transaction'
import { prettyTokenBal, scaleDown } from 'toolbox/format'
import CloseButton from '../common/CloseButton'
import check from 'assets/graphics/green-check.svg'

export default function TxSuccess() {
  const successState = useAppSelector(selectTxSuccess)

  if (!successState) return null
  const { amount, ticker, txHash, decimals, action } = successState
  const network = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'

  return (
    <div className="transfer-success">
      <CloseButton cb={() => setTxSuccessState(null)} />
      <img src={check} alt="logo" />
      <div className="transfer-text">
        <h3>{`Success! You have ${action}`}</h3>
        {!!amount && (
          <span className="is-flex">
            <h3>{`${prettyTokenBal(scaleDown(parseFloat(amount), decimals))} `}</h3>
            <h3 className="ticker">&nbsp;{`${ticker}`}</h3>
          </span>
        )}
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://explorer.aptoslabs.com/txn/${txHash}?network=${network}`}>
        Explorer ↗
      </a>

      <button onClick={() => setTxSuccessState(null)} className="sp-btn tertiary full-w-global">
        Close
      </button>
    </div>
  )
}
