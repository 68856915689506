import { Provider } from 'react-redux'
import { store } from 'state/store'
import InnerApp from 'layout/InnerApp'
import SuperWalletProvider from 'components/wallet/SuperWalletProvider'
import ErrorBoundary from 'layout/ErrorBoundary'

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <SuperWalletProvider>
          <InnerApp />
        </SuperWalletProvider>
      </ErrorBoundary>
    </Provider>
  )
}

export default App
