import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { createSelector } from 'reselect'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchToggles } from 'state/thunks/fetchToggles'
import { types } from '@concordia/super-sdk'

export const INCENTIVES_TOGGLE = 'incentives'
export const MULTIPLY_TOGGLE = 'multiply'

const initialState = initLoadable<types.SFeatureToggles>({})

export const toggles = createSlice({
  name: 'toggles',
  initialState,
  reducers: {
    updateToggleState: (state, action: PayloadAction<{ name: string; enabled: boolean }>) => {
      const { name, enabled } = action.payload
      if (state.value && state.value[name]) {
        state.value[name].enabled = enabled
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchToggles.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchToggles.pending, (state) => {
      state.status = 'busy'
    })

    builder.addCase(fetchToggles.rejected, (state) => {
      state.status = 'errored'
    })
  }
})

export default toggles.reducer
export const selectToggles = (s: RootState) => s.app.toggles.value
export const selectTogglesBusy = (s: RootState) => s.app.toggles.status === 'busy'
export const selectTogglesLoaded = (s: RootState) => s.app.toggles.loadedOnce
export const selectTogglesErrored = (s: RootState) => s.app.toggles.status === 'errored'
export const { updateToggleState } = toggles.actions

export const selectLoadedToggles = createSelector(
  [selectTogglesLoaded, selectToggles],
  (togglesLoaded, toggles) => {
    if (!togglesLoaded || isEmptyOrNil(toggles) || !toggles) {
      return {}
    }
    return toggles
  }
)

export const selectIncentivesToggleOn = createSelector([selectLoadedToggles], (toggles) => {
  return toggles[INCENTIVES_TOGGLE]?.enabled || false
})

export const selectMultiplyToggleOn = createSelector([selectLoadedToggles], (toggles) => {
  return toggles[MULTIPLY_TOGGLE]?.enabled || false
})
