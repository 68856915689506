import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'
import { isEmptyOrNil } from 'toolbox/account'

export type FetchPortfolioPayload = {
  address: string
  lendBrokerName: string
  borrowBrokerName: string
}

const MAX_ATTEMPTS = 5

export const fetchMultiplyPortfolio = createAsyncThunk(
  'multiplyPortfolio/fetchAll',
  async (payload: FetchPortfolioPayload): Promise<types.SUserStrategy | null> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }

    let attempt = 0
    const baseDelay = 1000

    const { address, lendBrokerName, borrowBrokerName } = payload

    if (!address || !lendBrokerName || !borrowBrokerName) {
      throw new Error('Invalid payload')
    }

    while (attempt < MAX_ATTEMPTS) {
      try {
        const vault = await sdk.fetcher.getUserVault(address, lendBrokerName, borrowBrokerName)
        if (isEmptyOrNil(vault)) {
          throw new Error('Failed to fetch portfolio')
        }
        console.log('Vault: ', vault)
        return vault
      } catch (e: any) {
        attempt++
        console.log(`Attempt ${attempt}: Failed to fetch portfolio, retrying...`)

        if (attempt >= MAX_ATTEMPTS) {
          throw new Error(e.message || 'Failed to fetch portfolio after multiple attempts')
        }

        const delay = baseDelay * Math.pow(2, attempt - 1) // Exponential backoff formula
        console.log(`Waiting ${delay} ms before next attempt...`)

        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }
)
