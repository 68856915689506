import { Link, useLocation } from 'react-router-dom'
import spBasic from 'assets/logos/sp-basic.svg'
import CloseButton from 'components/common/CloseButton'

export function BurgerNav({ cb }: { cb: () => void }) {
  const { pathname } = useLocation()
  const dashSelected = pathname === '/'
  const statsSelected = pathname.includes('/markets')
  const activitySelected = pathname.includes('/activity')
  const pointsSelected = pathname.includes('/points')
  const multiplySelected = pathname.includes('/multiply')
  const selectedStyle = 'selected'
  const currentYear = new Date().getFullYear()

  return (
    <div className="burger-nav">
      <img src={spBasic} alt="superposition" className="burg-logo-left" />
      <CloseButton cb={cb} />
      <div className="burg-links">
        <Link to="/" onClick={cb} className={` ${dashSelected ? selectedStyle : ''}`}>
          Dashboard
        </Link>
        <Link to="/markets" onClick={cb} className={` ${statsSelected ? selectedStyle : ''}`}>
          Markets
        </Link>
        <Link to="/activity" onClick={cb} className={` ${activitySelected ? selectedStyle : ''}`}>
          Activity
        </Link>
      </div>
      <div className="bottom-links">
        <a href="https://www.superposition.finance/" target="_blank" rel="noreferrer">
          Website
        </a>
        <a href="https://docs.superposition.finance/" target="_blank" rel="noreferrer">
          Docs
        </a>
        <a href="https://www.superposition.finance/tos" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a href="https://www.superposition.finance/privacy" target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
        <hr className="hr" />
        <p className="year">&copy;&nbsp;{currentYear}&nbsp;Superposition</p>
      </div>
    </div>
  )
}
