"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperClient = void 0;
const FetchHttpRequest_1 = require("./core/FetchHttpRequest");
const DefaultService_1 = require("./services/DefaultService");
class SuperClient {
    constructor(config, HttpRequest = FetchHttpRequest_1.FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.default = new DefaultService_1.DefaultService(this.request);
    }
}
exports.SuperClient = SuperClient;
