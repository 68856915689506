export interface DetailsBoxProps {
  children: React.ReactNode
  title: string
  short?: boolean
  shadow?: boolean
}

export function DetailsBox({ children, title, short, shadow }: DetailsBoxProps) {
  const shortClass = short ? 'short' : ''
  const shadowClass = shadow ? 'shadow' : ''
  return (
    <div className={`details-box ${shortClass} ${shadowClass}`}>
      <h5 className="top-label">{title}</h5>
      {children}
    </div>
  )
}
