import React, { ErrorInfo, ReactNode } from 'react'
import TopNav from './TopNav'
import { ErrorBoundaryModal } from './ErrorBoundaryModal'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  clearStateCallback = (): void => {
    this.setState({ hasError: false, error: null, errorInfo: null })
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div>
          <TopNav />
          <ErrorBoundaryModal
            title="Application Error"
            message="Something went wrong. This message is usually caused by an error in an external wallet
            extension that cannot be handled by the adapter. Cancelling or not completing certain
            interactions in wallet extensions can create inconsistent state and compromise app
            security. Check your wallet extensions for pending interactions, and refresh the page to
            try again. If the problem persists, please contact support through discord."
            details={
              <details style={{ whiteSpace: 'pre-wrap' }} className="clickable">
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </details>
            }
            clear={this.clearStateCallback}
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
