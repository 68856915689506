import { useWallet } from '@aptos-labs/wallet-adapter-react'
import gift from 'assets/icons/gift.svg'
import CloseButton from 'components/common/CloseButton'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from 'state/hooks'
import {
  closeDoReferModal,
  closeReferralSuccess,
  selectReferralError,
  selectReferralSuccess
} from 'state/slices/app/points'
import { openWidgetSignIn } from 'state/slices/ui/widget'
import { dispatch } from 'state/store'
import { captureReferral } from 'state/thunks/captureReferral'
import success from 'assets/icons/success.svg'
import { ReferralError } from './ReferralError'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export function DoReferModal() {
  const { connected, account } = useWallet()
  const [referralCode, setReferralCode] = useState<string | null>(null)
  const isSuccessOpen = useAppSelector(selectReferralSuccess)
  const isReferralError = useAppSelector(selectReferralError)
  const hasError = isReferralError !== null

  const query = useQuery()

  useEffect(() => {
    const code = query.get('refCode')
    if (code) {
      setReferralCode(code)
    }
  }, [query])

  const buttonText = connected ? 'Confirm' : 'Connect wallet and submit code'

  const handleButtonClick = () => {
    if (connected) {
      dispatch(
        captureReferral({
          refCode: referralCode,
          address: account?.address,
          network: process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'
        })
      )
    } else {
      openWidgetSignIn()
    }
  }

  const h3Text = connected
    ? 'Submit referral code'
    : 'Get 2x more points for the first 10 days by using referral code'

  const mText = connected
    ? 'By using the referral code you will get 2x more points for the next 10 days'
    : 'You can submit the code once your wallet is successfully connected'

  return isSuccessOpen ? (
    <div className="do-refer-modal">
      <CloseButton cb={closeReferralSuccess} />
      <img src={success} alt="success" />
      <h3>
        A referral code has been successfully submitted! Enjoy earning double points for the next 10
        days.
      </h3>
      <p className="message">
        Points are earned based on your activity within the protocol. You receive 1 point per dollar
        for supplying and 2 points per dollar for borrowing. These points will be doubled for the
        next 10 days.
      </p>
      <button className="sp-btn tertiary full-w" onClick={closeReferralSuccess}>
        Close
      </button>
    </div>
  ) : hasError ? (
    <ReferralError />
  ) : (
    <div className="do-refer-modal">
      <CloseButton cb={closeDoReferModal} />
      <img src={gift} alt="promo" />
      <h3>{h3Text}</h3>
      <p className="code">{referralCode}</p>
      <p className="message">{mText}</p>
      {/* <p>Connect wallet and link referrer with the code. Both of you will earn bonus points.</p> */}
      <button className="sp-btn primary full-w large" onClick={handleButtonClick}>
        {buttonText}
      </button>
    </div>
  )
}
