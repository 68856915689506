"use strict";
/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiquidationHistoryItem = void 0;
var LiquidationHistoryItem;
(function (LiquidationHistoryItem) {
    let action;
    (function (action) {
        action["LIQUIDATE"] = "liquidate";
    })(action = LiquidationHistoryItem.action || (LiquidationHistoryItem.action = {}));
})(LiquidationHistoryItem || (exports.LiquidationHistoryItem = LiquidationHistoryItem = {}));
