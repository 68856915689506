import {
  Fetcher,
  OpenMultiplyVaultArgs,
  CloseMultiplyVaultArgs,
  TransactionArgs,
  TransactionResponse
} from './io'
import * as Gen from '@concordia/super-json-api-client'

export class SuperSdk {
  client: Gen.SuperClient
  fetcher: Fetcher

  constructor(
    /** The URL for the super JSON API */
    superApiUrl: string
  ) {
    this.fetcher = new Fetcher(superApiUrl)
    this.client = new Gen.SuperClient({
      BASE: `${superApiUrl}`
    })
  }

  async lendV2(args: TransactionArgs): Promise<TransactionResponse> {
    return await this.client.default.lendV2(args)
  }

  async redeemV2(args: TransactionArgs): Promise<TransactionResponse> {
    return await this.client.default.redeemV2(args)
  }

  async borrowV2(args: TransactionArgs): Promise<TransactionResponse> {
    return await this.client.default.borrowV2(args)
  }

  async repayV2(args: TransactionArgs): Promise<TransactionResponse> {
    return await this.client.default.repayV2(args)
  }

  async openMultiplyVault(args: OpenMultiplyVaultArgs): Promise<TransactionResponse> {
    return await this.client.default.lendAndBorrowV1(args)
  }

  async closeMultiplyVault(args: CloseMultiplyVaultArgs): Promise<TransactionResponse> {
    return await this.client.default.repayAndRedeemV1(args)
  }

  getInterestRate(u: number, interestRateCurve: Gen.InterestRateCurve): number {
    if (u === 0) {
      return interestRateCurve.r0
    }

    const u1 = interestRateCurve.u1
    const u2 = interestRateCurve.u2

    if (u < u1) {
      return this.interpolate(u, 0, u1, interestRateCurve.r0, interestRateCurve.r1)
    } else if (u < u2) {
      return this.interpolate(u, u1, u2, interestRateCurve.r1, interestRateCurve.r2)
    } else {
      return this.interpolate(u, u2, 1, interestRateCurve.r2, interestRateCurve.r3)
    }
  }

  interpolate(x: number, x0: number, x1: number, y0: number, y1: number): number {
    return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0)
  }

  utilizationPure(b: Gen.Broker): number {
    const borrowed = Number(b.totalBorrowedUnderlying)
    const available = Number(b.availableLiquidityUnderlying)
    if (borrowed === 0) {
      return 0
    }

    return borrowed / (borrowed + available)
  }

  async captureReferralCode(code: string, address: string) {
    const refer = await this.client.default.createReferal(address, { code })
    if (!refer) {
      console.log('Referal not created')
      return null
    }
    return refer
  }
}
