import downChev from 'assets/icons/down-chev-black.svg'
import { useEffect, useRef } from 'react'

export interface CustomDropdownProps {
  options: string[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedOption: string
  setSelectedOption: (selectedOption: string) => void
  resetPaginationOnFilter: () => void
}

export const CustomDropdown = ({
  options,
  isOpen,
  setIsOpen,
  selectedOption,
  setSelectedOption,
  resetPaginationOnFilter
}: CustomDropdownProps) => {
  const toggleDropdown = () => setIsOpen(!isOpen)
  const handleOptionClick = (option) => {
    setSelectedOption(option)
    setIsOpen(false) // Close the dropdown after selection
    resetPaginationOnFilter()
  }

  const dropDownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setIsOpen(false) // Close dropdown if click is outside
      }
    }

    if (isOpen) {
      // Attach click event listener when dropdown is open
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      // Clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, setIsOpen]) // Re-attach listener only if isOpen changes

  const isOpenClass = isOpen ? 'is-open' : ''
  return (
    <div className={`custom-dropdown ${isOpenClass}`} ref={dropDownRef}>
      <div className="dropdown-selected" onClick={toggleDropdown}>
        <p>{selectedOption}</p>
        <img src={downChev} alt="select time filter" />
      </div>
      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
