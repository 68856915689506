import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'
import { isEmptyOrNil } from 'toolbox/account'
import { lastRefresh } from 'state/slices/ui/dash'

export const fetchBrokerHistories = createAsyncThunk(
  'brokerHistory/fetchAll',
  async ({
    brokerNames,
    userAddress
  }: {
    brokerNames: string[]
    userAddress: string
  }): Promise<types.AllBrokersHistory> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    lastRefresh()

    const hist = await sdk.fetcher.fetchBrokerHistories(brokerNames, userAddress)
    // console.log('Broker Histories:', hist)
    return hist
  }
)

export const kickRefreshBrokerHistories = createAsyncThunk(
  'brokerHistory/kickRefreshAll',
  async ({
    brokerNames,
    userAddress
  }: {
    brokerNames: string[]
    userAddress: string
  }): Promise<types.AllBrokersHistory> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    lastRefresh()
    const hist = await sdk.fetcher.refreshBrokerHistories(brokerNames, userAddress)
    console.log('Refreshed Histories:', hist)
    if (!isEmptyOrNil(hist)) {
      return hist
    } else {
      const hist = await sdk.fetcher.fetchBrokerHistories(brokerNames, userAddress)
      console.log('Broker histories fallback fetch:', hist)
      return hist
    }
  }
)
