import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { commaFloor, formatPublicKeyDisplay } from 'toolbox/format'

export interface PointsRowProps {
  rank: number
  address: string
  spActivity: number
  referralActivity: number
  totalRunning: number
  userRow?: boolean
  userTier?: number
  isDaily?: boolean
}

export function PointsRow({
  rank,
  address,
  spActivity,
  referralActivity,
  totalRunning,
  userRow,
  userTier,
  isDaily
}: PointsRowProps) {
  const userRowClass = userRow ? 'user-row' : ''
  const totalPoints = totalRunning
  const tierDisplay = userRow ? `${userTier}` : `1 (2.0x)`
  const { connected } = useWallet()

  if (!connected && userRow) {
    return (
      <div className={`points-row ${userRowClass}`}>
        <p className="ranking">
          {'N/A'} {!!userRow && <span className="me">Me</span>}
        </p>
        <p>--</p>
        <p>--</p>
        <p>--</p>
        <p>--</p>
      </div>
    )
  }
  return (
    <div className={`points-row ${userRowClass}`}>
      <p className="ranking">
        {rank === 0 ? 'N/A' : rank} {!!userRow && <span className="me">Me</span>}
      </p>
      <p>{formatPublicKeyDisplay(address)}</p>
      <p>{commaFloor(spActivity)}</p>
      {isDaily ? <p>{tierDisplay}</p> : <p>{commaFloor(referralActivity)}</p>}
      <p>{commaFloor(totalPoints)}</p>
    </div>
  )
}
