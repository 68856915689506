import { ReactNode, useEffect, useState } from 'react'

export function Modal(props: {
  children: ReactNode
  open: boolean
  onClose: () => void
  transition?: string
}) {
  const { open, onClose, transition } = props
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  useEffect(() => {
    if (open) {
      setIsRendered(true)
    } else {
      const timer = setTimeout(() => setIsRendered(false), 300)
      return () => clearTimeout(timer)
    }
  }, [open])

  const ani = transition ? transition : ''
  const live = open ? 'live' : ''

  return (
    <div className={`modal  ${open ? 'is-active' : ''} ${ani}`} id="modal">
      <div className="modal-background" onClick={onClose}></div>
      <div className={`modal-content ${live} ${ani}`}>{isRendered && props.children}</div>
    </div>
  )
}

export default Modal
