import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { types } from '@concordia/super-sdk'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchWalletBalances } from 'state/thunks/fetchWalletBalances'

type State = Loadable<types.SWalletBalance[]>
export const initialState = initLoadable<types.SWalletBalance[]>(null)

const walletBalances = createSlice({
  name: 'walletBalances',
  initialState,
  reducers: {
    resetWalletBalances: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWalletBalances.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchWalletBalances.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetWalletBalances } = walletBalances.actions
export default walletBalances.reducer
export const selectWalletBalances = (s: RootState) => s.user.walletBalances.value
export const selectWalletBalancesBusy = (s: RootState) => s.user.walletBalances.status === 'busy'
export const selectWalletBalancesLoaded = (s: RootState) => s.user.walletBalances.loadedOnce

export const selectLoadedWalletBalances = createSelector(
  [selectWalletBalances, selectWalletBalancesLoaded],
  (bals, loaded) => {
    if (!loaded) {
      return []
    }
    if (isEmptyOrNil(bals)) {
      return []
    }

    return bals
  }
)

export const getWalletBalanceByName = (balances: types.SWalletBalance[], name: string) => {
  if (isEmptyOrNil(balances)) {
    return 0
  }
  const b = balances.find((b) => b.name === name)?.scaledBalance
  if (isEmptyOrNil(b)) {
    return 0
  }
  return b
}
