import { isEmptyOrNil } from 'toolbox/account'
import arrow from 'assets/icons/right-arrow.svg'
import pencil from 'assets/icons/pencil.svg'
import { closeSlippageMenu, openSlippageMenu, selectSlippageMenuOpen } from 'state/slices/ui/form'
import { useAppSelector } from 'state/hooks'
export interface ParamProps {
  label: string
  value: string
  tooltip?: string
  next?: string
  action?: () => void
  simColor?: string
  currentColor?: string
  slippage?: boolean
}

function Param({
  label,
  value,
  tooltip,
  next,
  action,
  slippage,
  simColor,
  currentColor
}: ParamProps) {
  const hasNext = !isEmptyOrNil(next)
  const isClickable = action ? 'clickable' : ''
  const thin = tooltip ? 'thin-lined' : ''
  const isSlippageOpen = useAppSelector(selectSlippageMenuOpen)
  const slippageAction = isSlippageOpen ? closeSlippageMenu : openSlippageMenu
  const pencilDisplay = slippage ? (
    <img src={pencil} alt="edit" className="clickable" onClick={slippageAction} />
  ) : null

  return (
    <div className="param">
      <div className={`is-flex ${thin}`}>
        <div className={`label tip-box `}>
          <p>{label}</p>
          {tooltip && <span className="tip-value">{tooltip}</span>}
        </div>
      </div>

      <div className="values">
        {action && !hasNext ? (
          <p className={`value ${isClickable}`} onClick={action}>
            {value} {pencilDisplay}
          </p>
        ) : (
          <p className={`value ${currentColor}`}>
            {value} {pencilDisplay}
          </p>
        )}
        {!!hasNext && <img src={arrow} alt="arrow" />}
        {!!hasNext && <p className={`value nextVal ${simColor}`}>{next}</p>}
      </div>
    </div>
  )
}

export default Param
