import { createContext, Dispatch, ReactNode, SetStateAction, useState, useEffect } from 'react'

export interface MetaWalletProps {
  children: ReactNode
}

const WALLET_APTOS = 'aptosWallet'

export interface MetaWallets {
  [WALLET_APTOS]?: any
}

export interface WalletContextState {
  // wallets: MetaWallets
  setContext: Dispatch<SetStateAction<MetaWallets>>
  browser: string // Add a browser property to the context state
}

const DEFAULT_CONTEXT: WalletContextState = {
  // wallets: {},
  setContext: () => {},
  browser: 'Unknown'
}

export const WalletContext = createContext<WalletContextState>(DEFAULT_CONTEXT)

function detectBrowser(): string {
  const userAgent = navigator.userAgent

  if (userAgent.includes('Firefox')) {
    return 'Firefox'
  } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
    return 'Chrome'
  } else {
    return 'Other'
  }
}

function MetaWalletContext({ children }: MetaWalletProps) {
  // Setup
  const [walletContext, setWalletContext] = useState<WalletContextState>(DEFAULT_CONTEXT)

  useEffect(() => {
    // Detect browser on component mount
    const browserType = detectBrowser()
    setWalletContext((prevContext) => ({
      ...prevContext,
      browser: browserType
    }))
  }, [])

  return <WalletContext.Provider value={walletContext}>{children}</WalletContext.Provider>
}

export default MetaWalletContext
