import { types } from '@concordia/super-sdk'

export type BasicPosition = {
  /**
   * Type string
   */
  instrumentId: string
  /**
   * Raw token units
   */
  amount: string
}

export type PortfolioState = {
  collaterals: Array<BasicPosition>
  liabilities: Array<BasicPosition>
}

/**
 * An evaluation of a portfolio with the intermediate steps included.
 */
export type Evaluation = {
  /**
   * Maintenance Margin, also known as Initial Margin.
   */
  mm: number
  /**
   * Whether or not the portfolio is considered healthy
   */
  health_ratio: number
  /**
   * Total collateral in the portfolio.
   */
  total_collateral: number
  /**
   * Total liability in the portfolio.
   */
  total_liability: number
  /**
   * Loan to Value (LTV) ratio for the portfolio.
   */
  ltv: number
  /**
   * Revolatilized returns for each asset.
   * The keys are asset identifiers, and the values are arrays of numbers representing the revolatilized returns.
   */
  rvols: Record<string, Array<number>>
  /**
   * Profit and Loss (PnL) values for the portfolio.
   * This is an array of numbers, each representing the PnL.
   */
  pnls: Array<number>
  /**
   * Final volatility scalar for each asset.
   * The keys are asset identifiers, and the values are the final volatility scalars.
   */
  final_vol_scalars: Record<string, number>
  /**
   * The stress event value for each asset.
   * The keys are asset identifiers, and the values are the stress event values.
   */
  stresses: Record<string, number>
  /**
   * Volatility for each asset.
   * The keys are asset identifiers, and the values are arrays of numbers representing the volatilities.
   */
  vols: Record<string, Array<number>>
  /**
   * Relative returns for each asset.
   * The keys are asset identifiers, and the values are arrays of numbers representing the relative returns.
   */
  rrs: Record<string, Array<number>>
}

export async function fetchSimulatedPortfolio({
  portfolio,
  apiUrl,
  signal
}: {
  portfolio: PortfolioState
  apiUrl: string
  signal?: AbortSignal
}): Promise<Evaluation> {
  if (!apiUrl) {
    throw new Error('API URL is required')
  }

  console.log('here')

  const response = await fetch(`${apiUrl}/risk/simulated`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(portfolio),
    signal
  })

  console.log('here2')

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  return (await response.json()) as Evaluation
}
