import Tab, { TabProps } from './Tab'

export type TabVars = 'basic' | 'tab-link'
export type TabMods = 'full-width'

export interface TabsProps {
  tabs: TabProps[]
  variant: TabVars
  mods?: TabMods
}

function Tabs({ tabs, variant, mods }: TabsProps) {
  function toTab(t: TabProps, i: number) {
    return <Tab label={t.label} active={t.active} onClick={t.onClick} key={i} />
  }
  return <div className={`sp-tabs ${variant} ${mods && mods}`}>{tabs.map(toTab)}</div>
}

export default Tabs
