import { ReactNode, useState } from 'react'
import InfoPoint from '../common/InfoPoint'
import tooltipIcon from 'assets/icons/tooltip.svg'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import FlashRow from './FlashRow'

export interface MetaDisplayProps {
  secondIcon: string
  value: string
  sub: string
  secondarySub?: string
  isLoadedAny: boolean
  color?: number
  hp?: ReactNode
  openForm?: () => void
  tooltip?: string
  stackIcons?: boolean
  divider?: boolean
  isHover?: boolean
  hideSecondaryOnBreakpoint?: boolean
  percentage?: string
  assetHover?: string
  fitWidth?: boolean
}

function MetaDisplay({
  secondIcon,
  value,
  sub,
  isLoadedAny,
  secondarySub,
  color,
  hp,
  openForm,
  tooltip,
  stackIcons,
  divider,
  isHover,
  hideSecondaryOnBreakpoint,
  percentage,
  assetHover,
  fitWidth
}: MetaDisplayProps) {
  const info = {
    value,
    sub,
    secondarySub: secondarySub ? secondarySub : null,
    color,
    hideSecondaryOnBreakpoint
  }
  const hov = hp ? 'hp-con' : ''
  const { connected } = useWallet()
  const clickOption = openForm && connected ? openForm : null
  const clickClass = openForm && connected ? 'clickable' : ''
  const stackAdjust = stackIcons ? 'stack-adjust' : ''
  const innerHoverClass = isHover ? 'inner-hover' : ''
  const fitWidthClass = fitWidth ? 'fit-width' : ''
  const [isMetaHover, setIsMetaHover] = useState(false)

  const onHover = () => {
    setIsMetaHover(true)
  }

  const onLeave = () => {
    setIsMetaHover(false)
  }

  const showHoverClass = isMetaHover ? 'show-hover' : ''

  return isLoadedAny ? (
    <>
      <div
        className={`meta-display ${hov} ${clickClass} ${innerHoverClass} ${fitWidthClass}`}
        onClick={clickOption}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}>
        <div className="stacked">
          <img src={secondIcon} alt={value} />
        </div>
        {divider && <div className="divider-global" />}

        <div className={`info ${stackAdjust} `}>
          <InfoPoint {...info} />
          {percentage && <div className="percentage">{percentage}</div>}
        </div>
        {!!hp && hp}
        {tooltip && (
          <div className="tip-box c-row">
            <img src={tooltipIcon} alt="tooltip" className="tip-icon" />
          </div>
        )}
        {assetHover && <div className={`meta-tip top-down ${showHoverClass}`}>{assetHover}</div>}
      </div>
    </>
  ) : (
    <FlashRow metaOnly />
  )
}

export default MetaDisplay
