import { Wallet } from '@aptos-labs/wallet-adapter-core'
import AccountWalletsList from './AccountWalletsList'

export interface WidgetSelectProps {
  closeSelect: () => void
}

function WidgetSelect({ closeSelect }: WidgetSelectProps) {
  return (
    <div className="right">
      <h3>Select Wallet</h3>
      <AccountWalletsList />
    </div>
  )
}

export default WidgetSelect
