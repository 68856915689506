import { combineReducers } from 'redux'
import portfolio from 'state/slices/user/portfolio'
import walletBalances from 'state/slices/user/walletBalances'
import referral from 'state/slices/user/referral'
import referralsByUser from 'state/slices/user/referralsByUser'
import multiplyPortfolio from 'state/slices/user/multiplyPortfolio'

export const user = combineReducers({
  portfolio,
  walletBalances,
  referral,
  referralsByUser,
  multiplyPortfolio
})
