import { configureStore } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from './context'
import { rootReducer } from './reducers/root'

export const configureTheStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: SDK_CONTEXT
        }
      })
  })

export const store = configureTheStore()
export const { dispatch } = store

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof configureTheStore>
export type AppDispatch = typeof store.dispatch
