import plane from 'assets/icons/paper-plane.svg'
import CloseButton from 'components/common/CloseButton'
import { useAppSelector } from 'state/hooks'
import { closeReferralModal } from 'state/slices/app/points'
import { selectReferralCode } from 'state/slices/user/referral'
import { toastSuccess } from 'toolbox/toast'

const REFERRAL_PROMO = `I found this Tribe capital backed project @superpositionfi with huge airdrop potential!
Supply or borrow Aptos chain assets to be eligible! Get 10% extra points with this
link: `

export function LinkModal() {
  const refCode = useAppSelector(selectReferralCode)
  const networkShort = process.env.REACT_APP_APTOS_NETWORK_SHORT
  const isTestnet = networkShort === 'testnet'
  const link = isTestnet
    ? `https://testnet.app.superposition.finance/?refCode=${refCode}`
    : `https://app.superposition.finance/?refCode=${refCode}`

  const handleButtonClick = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText((REFERRAL_PROMO + link) as string)
    toastSuccess('Text copied!')
  }
  return (
    <div className="link-modal">
      <CloseButton cb={closeReferralModal} />

      <img src={plane} alt="share" />
      <h3>Share my referral link</h3>
      <hr className="hr" />
      <p>{REFERRAL_PROMO}</p>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </a>
      <button className="sp-btn tertiary full-w" onClick={handleButtonClick}>
        Copy
      </button>
    </div>
  )
}
