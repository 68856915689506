import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'
import { isEmptyOrNil } from 'toolbox/account'

const MAX_ATTEMPTS = 5

export const fetchStrategies = createAsyncThunk(
  'strategies/fetchAll',
  async (): Promise<types.SFlashLoanStrategies> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not provided')
    }

    let attempt = 0

    const baseDelay = 1000

    while (attempt < MAX_ATTEMPTS) {
      try {
        const strategies = await sdk.fetcher.fetchStrategies()
        console.log('Strategies: ', strategies)
        if (isEmptyOrNil(strategies)) {
          throw new Error('Failed to fetch strategies')
        }
        return strategies
      } catch (e: any) {
        attempt++
        console.log(`Attempt ${attempt}: Failed to fetch strategies, retrying...`)

        if (attempt >= MAX_ATTEMPTS) {
          throw new Error(e.message || 'Failed to fetch strategies after multiple attempts')
        }

        const delay = baseDelay * Math.pow(2, attempt - 1) // Exponential backoff formula
        console.log(`Waiting ${delay} ms before next attempt...`)

        await new Promise((resolve) => setTimeout(resolve, delay))
      }
    }
  }
)
