import { formatPercentage, prettyTokenBalWithK, prettyTokenBal } from 'toolbox/format'
import aptos from 'assets/logos/apt-circle.svg'
import { openMultiplyFormWithBroker } from 'state/slices/ui/form'
import { MetaBroker } from 'state/mock'
import { SFlashLoanStrategy } from '@concordia/super-sdk/src/io'

export interface MultiPositionProps {
  lendAmount: number
  borrowAmount: number
  lendTokenTicker: string
  netAPR: number
  healthFactor: number
  leverage: number
  availableLiq: number
  maxApy: number
  openModal: () => void
  hasPosition: boolean
  borrowBroker: MetaBroker
  lendBroker: MetaBroker
  strategy: SFlashLoanStrategy
}

export function MultiPosition({
  lendAmount,
  borrowAmount,
  lendTokenTicker,
  netAPR,
  healthFactor,
  leverage,
  availableLiq,
  maxApy,
  openModal,
  hasPosition,
  borrowBroker,
  lendBroker,
  strategy
}: MultiPositionProps) {
  const emptyPositionStyle = !hasPosition ? 'empty' : ''

  const useButton = hasPosition ? (
    <button className="sp-btn primary rounded" onClick={openModal}>
      Close position
    </button>
  ) : (
    <button
      className="sp-btn tertiary rounded"
      onClick={() => openMultiplyFormWithBroker(borrowBroker, lendBroker, strategy)}>
      Multiply
    </button>
  )
  return (
    <>
      <div className="multi-position multi-card-columns">
        <div className="multi-card-headers long">
          <p>Borrow Asset</p>
          <p>Max APR</p>
          <p>Liquidity Available</p>
          <p>Total Exposure</p>
          <p>Total Debt</p>
          <p>My Multiply</p>
          <p>Net APR</p>
          <p>Health Factor</p>
        </div>

        <div className={`multi-card-row ${emptyPositionStyle}`}>
          <div className="row-left long">
            <p className="left-col">
              <img src={aptos} alt="token logo" />
            </p>
            <p className="left-col">{formatPercentage(maxApy)}</p>
            <p className="left-col">{prettyTokenBalWithK(availableLiq)}</p>
            <p className="left-col-2">
              {prettyTokenBal(lendAmount)} {lendTokenTicker}
            </p>
            <p className="left-col-2">{prettyTokenBal(borrowAmount)} APT</p>
            <p className="left-col-2">{leverage + 'x'}</p>
            <p className="left-col-2">{formatPercentage(netAPR)}</p>
            <p className="left-col-2">{!hasPosition ? 'N/A' : healthFactor + 'x'}</p>
          </div>
          {useButton}
        </div>
        <div className="blue-bg" />
      </div>
    </>
  )
}
