import React, { useEffect, useRef, useState } from 'react'

interface SliderProps {
  min: number
  max: number
  step: number
  value: number
  onChange: (value: number) => void
}

const Slider: React.FC<SliderProps> = ({ min, max, step, value, onChange }) => {
  const sliderRef = useRef<HTMLInputElement>(null)
  const [valuePosition, setValuePosition] = useState<number>(0)

  useEffect(() => {
    if (sliderRef.current) {
      const percent = (value - min) / (max - min)
      const newPosition = percent * sliderRef.current.clientWidth
      setValuePosition(newPosition)
    }
  }, [value, min, max])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(parseInt(e.target.value, 10))
  }

  return (
    <div className="slider-container">
      <p>Multiplier</p>
      <div className="slider-track" style={{ width: `${valuePosition}px` }}></div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className="slider"
        ref={sliderRef}
      />
      <div className="slider-value" style={{ left: `calc(${valuePosition}px)` }}>
        {value}x
      </div>
    </div>
  )
}

export default Slider
