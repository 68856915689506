import Dashboard from 'pages/Dashboard'
import { Route, Routes } from 'react-router-dom'
import { Markets } from 'pages/Markets'
import { Activity } from 'pages/Activity'
import { Points } from 'pages/Points'
import { Multiply } from 'pages/Multiply'
import { Toggles } from 'pages/Toggles'
import { selectMultiplyToggleOn } from 'state/slices/app/toggles'
import { useAppSelector } from 'state/hooks'

export function Routing() {
  const isMultiplyToggleOn = useAppSelector(selectMultiplyToggleOn)
  const routes = (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/markets" element={<Markets />} />
      <Route path="/activity" element={<Activity />} />
      <Route path="/points" element={<Points />} />
      {isMultiplyToggleOn && <Route path="/multiply" element={<Multiply />} />}
      <Route path="/toggles" element={<Toggles />} />
    </Routes>
  )

  return routes
}
