export interface ChartCardWrapperProps {
  children: React.ReactNode
  title: string
  dataPointValue: string
}

export function ChartCardWrapper({ children, title, dataPointValue }: ChartCardWrapperProps) {
  return (
    <div className="chart-card-wrapper">
      <div className="card-title">
        <h3>{title}</h3>
        <h3>{dataPointValue}</h3>
      </div>
      <div className="inner-chart-card">{children}</div>
    </div>
  )
}
