import { TabProps } from 'components/common/Tab'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, dispatch } from 'state/store'
import { createSelector } from 'reselect'
import { setTab, switchFormLabels } from './form'
import Cookies from 'js-cookie'

export const COLLATERAL = 'Supply'
export const BORROW = 'Borrow'
export const COLLATERAL_COLUMNS = [
  'Chain/Asset',
  'Supplied',
  'Utilization',
  'Total Supplied',
  'Supply APR'
]
export const BORROW_COLUMNS = [
  'Chain/Asset',
  'Borrowed',
  'Max borrow',
  'Total available',
  'Borrow APR'
]

export type DashTab = typeof COLLATERAL | typeof BORROW
export const dashLabels: DashTab[] = [COLLATERAL, BORROW]

export interface DashState {
  dashTab: DashTab
  input: string
  bannerOpen: boolean
  activityInput: string
  showInfoBox: boolean
  lastRefresh: number
  isBlocked: boolean
  showBlockModal: boolean
}

//banner not currently in use
const bannerClosed = Cookies.get('bannerClosed')
const bannerStatus = bannerClosed ? false : true

//get cookie for info box for initial state
const hideInfoBoxCookie = Cookies.get('hideInfoBox')
const showInfoBox = hideInfoBoxCookie ? false : true

export const initialState: DashState = {
  dashTab: COLLATERAL,
  input: '',
  bannerOpen: bannerStatus,
  activityInput: '',
  showInfoBox,
  lastRefresh: 0,
  isBlocked: true,
  showBlockModal: false
}

const slice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    setDashTab: (s, a: PayloadAction<DashTab>) => {
      s.dashTab = a.payload
    },
    setInput: (s, a: PayloadAction<string>) => {
      s.input = a.payload
    },
    setActivityInput: (s, a: PayloadAction<string>) => {
      s.activityInput = a.payload
    },
    setBannerOpen: (s) => {
      s.bannerOpen = true
    },
    setBannerClosed: (s) => {
      s.bannerOpen = false
    },
    hideInfoBox: (s) => {
      s.showInfoBox = false
    },
    setLastRefresh: (s) => {
      s.lastRefresh = Date.now()
    },
    resetLastRefresh: (s) => {
      s.lastRefresh = 0
    },
    setIsBlocked: (s) => {
      s.isBlocked = true
    },
    resetIsBlocked: (s) => {
      s.isBlocked = false
    },
    setShowBlockModal: (s) => {
      s.showBlockModal = true
    },
    hideBlockModal: (s) => {
      s.showBlockModal = false
    }
  }
})

export default slice.reducer

//selectors
export const selectDashTab = (s: RootState) => s.ui.dash.dashTab
export const selectBuiltDashTabs = createSelector([selectDashTab], (selectedTab) => {
  const tabs = buildDashTabs(selectedTab)
  return tabs
})
export const selectDashInput = (s: RootState) => s.ui.dash.input
export const selectActivityInput = (s: RootState) => s.ui.dash.activityInput
export const selectBannerOpen = (s: RootState) => s.ui.dash.bannerOpen
export const selectShowInfoBox = (s: RootState) => s.ui.dash.showInfoBox
export const selectLastRefresh = (s: RootState) => s.ui.dash.lastRefresh
export const selectIsBlocked = (s: RootState) => s.ui.dash.isBlocked
export const selectShowBlockModal = (s: RootState) => s.ui.dash.showBlockModal

//dispatches
export const {
  setDashTab,
  setInput,
  setBannerClosed,
  setBannerOpen,
  setActivityInput,
  hideInfoBox,
  setLastRefresh,
  resetLastRefresh,
  setIsBlocked,
  resetIsBlocked,
  setShowBlockModal,
  hideBlockModal
} = slice.actions

export const setDashboardTab = (t: DashTab) => {
  dispatch(setDashTab(t))
  const formLabels = switchFormLabels(t)
  dispatch(setTab(formLabels[0]))
}
export const setDashInput = (p: string) => {
  dispatch(setInput(p))
}
export const setActivitySearch = (p: string) => {
  dispatch(setActivityInput(p))
}
export const closeBanner = () => {
  Cookies.set('bannerClosed', 'true')
  dispatch(setBannerClosed())
}
export const hideBox = () => {
  Cookies.set('hideInfoBox', 'true')
  dispatch(hideInfoBox())
}
export const lastRefresh = () => {
  dispatch(setLastRefresh())
}
export const setBlocked = () => {
  dispatch(setIsBlocked())
}
export const resetBlocked = () => {
  dispatch(resetIsBlocked())
}
export const showBlock = () => {
  dispatch(setShowBlockModal())
}
export const closeBlock = () => {
  dispatch(hideBlockModal())
}

//prebuilds
export const buildDashTabs = (selected: DashTab) => {
  const dashTabs: TabProps[] = dashLabels.map((label) => ({
    label,
    active: label === selected,
    onClick: () => setDashboardTab(label)
  }))
  return dashTabs
}

const BorrowColumns = (labels: string[]) => {
  return (
    <div className="columns inner">
      <div className="column is-4">
        <p>{labels[0]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[1]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[2]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[3]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[4]}</p>
      </div>
    </div>
  )
}

const SupplyColumns = (labels: string[]) => {
  return (
    <div className="columns inner">
      <div className="column is-4">
        <p>{labels[0]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[1]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[2]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[3]}</p>
      </div>
      <div className="column is-2">
        <p>{labels[4]}</p>
      </div>
    </div>
  )
}

export const selectColumns = createSelector([selectDashTab], (dashTab) => {
  switch (dashTab) {
    case COLLATERAL:
      return SupplyColumns(COLLATERAL_COLUMNS)
    case BORROW:
      return BorrowColumns(BORROW_COLUMNS)
  }
})
