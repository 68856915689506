import double from 'assets/icons/double-arrow.svg'
import spName from 'assets/logos/sp-name.svg'
import { Tooltip } from 'components/common/Tooltip'
import { openMultiplyFormWithBroker } from 'state/slices/ui/form'
import { getBrokerByName, selectBrokersWithMeta } from 'state/slices/app/brokers'
import { useAppSelector } from 'state/hooks'
import { formatPercentage, prettyTokenBalWithK, scaleDown } from 'toolbox/format'
import { MultiPosition, MultiPositionProps } from './MultiPosition'
import { selectLoadedMultiplyPosition } from 'state/slices/user/multiplyPortfolio'
import { isEmptyOrNil } from 'toolbox/account'
import { SFlashLoanStrategy } from '@concordia/super-sdk/src/io'
import { calculateNetAPR } from 'toolbox/calc'
import { useWallet } from '@aptos-labs/wallet-adapter-react'

export interface MultiCardProps {
  borrowTokenName: string
  lendTokenName: string
  availableLiquidity: number
  strategy: SFlashLoanStrategy
  marketName: string
  marketLogo: string
}

export function MultiCard({
  borrowTokenName,
  lendTokenName,
  availableLiquidity,
  strategy,
  marketName,
  marketLogo
}: MultiCardProps) {
  const brokers = useAppSelector(selectBrokersWithMeta)
  const borrowBroker = getBrokerByName(brokers, borrowTokenName)
  const borrowTicker = borrowBroker?.tokenMeta.ticker
  const borrowLogo = borrowBroker?.tokenMeta.logo
  const lendBroker = getBrokerByName(brokers, lendTokenName)
  const lendTicker = lendBroker?.tokenMeta.ticker
  const lendLogo = lendBroker?.tokenMeta.logo
  const decimals = borrowBroker?.underlyingAsset.decimals
  const scaledAvail = scaleDown(availableLiquidity, decimals)
  const { connected } = useWallet()

  const preMultiPosition = useAppSelector(selectLoadedMultiplyPosition)

  const lendAmount = Number(preMultiPosition?.vault_balance.collaterals[0]?.amount)
  const scaledLendAmount = scaleDown(lendAmount, lendBroker?.depositNote.decimals) || 0
  const maxAPR = calculateNetAPR(strategy)
  const userLeverage = preMultiPosition?.leverage_value || 0
  const userAPR = userLeverage > 0 ? calculateNetAPR(strategy, userLeverage) : 0

  const showMulti = connected
  const hasPosition = !isEmptyOrNil(preMultiPosition) && lendAmount > 0

  const borrowValueUV = preMultiPosition?.vault_balance.liabilities[0]?.amount || 0
  const scaledBorrowValueUV = scaleDown(Number(borrowValueUV), borrowBroker?.loanNote.decimals)

  const leverage = Math.round(preMultiPosition?.leverage_value) || 0
  const multiPosition: MultiPositionProps = {
    lendAmount: scaledLendAmount,
    borrowAmount: scaledBorrowValueUV,
    lendTokenTicker: lendTicker || '',
    hasPosition,
    netAPR: userAPR,
    healthFactor: Number(preMultiPosition?.health_ratio?.toFixed(2)) || 0,
    leverage: leverage,
    availableLiq: scaledAvail,
    maxApy: maxAPR,
    openModal: () => openMultiplyFormWithBroker(borrowBroker, lendBroker, strategy),
    borrowBroker,
    lendBroker,
    strategy
  }

  return (
    <div className="multi-card">
      <div className="multi-card-over">
        <div className="over-left">
          <div className="apy-preview">
            up to <span className="bigger">{formatPercentage(maxAPR)} APR</span>
          </div>
          <div className="logos">
            <img src={marketLogo} alt="thala name" className="name-logo" />
            <p className="plus">+</p>
            <img src={spName} alt="superposition name" className="name-logo" />
          </div>
        </div>
        <div className="over-right">
          <div className="over-details">
            Get up to {formatPercentage(maxAPR)} APR by multiplying {lendTicker} exposure against{' '}
            {borrowTicker} using {marketName} <br />
            <div className="split">
              <a
                href="https://medium.com/superp-fi/multiply-for-leveraged-lsd-strategy-74d85db256b4"
                rel="noreferrer"
                target="_blank">
                Learn more about Multiply Leveraged LSD
              </a>
              <span className="right-details">
                Afraid of Liquidations?&nbsp;&nbsp;{' '}
                <Tooltip
                  label=""
                  value={`Leverage LSD Multiply strategy is completely unaffected by changes in LSD market prices, or by temporary LSD de-pegs`}
                  normal
                  shiftLeft
                  small
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="hr" />
      <div className="multi-card-top">
        <div className="multi-card-meta">
          <img src={borrowLogo} alt="token logo" className="logo" />
          <img src={double} alt="multiply" />
          <img src={lendLogo} alt="token logo" className="logo" />
          <p>
            {borrowTicker} &#183; {lendTicker}
          </p>
        </div>
      </div>
      {showMulti ? (
        <MultiPosition {...multiPosition} />
      ) : (
        <div className="multi-card-columns">
          <div className="multi-card-headers">
            <p>Borrow Asset</p>
            <p>Max APR</p>
            {/* <p>Max Multiple</p> */}
            <p>Liquidity Available</p>
          </div>
          <div className="multi-card-row">
            <div className="row-left">
              <div className="left-col">
                <img src={borrowLogo} alt="token logo" />
              </div>
              <p className="left-col">{formatPercentage(maxAPR)}</p>
              {/* <p className="left-col">{maxMultiple}x</p> */}
              <p className="left-col">{prettyTokenBalWithK(scaledAvail)}</p>
            </div>

            <button
              className="sp-btn tertiary rounded"
              onClick={() => openMultiplyFormWithBroker(borrowBroker, lendBroker, strategy)}>
              Multiply
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
