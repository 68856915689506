import { useEffect } from 'react'

export function FullModal(props) {
  const { children, open, onClose } = props

  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  return (
    <div
      className={`full-modal ${open ? ' is-active' : ''}`}
      id="modal"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          onClose()
        }
      }}>
      <div className={`full-modal-content left-in ${open ? 'live' : ''} `}>{children}</div>
    </div>
  )
}
