import { createSlice } from '@reduxjs/toolkit'
import { initLoadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchPriceHistories } from 'state/thunks/fetchPriceHistories'
import { types } from '@concordia/super-sdk'

const initialState = initLoadable<types.HistoricalPrices>({})

export const priceHistories = createSlice({
  name: 'priceHistories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPriceHistories.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchPriceHistories.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export default priceHistories.reducer
export const selectPriceHistories = (s: RootState) => s.app.priceHistories.value
export const selectPriceHistoriesBusy = (s: RootState) => s.app.priceHistories.status === 'busy'
export const selectPriceHistoriesLoaded = (s: RootState) => s.app.priceHistories.loadedOnce

export const getPriceHistoryByName = (name: string, histories: types.HistoricalPrices) => {
  if (isEmptyOrNil(histories)) {
    return []
  }
  const history = histories[name]
  if (!history) {
    return []
  }
  return history
}
