import { MetaBroker } from 'state/mock'
import { calcUtil } from 'toolbox/calc'
import { formatPercentage, prettyTokenBal } from 'toolbox/format'
import ParamList from 'components/txForm/ParamList'
import { useAppSelector } from 'state/hooks'
import { selectIncentivesToggleOn } from 'state/slices/app/toggles'
import {
  calcBorrowData,
  calcSupplyData,
  selectFormattedPositions
} from 'state/slices/user/portfolio'
import { buildIncentiveData } from 'state/slices/ui/form'
import { getPriceFromName, selectPricesFromBrokers } from 'state/slices/app/brokers'

export interface MobileMarketCardProps {
  broker: MetaBroker
}

export function MobileMarketCard({ broker }: MobileMarketCardProps) {
  const brokerBorrowed = broker?.scaledTotalBorrowedUnderlying
  const totalAvailable = broker?.scaledAvailableLiquidityUnderlying
  const brokerTotal = brokerBorrowed + totalAvailable
  const utilization = calcUtil(broker)
  const t = broker.tokenMeta
  const prices = useAppSelector(selectPricesFromBrokers)
  const positions = useAppSelector(selectFormattedPositions)
  const aptPrice = getPriceFromName('aptos', prices)

  const incentivesToggleOn = useAppSelector(selectIncentivesToggleOn)

  const borrowData = calcBorrowData(positions, broker)
  const supplyData = calcSupplyData(positions, broker)
  const incentiveData = buildIncentiveData(broker, aptPrice)

  const { supplyRewardRate, borrowRewardRate } = incentiveData

  //broker params and projections
  const brokerStats = [
    {
      label: 'Lend APR',
      value: formatPercentage(supplyData.supplyAPR + supplyRewardRate),
      tooltip: `Total lend APR`
    },
    {
      label: 'Borrow APR',
      value: formatPercentage(borrowData.borrowAPR - borrowRewardRate),
      tooltip: `Total borrow APR`
    },
    {
      label: 'Total Supplied In Broker',
      value: prettyTokenBal(brokerTotal),
      tooltip: 'Liquidity supplied in tokens'
    },
    {
      label: 'Utilization',
      value: formatPercentage(utilization),
      tooltip: 'Ratio of debt / collateral.  High utilization increases interest'
    }
  ]

  return (
    <div className="mobile-broker-card">
      <div className="top-card">
        <div className="basic-meta small">
          <img src={t.logo} alt={t.name} />
          <div className="names">
            <p className="name">{t.ticker}</p>
            <p className="full-name">{t.displayName}</p>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <ParamList params={brokerStats} />
    </div>
  )
}
