import { toast } from 'react-hot-toast'
import { setTxErrorState } from 'state/slices/ui/transaction'

const TOAST_ID = 'toasty'

export function toastLoad(message: string) {
  toast.loading(message, { id: TOAST_ID })
}

export function dismissNotifyThrow(title: string, message: string, explorerHash?: string) {
  toast.dismiss(TOAST_ID)
  const txErrorState = {
    title,
    message,
    explorerHash
  }
  setTxErrorState(txErrorState)
  throw new Error(`${title}: ${message}`)
}

export function toastLinkAccount(hash: string, network: string, message: string) {
  toast.success(
    <p>
      {message}
      <a
        href={`https://explorer.aptoslabs.com/account/${hash}?network=${network}`}
        target="_blank"
        rel="noreferrer"
        className="blue-link">
        Explorer
      </a>
    </p>,
    { id: TOAST_ID, duration: 10000 }
  )
}

export function toastSuccess(message: string) {
  toast.success(message, { id: TOAST_ID, duration: 2000 })
}

export function logAndToastError(type: string, e: any) {
  console.log(`${type} Error`)
  console.log(e)
  toast.error(`${e}`, { id: TOAST_ID, duration: 1500 })
}
