import { createAsyncThunk } from '@reduxjs/toolkit'
import { types } from '@concordia/super-sdk'
import { SDK_CONTEXT } from 'state/context'

export const fetchPointsTotals = createAsyncThunk(
  'pointsTotals/fetchAll',
  async (): Promise<types.SPointsTotals> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }
    const pointsTotals = await sdk.fetcher.fetchPointsTotals()
    // console.log('Points Totals: ', pointsTotals)
    return pointsTotals
  }
)
