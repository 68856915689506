import aptCircle from 'assets/logos/apt-circle.svg'
import aptSquare from 'assets/logos/apt-square.svg'
import usdcCircle from 'assets/logos/usdc-circle.svg'
import thAPTLogo from 'assets/logos/thAPT.svg'
import sthAPTLogo from 'assets/logos/sthAPT.png'
import amAPTLogo from 'assets/logos/amAPT.png'
import stAPTLogo from 'assets/logos/stAPT.png'
import thlLogo from 'assets/logos/thl.svg'
import usdcWHLogo from 'assets/logos/usdcWH.svg'
import usdtLZLogo from 'assets/logos/usdtLZ.svg'
import usdtWHLogo from 'assets/logos/usdtWH.svg'
import wethLZLogo from 'assets/logos/wethLZ.svg'
import wethWHLogo from 'assets/logos/wethWH.svg'
import btcLogo from 'assets/logos/btc.svg'
import truAPTLogo from 'assets/logos/truApt.svg'
import usdtLogo from 'assets/logos/usdt.svg'

export type ChainId = string
export interface ChainMeta {
  chainID: string
  logoURL: string
  name: string
  description: string
}

export interface TokenMeta {
  chainID: ChainId
  logo: string
  ticker: string
  name: string
  description: string
  projectURL: string
  displayName: string
}

export interface ChainMetaMap {
  [key: ChainId]: ChainMeta
}

export interface TokenMetaMap {
  [key: string]: TokenMeta
}

export const DEFAULT_CHAIN: ChainMeta = {
  chainID: '',
  logoURL: '',
  name: '',
  description: ''
}

export const DEFAULT_TOKEN: TokenMeta = {
  chainID: '',
  logo: '',
  ticker: '',
  name: '',
  description: '',
  projectURL: '',
  displayName: ''
}

//chains
export const CHAIN_APTOS = 'Aptos'
//logos
export const aptosLogo = aptCircle
export const aptosChainLogo = aptSquare
export const usdcLogo = usdcCircle
//tokens
const APTOS = 'aptos'
const APT_USDC = 'usdc'
const THAPT = 'thapt'
const STH_APT = 'sthapt'
const AM_APT = 'amapt'
const STAKED_APT = 'stapt'
const THALA = 'thl'
const WETH_WH = 'wethwh'
const WETH_LZ = 'weth'
const USDT_LZ = 'usdtlz'
const USDC_WH = 'usdcwh'
const USDT_WH = 'usdtwh'
const BTC = 'wbtc'
const ABTC = 'abtc'
const TRU_APT = 'truapt'
const USDT = 'usdt-fa'

export interface MintMap {
  [key: string]: number
}

//map chain ids to chain meta
export const chains: ChainMetaMap = {
  [CHAIN_APTOS]: {
    name: 'Aptos',
    chainID: CHAIN_APTOS,
    logoURL: aptosChainLogo,
    description: 'Aptos'
  }
}

//token meta objects
export const aptos: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: APTOS,
  ticker: 'APT',
  description: '',
  logo: aptosLogo,
  projectURL: '',
  displayName: 'Aptos'
}

export const apt_usdc: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: APT_USDC,
  ticker: 'zUSDC',
  description: '',
  logo: usdcLogo,
  projectURL: '',
  displayName: 'USD Coin (LayerZero)'
}

export const thAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: THAPT,
  ticker: 'thAPT',
  description: '',
  logo: thAPTLogo,
  projectURL: '',
  displayName: 'Thala APT'
}

export const sthAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: STH_APT,
  ticker: 'sthAPT',
  description: '',
  logo: sthAPTLogo,
  projectURL: '',
  displayName: 'Staked Thala APT'
}

export const amAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: AM_APT,
  ticker: 'amAPT',
  description: '',
  logo: amAPTLogo,
  projectURL: '',
  displayName: 'Amnis APT'
}

export const stakedAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: STAKED_APT,
  ticker: 'stAPT',
  description: '',
  logo: stAPTLogo,
  projectURL: '',
  displayName: 'Staked Amnis APT'
}

export const thala: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: THALA,
  ticker: 'THL',
  description: '',
  logo: thlLogo,
  projectURL: '',
  displayName: 'Thala'
}

export const wrappedEthWh: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: WETH_WH,
  ticker: 'whWETH',
  description: '',
  logo: wethWHLogo,
  projectURL: '',
  displayName: 'Wrapped Ethereum (Wormhole)'
}

export const wrappedEthLZ: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: WETH_LZ,
  ticker: 'zWETH',
  description: '',
  logo: wethLZLogo,
  projectURL: '',
  displayName: 'Wrapped Ethereum (LayerZero)'
}

export const usdtLZ: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDT_LZ,
  ticker: 'zUSDT',
  description: '',
  logo: usdtLZLogo,
  projectURL: '',
  displayName: 'USD Tether (LayerZero)'
}

export const usdcWH: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDC_WH,
  ticker: 'whUSDC',
  description: '',
  logo: usdcWHLogo,
  projectURL: '',
  displayName: 'USD Coin (Wormhole)'
}

export const usdtWH: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDT_WH,
  ticker: 'whUSDT',
  description: '',
  logo: usdtWHLogo,
  projectURL: '',
  displayName: 'USD Tether (Wormhole)'
}

export const btc: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: BTC,
  ticker: 'zWBTC',
  description: '',
  logo: btcLogo,
  projectURL: '',
  displayName: 'Wrapped Bitcoin (LayerZero)'
}

export const abtc: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: ABTC,
  ticker: 'aBTC',
  description: '',
  logo: btcLogo,
  projectURL: '',
  displayName: 'Echo Bridged Bitcoin'
}

export const truAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: TRU_APT,
  ticker: 'truAPT',
  description: '',
  logo: truAPTLogo,
  projectURL: '',
  displayName: 'Trufin Staked APT'
}

export const usdt: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDT,
  ticker: 'USDT',
  description: '',
  logo: usdtLogo,
  projectURL: '',
  displayName: 'USD Tether'
}

export const tokenMetaMap: TokenMetaMap = {
  [APT_USDC]: apt_usdc,
  [USDC_WH]: usdcWH,
  [USDT_LZ]: usdtLZ,
  [USDT]: usdt,
  [USDT_WH]: usdtWH,
  [APTOS]: aptos,
  [TRU_APT]: truAPT,
  [STH_APT]: sthAPT,
  [THAPT]: thAPT,
  [STAKED_APT]: stakedAPT,
  [AM_APT]: amAPT,
  [WETH_LZ]: wrappedEthLZ,
  [WETH_WH]: wrappedEthWh,
  [BTC]: btc,
  [ABTC]: abtc,
  [THALA]: thala
}

export const DUST_LIMIT = 0.00000001
