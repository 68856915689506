import { formatPercentage } from 'toolbox/format'
import tspAptos from 'assets/logos/aptTransparent.svg'

export interface RewardRateProps {
  rewardRate: number
  timeFrame: string
  baseRate: number
  isBorrow?: boolean
}

export function RewardRate({ rewardRate, baseRate, isBorrow }: RewardRateProps) {
  return (
    <div className="reward-rate-display">
      <p className="plus">{formatPercentage(baseRate)}</p>
      <div className="pill">
        {isBorrow ? '-' : '+'}
        <p>{formatPercentage(rewardRate)}</p>
        <img src={tspAptos} alt="APT" />
      </div>
      {/* <div className="time-frame-box">
        <div className="clock-icon" />
        <p>{timeFrame}</p>
      </div> */}
    </div>
  )
}
