export interface WidgetColumnProps {
  h1: string
  asset: string
  p: string
  h2?: string
}

function WidgetColumn({ h1, asset, p, h2 }: WidgetColumnProps) {
  return (
    <div className="left">
      <h1>{h1}</h1>
      <img src={asset} alt="account" className="sso" />
      {!!h2 && h2}
      <p>{p}</p>
    </div>
  )
}

export default WidgetColumn
