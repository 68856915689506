import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchReferralsByUser } from 'state/thunks/fetchReferralsByUser'
import { types } from '@concordia/super-sdk'

type State = Loadable<types.SReferralsByUser>
export const initialState = initLoadable<types.SReferralsByUser>(null)

const referralsByUser = createSlice({
  name: 'referralsByUser',
  initialState,
  reducers: {
    resetReferralsByUser: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReferralsByUser.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchReferralsByUser.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetReferralsByUser } = referralsByUser.actions
export default referralsByUser.reducer
export const selectUserReferrals = (s: RootState) => s.user.referralsByUser.value
export const selectUserReferralsBusy = (s: RootState) => s.user.referralsByUser.status === 'busy'
export const selectUserReferralsLoaded = (s: RootState) => s.user.referralsByUser.loadedOnce

export const selectUserReferralInfo = createSelector(
  [selectUserReferrals, selectUserReferralsLoaded],
  (referralsByUser, loaded) => {
    if (!loaded) {
      return {
        userReferrals: [],
        userStatus: null
      }
    }
    if (isEmptyOrNil(referralsByUser)) {
      return {
        userReferrals: [],
        userStatus: null
      }
    }

    return referralsByUser
  }
)

export const selectIsActiveReferralMulti = createSelector(
  [selectUserReferralInfo],
  (referralInfo) => {
    if (
      !referralInfo ||
      referralInfo.userStatus === null ||
      !referralInfo.userStatus?.ts ||
      !referralInfo.userStatus
    ) {
      return { isMulti: false, multiDays: 0 }
    }

    const date = new Date()
    const referDate = new Date(referralInfo.userStatus?.ts)

    //check if referDate is within last 10 days
    const diff = date.getTime() - referDate.getTime()
    const diffDays = diff / (1000 * 3600 * 24)
    return { isMulti: diffDays < 10, multiDays: Math.floor(10 - diffDays) }
  }
)

export const selectReferees = createSelector([selectUserReferralInfo], (referralInfo) => {
  if (!referralInfo || referralInfo.userReferrals.length === 0) {
    return { hasReferees: false, referees: [] }
  }
  return { hasReferees: true, referees: referralInfo.userReferrals }
})
