import { CoreTypes_Referal } from '@concordia/super-json-api-client'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SDK_CONTEXT } from 'state/context'
import {
  DUPLICATE,
  INVALID,
  OTHER,
  openReferralSuccess,
  setReferralErrorText
} from 'state/slices/app/points'
import { toastLinkAccount } from 'toolbox/toast'

export type CaptureReferralPayload = {
  refCode: string
  address: string
  network: string
}

export const INVALID_CODE = 'Invalid referal code'
export const DUPLICATE_KEY = 'duplicate key value violates unique constraint'

export const captureReferral = createAsyncThunk(
  'captureReferral',
  async (payload: CaptureReferralPayload): Promise<CoreTypes_Referal> => {
    const sdk = SDK_CONTEXT.superSdk
    if (!sdk) {
      throw new Error('SDK not initialized')
    }

    try {
      const code = await sdk.captureReferralCode(payload.refCode, payload.address)
      console.log('Captured Referral:', code)
      toastLinkAccount(code.referer_address, payload.network, `Referral connected to: `)
      openReferralSuccess()
      return code
    } catch (e: any) {
      if (e.body.includes(DUPLICATE_KEY)) {
        setReferralErrorText(DUPLICATE)
      } else if (e.body.includes(INVALID_CODE)) {
        setReferralErrorText(INVALID)
      } else {
        setReferralErrorText(OTHER)
      }
    }
  }
)
