import { useAppSelector } from 'state/hooks'
import { MetaBroker, FormattedPositions } from 'state/mock'
import { DashTab, selectColumns } from 'state/slices/ui/dash'
import CollateralRow from './CollateralRow'
import { selectFormLabelsByDashTab } from 'state/slices/ui/form'
import { types } from '@concordia/super-sdk'
import { selectTotalCollateral } from 'state/slices/user/portfolio'
import FlashRow from './FlashRow'
import { selectBrokersErrored, selectBrokersLoaded } from 'state/slices/app/brokers'
import { memo, useMemo } from 'react'

export interface CollateralListProps {
  brokers: MetaBroker[]
  isLoadedApp: boolean
  tab: DashTab
  positions: FormattedPositions
  isLoadedUser: boolean
  balances: types.SWalletBalance[]
}

const CollateralRowMemo = memo(CollateralRow)

function CollateralList({
  brokers,
  isLoadedApp,
  tab,
  isLoadedUser,
  positions,
  balances
}: CollateralListProps) {
  const formTabs = useAppSelector(selectFormLabelsByDashTab)
  const userCollat = useAppSelector(selectTotalCollateral)
  const isLoadedBrokers = useAppSelector(selectBrokersLoaded)
  const isBrokersErrored = useAppSelector(selectBrokersErrored)
  const displayListTop = useMemo(
    () =>
      brokers?.map((b, i) => (
        <CollateralRowMemo
          b={b}
          key={b.underlyingAsset.name + 'a'}
          tab={tab}
          isLoadedUser={isLoadedUser}
          positions={positions}
          balances={balances}
          showBalRow={true}
          isLoadedApp={isLoadedApp}
          formTabs={formTabs}
          userCollat={userCollat}
          isLoadedBrokers={isLoadedBrokers}
        />
      )),
    [
      brokers,
      tab,
      isLoadedUser,
      positions,
      balances,
      isLoadedApp,
      formTabs,
      userCollat,
      isLoadedBrokers
    ]
  )
  const displayListBelow = useMemo(
    () =>
      brokers?.map((b, i) => (
        <CollateralRowMemo
          b={b}
          key={b.underlyingAsset.name + 'b'}
          tab={tab}
          isLoadedUser={isLoadedUser}
          positions={positions}
          balances={balances}
          showBalRow={false}
          isLoadedApp={isLoadedApp}
          formTabs={formTabs}
          userCollat={userCollat}
          isLoadedBrokers={isLoadedBrokers}
        />
      )),
    [
      brokers,
      tab,
      isLoadedUser,
      positions,
      balances,
      isLoadedApp,
      formTabs,
      userCollat,
      isLoadedBrokers
    ]
  )
  const columns = useAppSelector(selectColumns)

  if (!isLoadedApp && !isBrokersErrored) {
    return (
      <div className="broker-list">
        <div className="headings">{columns}</div>
        <div className="rows">
          <hr className="hr" />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
          <FlashRow />
        </div>
      </div>
    )
  }

  return (
    <div className="broker-list">
      <div className="headings">{columns}</div>
      <div className="rows">
        {displayListTop}

        <hr className="hr" />
        {displayListBelow}
      </div>
    </div>
  )
}

export default CollateralList
