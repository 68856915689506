import { TokenMeta } from 'state/tokens'
import { formatCurrency, onlyNumbers } from 'toolbox/format'

export interface MultiInputContainerProps {
  token: TokenMeta
  inputVal: string
  inputDollars: number
  setMax: () => void
  setInput: (val: string) => void
}

export function MultiInputContainer({
  token,
  inputVal,
  inputDollars,
  setMax,
  setInput
}: MultiInputContainerProps) {
  const numberOfCharacters = inputVal.length
  let size = ''
  if (numberOfCharacters > 9) {
    size = 'small'
  }
  if (numberOfCharacters > 12) {
    size = 'x-small'
  }

  return (
    <div className="input-container">
      <div className="stacked-global large">
        <img src={token?.logo} alt={token?.name} />
      </div>
      <div className="center">
        <input
          className={`form-input ${size}`}
          placeholder="0"
          value={inputVal}
          onChange={(e) => setInput(onlyNumbers(e.target.value))}
        />
        <p className="value">{formatCurrency(inputDollars)}</p>
      </div>
      <button onClick={setMax} className={`sp-btn max `}>
        Max
      </button>
    </div>
  )
}
