import down from 'assets/icons/down-chev.svg'
import { useState } from 'react'
export interface InfoPointProps {
  value: string
  sub: string
  secondarySub?: string
  withChev?: boolean
  color?: number
  disabled?: boolean
  largeBalance?: boolean
  hideSecondaryOnBreakpoint?: boolean
  slashValue?: string
  hoverValue?: React.ReactNode
}

function InfoPoint({
  value,
  sub,
  withChev,
  secondarySub,
  color,
  disabled,
  largeBalance,
  hideSecondaryOnBreakpoint,
  slashValue,
  hoverValue
}: InfoPointProps) {
  const dotClass = color ? `${color}` : ''
  const hideSub = value === '0' || value === '--'
  const disableClass = disabled || hideSub ? 'dis' : ''
  const largeClass = largeBalance ? 'large-balance' : ''
  const hideSecondaryClass = hideSecondaryOnBreakpoint ? 'hide-secondary' : ''
  const [showHover, setShowHover] = useState(false)
  const showHoverClass = showHover ? 'show-hover' : ''

  return withChev ? (
    <div className={`info-point ${disableClass}`}>
      {!hideSub && <p className="light">{sub}</p>}
      <div className="with-chev">
        <p className="dark">{value}</p>
        <img src={down} alt="down" className="chev" />
      </div>
    </div>
  ) : (
    <div
      className={`info-point ${disableClass}`}
      onMouseEnter={() => setShowHover(true)}
      onMouseLeave={() => setShowHover(false)}>
      <div className={`dark ${largeClass}`}>
        {!!hoverValue && <div className={`info-tip top-down ${showHoverClass}`}>{hoverValue}</div>}

        {!!color && <div className={`c-color ${dotClass}`} />}
        <p>{value}</p>
        {!!slashValue && <p className="slash-value">/&nbsp;{slashValue}</p>}
      </div>
      <div className={`light ${largeClass}`}>
        {!hideSub && <p>{sub}</p>}
        {secondarySub && (
          <div className={`secondary-sub ${hideSecondaryClass}`}>
            <div className="marker" />
            <p>{secondarySub}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoPoint
