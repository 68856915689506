import xyz from 'assets/icons/xyz.svg'
import redLink from 'assets/icons/red-link.svg'
import redX from 'assets/icons/red-x.svg'
import {
  DUPLICATE,
  INVALID,
  OTHER,
  closeReferralError,
  selectReferralError
} from 'state/slices/app/points'
import { useAppSelector } from 'state/hooks'
import CloseButton from 'components/common/CloseButton'

export function ReferralError() {
  const referralError = useAppSelector(selectReferralError)

  const bigTextMap = {
    [DUPLICATE]: 'Code Already Submitted',
    [INVALID]: 'Error: Invalid Referral Code',
    [OTHER]: 'An unexpected error occurred'
  }

  const smalltextMap = {
    [DUPLICATE]: 'You can submit only one referral code.',
    [INVALID]:
      'The referral code you entered is invalid. Please double-check the URL you received and try again.',
    [OTHER]: 'Please reload the app and try again.'
  }

  const iconMap = {
    [DUPLICATE]: xyz,
    [INVALID]: redLink,
    [OTHER]: redX
  }

  const bigText = bigTextMap[referralError]
  const smallText = smalltextMap[referralError]
  const icon = iconMap[referralError]

  return (
    <div className="do-refer-modal">
      <CloseButton cb={closeReferralError} />
      <img src={icon} alt="error" />
      <h3>{bigText}</h3>
      <p>{smallText}</p>
      <button className="sp-btn tertiary full-w" onClick={closeReferralError}>
        Close
      </button>
    </div>
  )
}
