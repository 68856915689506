import { isEmpty, isNil } from 'ramda'
import { MetaBroker } from 'state/mock'

export function isEmptyOrNil(anything: any) {
  return isNil(anything) || isEmpty(anything)
}

export function noteNameToBroker(brokers: MetaBroker[], noteName: string) {
  return brokers.find((b) => b.depositNote.name === noteName || b.loanNote.name === noteName)
}
