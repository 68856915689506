import { formatDateString, formatPublicKeyDisplay, prettyTokenBal, scaleDown } from 'toolbox/format'
import deposit from 'assets/icons/deposit.svg'
import withdraw from 'assets/icons/withdraw.svg'
import { getBrokerByName, selectBrokersWithMeta } from 'state/slices/app/brokers'
import { useAppSelector } from 'state/hooks'
import { getTokenMeta, selectTokens } from 'state/slices/app/meta'

export interface ActivityRowItem {
  txType: string
  time: number
  amount: number
  brokerName: string
  userAddress: string
  version: string
  collatAmount?: number //liquidate type only
  liabAmount?: number //liquidation type only
}
const BORROW_ACTION = 'borrow'
const REDEEM_ACTION = 'redeem'
const REPAY_ACTION = 'repay'
const LEND_ACTION = 'lend'
const LIQUIDATE_ACTION = 'liquidate'

export function ActivityRow({
  txType,
  time,
  amount,
  brokerName,
  userAddress,
  version,
  collatAmount,
  liabAmount
}: ActivityRowItem) {
  const timeString = new Date(time * 1000)
  const formattedTime = formatDateString(timeString)

  const downActions = [BORROW_ACTION, REDEEM_ACTION]
  const isDownAction = downActions.includes(txType)

  const brokers = useAppSelector(selectBrokersWithMeta)
  const broker = getBrokerByName(brokers, brokerName)
  const depNoteDecimals = broker?.depositNote.decimals
  const loanNoteDecimals = broker?.loanNote.decimals
  const underlyingDecimals = broker?.underlyingAsset.decimals
  const tokens = useAppSelector(selectTokens)
  const tokenMeta = getTokenMeta(broker?.underlyingAsset.name, tokens)
  const ticker = tokenMeta.ticker
  const network = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'

  if (txType === LIQUIDATE_ACTION) {
    const cAmount = collatAmount || 0
    const liqAmountSum = scaleDown(cAmount, underlyingDecimals)
    return (
      <>
        <div className="act-columns desk-only">
          <div className="act-column with-icon">
            <img className="arr" src={deposit} alt={txType} />
            <p>{txType}</p>
          </div>
          <div className="act-column is-flex grey">
            <p>{formattedTime.yearly}</p>
            <div className="marker" />
            <p>{formattedTime.hourly}</p>
          </div>
          <div className="act-column">
            <p>{prettyTokenBal(liqAmountSum)}</p>
          </div>
          <div className="act-column">
            <p>{ticker}</p>
          </div>
          <div className="act-column grey">
            <a
              href={`https://explorer.aptoslabs.com/account/${userAddress}?network=${network}`}
              rel="noreferrer"
              target="_blank">
              <p>{formatPublicKeyDisplay(userAddress)}</p>
            </a>
          </div>
          <div className="act-column">
            <a
              href={`https://explorer.aptoslabs.com/txn/${version}?network=${network}`}
              rel="noreferrer"
              target="_blank"
              className="last-link">
              Explorer
            </a>
          </div>
        </div>
        <div className="act-columns mobile-only">
          <div className="act-column with-icon">
            <img className="arr" src={deposit} alt={txType} />
            <p>{txType}</p>
          </div>
          <div className="act-column is-flex grey">
            <p>{formattedTime.yearly}</p>
            <div className="marker" />
            <p>{formattedTime.hourly}</p>
          </div>

          <div className="act-column">
            <a
              href={`https://explorer.aptoslabs.com/txn/${version}?network=${network}`}
              rel="noreferrer"
              target="_blank"
              className="last-link">
              Explorer
            </a>
          </div>
        </div>
        <div className="act-under mobile-only">
          <p>
            {prettyTokenBal(liqAmountSum)}&nbsp;{ticker}
          </p>
        </div>
      </>
    )
  }
  let scaledAmount = 0
  switch (txType) {
    case LEND_ACTION:
      scaledAmount = scaleDown(amount, underlyingDecimals)
      break
    case REDEEM_ACTION:
      scaledAmount = scaleDown(amount, depNoteDecimals)
      break
    case BORROW_ACTION:
      scaledAmount = scaleDown(amount, underlyingDecimals)
      break
    case REPAY_ACTION:
      scaledAmount = scaleDown(amount, loanNoteDecimals)
      break
  }

  return (
    <>
      <div className="act-columns desk-only">
        <div className="act-column with-icon">
          <img className="arr" src={isDownAction ? deposit : withdraw} alt={txType} />
          <p>{txType}</p>
        </div>
        <div className="act-column is-flex grey">
          <p>{formattedTime.yearly}</p>
          <div className="marker" />
          <p>{formattedTime.hourly}</p>
        </div>
        <div className="act-column">
          <p>{prettyTokenBal(scaledAmount)}</p>
        </div>
        <div className="act-column">
          <p>{ticker}</p>
        </div>
        <div className="act-column grey">
          <a
            href={`https://explorer.aptoslabs.com/account/${userAddress}?network=${network}`}
            rel="noreferrer"
            target="_blank">
            <p>{formatPublicKeyDisplay(userAddress)}</p>
          </a>
        </div>
        <div className="act-column">
          <a
            href={`https://explorer.aptoslabs.com/txn/${version}?network=${network}`}
            rel="noreferrer"
            target="_blank"
            className="last-link">
            Explorer
          </a>
        </div>
      </div>
      <div className="act-columns mobile-only">
        <div className="act-column with-icon">
          <img className="arr" src={isDownAction ? deposit : withdraw} alt={txType} />
          <p>{txType}</p>
        </div>
        <div className="act-column is-flex grey">
          <p>{formattedTime.yearly}</p>
          <div className="marker" />
          <p>{formattedTime.hourly}</p>
        </div>

        <div className="act-column">
          <a
            href={`https://explorer.aptoslabs.com/txn/${version}?network=${network}`}
            rel="noreferrer"
            target="_blank"
            className="last-link">
            Explorer
          </a>
        </div>
      </div>
      <div className="act-under mobile-only">
        <p>
          {prettyTokenBal(scaledAmount)}&nbsp;{ticker}
        </p>
      </div>
    </>
  )
}
