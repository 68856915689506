import { useMemo, useState, memo } from 'react'
import { isEmptyOrNil } from 'toolbox/account'
import Param, { ParamProps } from './Param'

export interface ParamListProps {
  params: ParamProps[]
  more?: ParamProps[]
  tight?: boolean
  next?: string
}
const ParamMemo = memo(Param)

function ParamList({ params, tight, more }: ParamListProps) {
  const displayList = useMemo(
    () => params.map((p, i) => p && <ParamMemo {...p} key={i} />),
    [params]
  )
  const displayMore = useMemo(() => more?.map((p, i) => p && <ParamMemo {...p} key={i} />), [more])

  const t = tight ? 'tight' : ''
  const hasMore = !isEmptyOrNil(more)
  const [showMore, setShowMore] = useState(false)
  const buttonText = showMore ? 'Less' : 'More'

  return (
    <div className={`param-list ${t}`}>
      <>
        {displayList}
        {!!hasMore && (
          <div className="more-container" onClick={() => setShowMore(!showMore)}>
            <div className="line" />
            <button className="sp-btn link pri">{buttonText}</button>
            <div className="line" />
          </div>
        )}
        {!!showMore && displayMore}
      </>
    </div>
  )
}

export default ParamList
