import orangeTip from 'assets/icons/tooltip-orange.svg'
import CloseButton from 'components/common/CloseButton'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'
import { TxError, setTxErrorState } from 'state/slices/ui/transaction'

export function ErrorModal({ title, message, explorerHash }: TxError) {
  const closeModal = () => {
    setTxErrorState(null)
  }

  const infoBoxProps: InfoBoxProps = {
    color: 'blue',
    showBox: true,
    text: message,
    fullWidth: true,
    noTopMargin: true
  }

  const envNet = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'

  return (
    <div className="error-modal">
      <CloseButton cb={closeModal} />
      <img src={orangeTip} alt="warning" className="big-i" />
      <h1>{title}</h1>
      <hr className="hr" />
      <InfoBox {...infoBoxProps} />
      {!!explorerHash && (
        <a
          href={`https://explorer.aptoslabs.com/txn/${explorerHash}?network=${envNet}`}
          rel="noreferrer"
          target="_blank">
          View Status In Explorer ↗
        </a>
      )}
      <button className="sp-btn tertiary full-w" onClick={closeModal}>
        Close
      </button>
    </div>
  )
}
