export function compactNum(num: number, options?: any) {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    ...options
  }).format(num)
}

export function oneDecimal(num: number) {
  return formatNum(1, num, { maximumFractionDigits: 1 })
}

export function prettyNumber(num: number) {
  return formatNum(2, num)
}

export function commaFloor(num: number) {
  return formatNum(0, num, { maximumFractionDigits: 0 })
}

export function formatNum(min: number, num: number, options?: any): string {
  if (Number.isNaN(num)) {
    return '--'
  }
  const opt = { ...options, minimumFractionDigits: min }
  return new Intl.NumberFormat('en-US', opt).format(num)
}

export function prettyTokenBal(num: number) {
  if (num === 0) {
    return formatNum(0, num)
  } else if (num < 1) {
    return formatNum(8, num, { maximumFractionDigits: 8 })
  } else if (num < 1000) {
    return formatNum(4, num, { maximumFractionDigits: 4 })
  } else if (num < 1_000_000) {
    return formatNum(0, num, { maximumFractionDigits: 0 })
  } else if (num < 1_000_000_000) {
    return formatNum(1, num / 1_000_000, { maximumFractionDigits: 1 }) + 'M'
  } else if (num < 1_000_000_000_000) {
    return formatNum(1, num / 1_000_000_000, { maximumFractionDigits: 1 }) + 'B'
  } else if (num < 0) {
    return formatNum(0, num)
  }
}

export function prettyTokenBalWithK(num: number) {
  if (num === 0) {
    return formatNum(0, num)
  } else if (num < 1) {
    return formatNum(4, num, { maximumFractionDigits: 4 })
  } else if (num < 1000) {
    return formatNum(2, num, { maximumFractionDigits: 2 })
  } else if (num < 999_900) {
    return formatNum(1, num / 1000, { maximumFractionDigits: 1 }) + 'K'
  } else if (num < 1_000_000) {
    // Handling numbers very close to 1M to display as "999K"
    return '1.0M'
  } else if (num < 1_000_000_000) {
    return formatNum(1, num / 1_000_000, { maximumFractionDigits: 1 }) + 'M'
  } else if (num < 1_000_000_000_000) {
    return formatNum(1, num / 1_000_000_000, { maximumFractionDigits: 1 }) + 'B'
  } else if (num < 0) {
    return formatNum(0, num)
  }
}

export function formatCurrency(num: number): string {
  const formatNum = (fractionDigits: number, number: number, options: Intl.NumberFormatOptions) => {
    return new Intl.NumberFormat('en-US', {
      ...options,
      maximumFractionDigits: fractionDigits
    }).format(number)
  }

  const dollars = { style: 'currency', currency: 'USD' }

  if (num >= 1_000_000_000) {
    // For billions
    return formatNum(1, num / 1_000_000_000, dollars) + 'B'
  } else if (num >= 1_000_000) {
    // For millions
    return formatNum(1, num / 1_000_000, dollars) + 'M'
  } else if (num >= 1000) {
    // For thousands
    return formatNum(0, num, { ...dollars, ...{ maximumFractionDigits: 0 } })
  }

  // For amounts less than 1000
  return formatNum(2, num, dollars)
}

export function formatCurrencyCompact(num: number) {
  const opt = {
    style: 'currency',
    currency: 'USD'
  }
  if (num >= 1000000) {
    return compactNum(num, opt)
  } else {
    return formatCurrency(num)
  }
}

export function formatPercentageCompact(num: number) {
  const percentCompact = { style: 'percent', maximumFractionDigits: 0 }
  return formatNum(0, num, percentCompact)
}

export function formatPercentage(num: number) {
  const percent = { style: 'percent' }
  if (num === 0 || num < 0.0001) {
    return formatNum(0, num, percent)
  }
  return formatNum(2, num, percent)
}

export function formatPercentageWithSign(num: number) {
  const withSign = { style: 'percent', signDisplay: 'exceptZero' }
  return formatNum(2, num, withSign)
}

export function returnClassnameBySign(num: number) {
  const sign = Math.sign(num)

  if (sign === 1) {
    return 'positive'
  } else if (sign === -1) {
    return 'negative'
  }
}

export function formatPublicKeyDisplay(string: string | string[] | undefined) {
  if (!string) {
    return ''
  }
  let useString = string
  if (Array.isArray(string)) {
    useString = string.join('')
  }

  const chars1 = 6
  const chars2 = 5
  const length = useString.length
  const first = useString.slice(0, chars1)
  const second = useString.slice(length - chars2)
  const display = first + '...' + second
  return display
}

export function onlyNumbers(str: string) {
  const nums = str.replace(/[^0-9.]/g, '')
  return nums
}

export function onlyAlpha(str: string) {
  const nums = str.replace(/[^a-z0-9]/gi, '')
  return nums
}

export function hexToUTF(hex: string) {
  return decodeURIComponent(hex.replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&'))
}

export function alphaInput(s: string) {
  return s.replace(/[^[a-zA-Z0-9_]*$]/, '')
}

export function stringify(obj: any) {
  return JSON.stringify(obj)
}

export const dollars = (check, value) => {
  if (check) {
    return formatCurrency(value)
  } else {
    return '--'
  }
}

export const dollarsCompact = (check, value) => {
  if (check) {
    return formatCurrencyCompact(value)
  } else {
    return '--'
  }
}

export function formatDateString(date: Date) {
  const yearly = `${(date.getMonth() + 1).toString().padStart(2, '0')}.${date
    .getDate()
    .toString()
    .padStart(2, '0')}.${date.getFullYear().toString().slice(-2)}`
  const hourly = `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
  return {
    yearly,
    hourly
  }
}

export function formatDateSimple(timestamp) {
  const date = new Date(timestamp * 1000)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const month = months[date.getMonth()]
  const day = date.getDate()
  return `${month} ${day}`
}

export function formatDateExact(timestamp) {
  const date = new Date(timestamp * 1000)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const month = months[date.getMonth()]
  const day = date.getDate()

  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const strTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`

  return `${month} ${day}, ${strTime}`
}

export function scaleUp(num: number, decimals: number) {
  return num * 10 ** decimals
}

export function scaleDown(num: number, decimals: number) {
  return num / 10 ** decimals
}

export function formatHealthFactor(healthFactor: number) {
  return !healthFactor || healthFactor <= 0
    ? 'N/A'
    : healthFactor >= 135
    ? 'MAX'
    : compactNum(healthFactor) + 'x'
}

export function detrmineHealthColor(isGreenZoneStyles, simHealthRed, simHealthYellow) {
  if (isGreenZoneStyles) {
    return 'risk-green'
  } else if (simHealthRed) {
    return 'risk-red'
  } else if (simHealthYellow) {
    return 'risk-orange'
  } else {
    return ''
  }
}

export function eMessage(e: any) {
  return e?.message ? `${e.message}` : `${e}`
}
