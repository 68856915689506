import { createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState } from 'state/store'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchReferralCode } from 'state/thunks/fetchReferralCode'

export interface Referral {
  referralCode: string
}

type State = Loadable<Referral>
export const initialState = initLoadable<Referral>(null)

const referral = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    resetReferralCode: (state: State) => {
      state.value = null
      state.loadedOnce = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReferralCode.fulfilled, (state, action) => {
      state.loadedOnce = true
      state.value = action.payload
      state.status = 'idle'
    })

    builder.addCase(fetchReferralCode.pending, (state) => {
      state.status = 'busy'
    })
  }
})

export const { resetReferralCode } = referral.actions
export default referral.reducer
export const selectReferral = (s: RootState) => s.user.referral.value
export const selectReferralBusy = (s: RootState) => s.user.referral.status === 'busy'
export const selectReferralLoaded = (s: RootState) => s.user.referral.loadedOnce

export const selectReferralCode = createSelector(
  [selectReferral, selectReferralLoaded],
  (referral, loaded) => {
    if (!loaded) {
      return ''
    }
    if (isEmptyOrNil(referral)) {
      return ''
    }

    return referral?.referralCode
  }
)
